import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link, Typography } from '@mui/material';
import Loginlogo from 'assets/images/login-logo.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import { PublicRoutes } from 'config';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { PasswordForm } from 'types';
import { passwordSchema } from 'utils/ValidatorSchema';

import useStyles from '../publicStyles';

type screenProps = {
  screen2Change: (src: any) => void;
  backScreen: (src: any) => void;
};

const Screen2 = (props: screenProps): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { loading }: any = useAppSelector((store: any) => store.userLogin);

  const { handleSubmit, control, formState } = useForm<PasswordForm>({
    defaultValues: {
      password: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(passwordSchema),
  });

  const submitForm = (data: any) => {
    props.screen2Change(data['password']);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <a
        href="javascript:void(0);"
        style={{
          height: 20,
          left: 20,
          position: 'absolute',
          top: 20,
          width: 20,
        }}
        onClick={props.backScreen}>
        <ArrowBackIosNewIcon sx={{ color: '#292929', fontSize: 20 }} />
      </a>
      <Typography>
        <img
          src={Loginlogo}
          alt="signup-logo"
          style={{ display: 'table', margin: '51px auto 30px' }}
        />
      </Typography>
      <Typography>
        <label>Password</label>
        <Controller
          control={control}
          defaultValue=""
          name="password"
          render={({ field: { onChange, value, name, ref } }) => {
            return (
              <CustomInput
                placeHolder="Password"
                type={'password'}
                value={value}
                className={classes.passwordinput}
                name={name}
                ref={ref}
                autoFocus={true}
                onChange={(e: any) => {
                  onChange(e);
                }}
                error={formState.errors?.password?.message}
                extraStyle1={{ right: '13%' }}
              />
            );
          }}
        />
      </Typography>
      <Typography style={{ marginBottom: 35, marginTop: 15 }}>
        <Link
          href="javascript:void(0);"
          underline="always"
          style={{
            color: '#979598',
            fontFamily: 'Red Hat Display SemiBold',
            fontSize: 14,
            textDecorationColor: '#979598',
          }}
          onClick={() => navigate(PublicRoutes.FORGOTPASSWORD)}>
          {'Forgot Password?'}
        </Link>
      </Typography>
      <Typography>
        <CustomButton
          variant="contained"
          type="submit"
          name={'Login'}
          loading={loading}
          extraStyle={{ textTransform: 'capitalize' }}
        />
      </Typography>
    </form>
  );
};

export default Screen2;
