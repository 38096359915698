import { AppStyles } from '../style/variable';
import GetTheme from './GetTheme';

const currentTheme = window.localStorage.getItem('currentTheme') || 'light';
const palette = GetTheme(currentTheme);

const typography = {
  body1: {
    color: palette.text.primary,
    fontSize: '1rem',
    letterSpacing: '0.00938rem',
    lineHeight: 1.125,
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '1rem',
    letterSpacing: '0.00938rem',
    lineHeight: 1.125,
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.875rem',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '0.6875rem',
    letterSpacing: '0.33px',
  },
  fontFamily: AppStyles.font.regular,
  h1: {
    color: palette.text.primary,
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  h2: {
    color: palette.text.primary,
    fontSize: '1.875rem',
    fontWeight: 500,
  },
  h3: {
    color: palette.text.primary,
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  h4: {
    color: palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  h5: {
    color: palette.text.primary,
    fontSize: '1rem',
    fontWeight: 500,
  },
  h6: {
    color: palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '0.6875rem',
    fontWeight: 500,
    letterSpacing: '0.33px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '1rem',
  },
  subtitle2: {
    color: palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
};

export default typography;
