import { Grid, Paper, Typography } from '@mui/material';
import Loginbg from 'assets/images/login-bg.png';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { login } from 'store/actions/UserAction';
import { useAppDispatch } from 'store/hooks';
import { EMAIL_CHECKED } from 'store/types/UserTypes';
import Storage from 'utils/Storage';

import CopyAndVersionHistory from '../../common/CopyAndVersionHistory';
import useStyles from '../publicStyles';
import Screen1 from './screen1';
import Screen2 from './screen2';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [screen, setScreen] = useState<number>(1);
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [emailRemember, setEmailRemember] = useState<string>('');
  const [cookies, setCookie] = useCookies(['email']);

  useEffect(() => {
    if (cookies.email) {
      setEmailRemember(cookies.email);
      setEmailChecked(true);
    }
  }, [cookies]);

  const screen1Change = (val: string, val1: boolean) => {
    setEmail(val);
    setScreen(2);
    dispatch({
      payload: val,
      type: EMAIL_CHECKED,
    });
    setCookie('email', val1 ? val : '');
    setEmailChecked(val1);
    Storage.setItem(Storage.KEYS.REMEMBER_ME, val1 ? val : '');
  };

  const backScreen = () => {
    setScreen(1);
  };

  const submit = (pas: string) => {
    dispatch(login(email, pas));
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography
          component="div"
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          style={{ backgroundImage: `url(${Loginbg})` }}
          className={classes.info}>
          <Paper className={classes.loginwrap}>
            {screen === 1 ? (
              <Screen1
                screen1Change={screen1Change}
                email={email ? email : emailRemember}
                emailChecked={emailChecked}
              />
            ) : (
              <Screen2 backScreen={backScreen} screen2Change={submit} />
            )}
            <CopyAndVersionHistory />
          </Paper>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
