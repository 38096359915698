import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

type InfinityScrollInterFace = {
  paramState: any;
  height: number;
  scrollableTargetId: string;
  rowPorops: any;
  headRow: any;
  data: any;
  count: any;
  columnCount: number;
};
const InfinityScroll: React.FC<any> = (props: InfinityScrollInterFace) => {
  return (
    <InfiniteScroll
      dataLength={props.data.length}
      next={() => {
        if (props.data.length < props.count) {
          props.paramState((prev) => ({
            ...prev,
            skip: Number(prev.skip) + Number(prev.take),
            take: prev.take,
          }));
        }
      }}
      hasMore={props.data.length < props.count}
      loader={
        <Typography
          component={'div'}
          sx={{
            justifyContent: 'center',
            paddingTop: '20px',
            textAlign: 'center',
          }}>
          <CircularProgress sx={{ color: '#340000' }} />
        </Typography>
      }
      height={props.height}
      scrollableTarget={props.scrollableTargetId}>
      <Table aria-label="customized table">
        {props.headRow()}
        <TableBody>
          {props?.data.length ? (
            props?.data.map((data: any, index: number) => {
              return props.rowPorops(data, index);
            })
          ) : (
            <TableCell
              colSpan={props.columnCount}
              sx={{
                color: '#241a2e',
                fontFamily: 'Red Hat Display SemiBold',
                fontSize: '14px',
                textAlign: 'center',
              }}>
              No Records Found
            </TableCell>
          )}
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
};
export default InfinityScroll;
