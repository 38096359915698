import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import Space from 'assets/images/space.png';
import React from 'react';

import DrawerLayout from './DrawerLayout';
// import Header from './Header';
// import Navbar from './Navbar';

export const useStyles = makeStyles((theme) => ({
  space: {
    // backgroundImage: `url(${String(Space)})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    width: '100%',
  },
}));

type headConfigsProps = {
  routes: any;
  showTools?: boolean;
  showHeader?: boolean;
  addEditRoute?: any;
  children: any;
  from?: string;
};

const PrivateLayout = (props: headConfigsProps) => {
  // const { children } = props;
  // const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <DrawerLayout
          childComProps={props.children}
          from={props.from}
          showHeader={props.showHeader}
        />
      </Grid>
    </Grid>
  );
};

export default PrivateLayout;
