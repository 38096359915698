import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Fade,
  Modal,
  Paper,
  TextField,
} from '@mui/material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DotsIcon from 'assets/images/dots.png';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { updateData } from 'services/CommonServices';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    borderColor: '#d4d4d6',
    borderWidth: 1,
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: 'transparent',
    borderColor: '#E0E0E0',
    borderWidth: 0.3,
    color: '#241A2E',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 13,
    padding: 8,
    paddingBottom: 15,
    paddingTop: 15,
    verticalAlign: 'top',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const style1 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 620,
};

const style2 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 614,
};

export const LeaveHistory: React.FC<any> = (props) => {
  const [editJSON, setEditJSON] = React.useState({
    comments: '',
    editId: '',
    open: false,
  });
  const [deleteJSON, setDeleteJSON] = React.useState({
    comments: '',
    deleteId: '',
    open: false,
  });
  const [leaveStatus, setLeaveStatus] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open5, setOpen5] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick =
    (newPlacement: PopperPlacementType, row: any, status?: string) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen5((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      setLeaveStatus(status);
      setEditJSON({
        ...editJSON,
        editId: row.id,
      });
      setDeleteJSON({
        ...deleteJSON,
        deleteId: row.id,
      });
    };

  const EditFunction = () => {
    if (!editJSON.comments) {
      toast.error('Please add comments', alertOptions);
      return;
    }
    updateData(
      editJSON.editId,
      {
        comments: editJSON.comments,
        type: 'editRequest',
      },
      MASTER_ENDPOINT.Leaves + '/request/update',
      true,
    )
      .then((resp: any) => {
        closeEdit();
      })
      .catch((err: any) => {});
  };

  const DeleteFunction = () => {
    updateData(
      deleteJSON.deleteId,
      {
        comments: deleteJSON.comments,
        type: 'cancelRequest',
      },
      MASTER_ENDPOINT.Leaves + '/request/update',
      true,
    )
      .then((resp: any) => {
        closeDelete();
      })
      .catch((err: any) => {});
  };

  const closeEdit = () => {
    setEditJSON({
      comments: '',
      editId: '',
      open: false,
    });
  };

  const closeDelete = () => {
    setDeleteJSON({
      comments: '',
      deleteId: '',
      open: false,
    });
  };

  return (
    <React.Fragment>
      <Card sx={{ boxShadow: 0, maxHeight: '400px', overflowY: 'scroll' }}>
        <CardContent>
          <Typography component={'div'}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Start Date</StyledTableCell>
                    <StyledTableCell align="left">Return Date</StyledTableCell>
                    <StyledTableCell align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.leaveHistory.length === 0 &&
                    props.leaveReturnedHistory.length === 0 &&
                    props.leaveHistoryPlanned.length === 0 &&
                    props.leaveHistoryDenied.length === 0 && (
                      <StyledTableRow>
                        <StyledTableCell
                          colSpan={3}
                          sx={{
                            color: '#241a2e',
                            fontFamily: 'Red Hat Display SemiBold',
                            fontSize: '14px',
                            textAlign: 'center',
                          }}>
                          No Records Found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  {props.leaveHistoryPlanned &&
                    props.leaveHistoryPlanned.map((row: any, index: number) => {
                      return (
                        <StyledTableRow
                          key={index}
                          style={{ background: '#fff' }}>
                          <StyledTableCell component="th" scope="row">
                            Planned
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row.fromDate).format('MM/DD/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row.returnDate).format('MM/DD/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              onClick={handleClick(
                                'bottom-end',
                                row,
                                'planned',
                              )}
                              style={{
                                backgroundColor: 'transparent',
                                padding: 0,
                              }}>
                              <Avatar
                                variant="square"
                                alt="Profile"
                                src={DotsIcon}
                                style={{
                                  height: 18,
                                  width: 15,
                                }}
                              />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {props.leaveHistory &&
                    props.leaveHistory.map((row: any, index: number) => {
                      let lStatus = 'approved';
                      if (
                        row.status === 'approved' &&
                        (moment(moment().format('YYYY-MM-DD')).isBetween(
                          moment(row.fromDate).format('YYYY-MM-DD'),
                          moment(row.toDate).format('YYYY-MM-DD'),
                        ) ||
                          moment(moment().format('YYYY-MM-DD')).isSame(
                            moment(row.fromDate).format('YYYY-MM-DD'),
                          ) ||
                          moment(moment().format('YYYY-MM-DD')).isSame(
                            moment(row.toDate).format('YYYY-MM-DD'),
                          ))
                      ) {
                        lStatus = 'onLeave';
                      }
                      return (
                        <StyledTableRow
                          key={index}
                          style={{
                            background:
                              moment(moment().format('YYYY-MM-DD')).isBetween(
                                moment(row.fromDate).format('YYYY-MM-DD'),
                                moment(row.toDate).format('YYYY-MM-DD'),
                              ) ||
                              moment(moment().format('YYYY-MM-DD')).isSame(
                                moment(row.fromDate).format('YYYY-MM-DD'),
                              ) ||
                              moment(moment().format('YYYY-MM-DD')).isSame(
                                moment(row.toDate).format('YYYY-MM-DD'),
                              )
                                ? '#fff'
                                : '#f0ffed',
                          }}>
                          <StyledTableCell component="th" scope="row">
                            {row.status === 'approved' &&
                            (moment(moment().format('YYYY-MM-DD')).isBetween(
                              moment(row.fromDate).format('YYYY-MM-DD'),
                              moment(row.toDate).format('YYYY-MM-DD'),
                            ) ||
                              moment(moment().format('YYYY-MM-DD')).isSame(
                                moment(row.fromDate).format('YYYY-MM-DD'),
                              ) ||
                              moment(moment().format('YYYY-MM-DD')).isSame(
                                moment(row.toDate).format('YYYY-MM-DD'),
                              ))
                              ? 'On Leave'
                              : row.status === 'approved'
                              ? 'Planned'
                              : row.status}
                            {row.status === 'approved'
                              ? moment(moment().format('YYYY-MM-DD')).isBetween(
                                  moment(row.fromDate).format('YYYY-MM-DD'),
                                  moment(row.toDate).format('YYYY-MM-DD'),
                                ) ||
                                moment(moment().format('YYYY-MM-DD')).isSame(
                                  moment(row.fromDate).format('YYYY-MM-DD'),
                                ) ||
                                (moment(moment().format('YYYY-MM-DD')).isSame(
                                  moment(row.toDate).format('YYYY-MM-DD'),
                                ) ? (
                                  ''
                                ) : (
                                  <Typography
                                    component={'div'}
                                    sx={{
                                      color: '#1D9900',
                                      fontFamily: 'Red Hat Display Medium',
                                      fontSize: '8px',
                                      paddingTop: '3px',
                                    }}>
                                    APPROVED
                                  </Typography>
                                ))
                              : ''}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row.fromDate).format('MM/DD/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row.returnDate).format('MM/DD/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              onClick={handleClick('bottom-end', row, lStatus)}
                              style={{
                                backgroundColor: 'transparent',
                                padding: 0,
                              }}>
                              <Avatar
                                variant="square"
                                alt="Profile"
                                src={DotsIcon}
                                style={{
                                  height: 18,
                                  width: 15,
                                }}
                              />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {props.leaveReturnedHistory &&
                    props.leaveReturnedHistory.map(
                      (row: any, index: number) => {
                        return (
                          <StyledTableRow
                            key={index}
                            style={{ background: '#f8f8fa' }}>
                            <StyledTableCell component="th" scope="row">
                              Returned
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {moment(row.fromDate).format('MM/DD/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {moment(row.returnDate).format('MM/DD/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell />
                          </StyledTableRow>
                        );
                      },
                    )}
                  {props.leaveHistoryDenied &&
                    props.leaveHistoryDenied.map((row: any, index: number) => {
                      return (
                        <StyledTableRow
                          key={index}
                          style={{ background: '#f8f8fa' }}>
                          <StyledTableCell component="th" scope="row">
                            Denied
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row.fromDate).format('MM/DD/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row.returnDate).format('MM/DD/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Button
                              onClick={handleClick('bottom-end', row, 'denied')}
                              style={{
                                backgroundColor: 'transparent',
                                padding: 0,
                              }}>
                              <Avatar
                                variant="square"
                                alt="Profile"
                                src={DotsIcon}
                                style={{
                                  height: 18,
                                  width: 15,
                                }}
                              />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {open5 && (
              <ClickAwayListener onClickAway={() => setOpen5(false)}>
                <Popper
                  className="popwidget"
                  open={open5}
                  anchorEl={anchorEl}
                  placement={placement}
                  transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <Typography sx={{ p: 2 }}>
                          <p>
                            <a
                              href="#"
                              style={{
                                color: '#fff',
                                textDecoration: 'inherit',
                              }}
                              onClick={() => {
                                setOpen5(false);
                                setEditJSON({
                                  ...editJSON,
                                  open: true,
                                });
                              }}>
                              Request To Edit
                            </a>
                          </p>
                          {leaveStatus === 'denied' ||
                          leaveStatus === 'onLeave' ? (
                            <Typography />
                          ) : (
                            <p>
                              <a
                                href="#"
                                style={{
                                  color: '#fff',
                                  textDecoration: 'inherit',
                                }}
                                onClick={() => {
                                  setOpen5(false);
                                  setDeleteJSON({
                                    ...deleteJSON,
                                    open: true,
                                  });
                                }}>
                                Request To Cancel
                              </a>
                            </p>
                          )}
                        </Typography>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </ClickAwayListener>
            )}
          </Typography>
        </CardContent>
      </Card>
      <Modal
        open={deleteJSON.open}
        onClose={closeDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1} className="confirmation-widget">
          <Typography id="modal-modal-title" component="h2">
            Do you want to Cancel your leave request?
          </Typography>
          <Typography className="confirm-btn model-btn" component={'div'}>
            <Button
              variant="outlined"
              onClick={closeDelete}
              style={{ textTransform: 'capitalize' }}>
              No, Cancel
            </Button>
            <Button variant="contained" onClick={DeleteFunction}>
              YES, Proceed
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={editJSON.open}
        onClose={closeEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style2} className="model-widget">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            REQUEST TO EDIT
          </Typography>
          <Typography
            component="h4"
            id="modal-modal-description"
            style={{ marginTop: 0 }}>
            Describe what changes you want to make to your leave request. A
            RiseIt admin will review this request.
          </Typography>
          <Typography>
            <TextField
              sx={{ p: 0 }}
              className="common-textarea"
              id="outlined-multiline-static"
              multiline
              rows={3}
              value={editJSON.comments}
              onChange={(e: any) =>
                setEditJSON({
                  ...editJSON,
                  comments: e?.target?.value,
                })
              }
              inputProps={{
                maxLength: 150,
              }}
            />
          </Typography>
          <Typography className="model-btn" component={'div'}>
            <Button variant="text" onClick={closeEdit}>
              NO, Cancel
            </Button>
            <Button variant="contained" onClick={EditFunction}>
              Submit
            </Button>
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default LeaveHistory;
