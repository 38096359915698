import PrivateLayout from 'components/PrivateLayout';
import { PayPeriod } from 'pages/consultant/PayPeriod';
import Admins from 'pages/private/Admins';
import Clients from 'pages/private/Clients';
import ClientsSub from 'pages/private/Clients/ClientsSub';
import ConsultantsProfile from 'pages/private/ConsultantProfile';
import Consultants from 'pages/private/Consultants';
import ConsultantProjects from 'pages/private/Consultants/ConsultProjects';
import ConsultSub from 'pages/private/Consultants/ConsultSub';
import { Dashboard } from 'pages/private/Dashboard';
import Leavetracker from 'pages/private/LeaveTracker';
import Myprofile from 'pages/private/Myprofile';
import Payrollsummary from 'pages/private/PayrollSummary';
import Queue from 'pages/private/queue';
import Users from 'pages/private/Users';
import VersionHistoryAdd from 'pages/public/VersionHistory/add';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AdminPrivateRoutes } from '../index';

const PrivateRoutes: React.FC = (): React.ReactElement => {
  const {
    DASHBOARD,
    MYPROFILE,
    ADMINS,
    CONSULTANTS,
    CLIENTS,
    CLIENTSSUB,
    CONSULTANTSSUB,
    PROJECTS,
    CONSULTANTSPROFILE,
    QUEUE,
    PAYROLLSUMMARY,
    LEAVETRACKER,
    PAYPERIOD,
    USERS,
    VERSIONHISTORYADD,
  } = AdminPrivateRoutes;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={CONSULTANTS} />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout
            routes={{
              path: DASHBOARD,
              show: false,
              title: 'Dashboard',
            }}
            from={'dashboard'}
            showHeader={false}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route path={VERSIONHISTORYADD} element={<VersionHistoryAdd />} />
      <Route
        path={ADMINS}
        element={
          <PrivateLayout
            routes={{
              path: ADMINS,
              show: false,
              title: 'Admins',
            }}
            from={'admins'}
            showHeader={true}>
            <Admins />
          </PrivateLayout>
        }
      />
      <Route
        path={USERS}
        element={
          <PrivateLayout
            routes={{
              path: USERS,
              show: false,
              title: 'users',
            }}
            from={'users'}
            showHeader={true}>
            <Users />
          </PrivateLayout>
        }
      />
      <Route
        path={CLIENTS}
        element={
          <PrivateLayout
            routes={{
              path: CLIENTS,
              show: false,
              title: 'Clients',
            }}
            from={'clients'}
            showHeader={true}>
            <Clients />
          </PrivateLayout>
        }
      />
      <Route
        path={CLIENTSSUB}
        element={
          <PrivateLayout
            routes={{
              path: CLIENTSSUB,
              show: false,
              title: 'Clients Sub',
            }}
            from={'clients'}
            showHeader={false}>
            <ClientsSub />
          </PrivateLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout
            from={'profile'}
            routes={{
              path: MYPROFILE,
              show: true,
              title: 'My Profile',
            }}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CONSULTANTS}
        element={
          <PrivateLayout
            routes={{
              path: CONSULTANTS,
              show: true,
              title: 'Consultants',
            }}
            from={'consultants'}
            showHeader={true}>
            <Consultants />
          </PrivateLayout>
        }
      />
      <Route
        path={CONSULTANTSSUB}
        element={
          <PrivateLayout
            routes={{
              path: CONSULTANTSSUB,
              show: true,
              title: 'ConsultantsSub',
            }}
            from={'consultantsSub'}
            showHeader={true}>
            <ConsultSub />
          </PrivateLayout>
        }
      />
      <Route
        path={PROJECTS}
        element={
          <PrivateLayout
            routes={{
              path: PROJECTS,
              show: true,
              title: 'Projects',
            }}
            from={'projects'}
            showHeader={true}>
            <ConsultantProjects />
          </PrivateLayout>
        }
      />
      <Route
        path={CONSULTANTSPROFILE}
        element={
          <PrivateLayout
            routes={{
              path: CONSULTANTSPROFILE,
              show: true,
              title: 'Consultant profile',
            }}
            from={'consultants'}
            showHeader={false}>
            <ConsultantsProfile />
          </PrivateLayout>
        }
      />
      <Route
        path={QUEUE}
        element={
          <PrivateLayout
            routes={{
              path: QUEUE,
              show: true,
              title: 'Queue',
            }}
            from={'queue'}
            showHeader={true}>
            <Queue />
          </PrivateLayout>
        }
      />
      <Route
        path={PAYROLLSUMMARY}
        element={
          <PrivateLayout
            routes={{
              path: PAYROLLSUMMARY,
              show: true,
              title: 'payroll',
            }}
            from={'payroll'}
            showHeader={true}>
            <Payrollsummary />
          </PrivateLayout>
        }
      />
      <Route
        path={LEAVETRACKER}
        element={
          <PrivateLayout
            routes={{
              path: LEAVETRACKER,
              show: true,
              title: 'leavetracker',
            }}
            from={'leavetracker'}
            showHeader={true}>
            <Leavetracker />
          </PrivateLayout>
        }
      />
      <Route
        path={PAYPERIOD}
        element={
          <PrivateLayout
            routes={{
              path: PAYPERIOD,
              show: false,
              title: 'PayPeriod',
            }}
            from={'PayPeriod'}
            showHeader={false}>
            <PayPeriod />
          </PrivateLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PrivateRoutes;
