import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backText: {
    color: '#005EBF',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 12,
  },
  commondownload: {
    border: '1px solid #E0E0E0 !important',
    borderRadius: '10px !important',
  },
  commondrop: {
    '& li': {
      color: '#fff',
      fontFamily: 'Red Hat Display Bold !important',
      fontSize: '15px !important',
      lineHeight: '36px',
    },
  },
  defaultpagination: {
    '& .MuiTablePagination-actions': {
      '& .MuiButtonBase-root': {
        '&:last-child': {
          borderRadius: '0px 10px 10px 0px',
        },
        border: '1px solid #E0E0E0',
        borderRadius: '10px 0px 0px 10px',
      },
    },
  },

  defaulttable: {
    '& tbody': {
      '& th, td': {
        color: '#292929',
        fontFamily: 'Red Hat Display Medium',
        fontSize: '13px',
        fontStyle: 'normal',
        padding: '0px',
      },
      '& tr': {
        borderBottom: '1px solid #eee',
      },
    },

    '& thead': {
      '& th': {
        '& em': {
          color: '#292929',
          fontFamily: 'Red Hat Display Bold',
          fontSize: '11px',
          fontStyle: 'normal',
        },
        '& span': {
          margin: '2px 10px',
        },
        color: '#979598',
        fontFamily: 'Red Hat Display Regular',
        fontSize: '12px',
        padding: '0',
      },
      background: '#F8F8FA',
    },
  },
  header1: {
    alignItems: 'center',
    background: '#fff',
    height: 64,
    marginTop: 0,
    padding: 15,
  },
  profilebox: {
    '& img': {
      background: '#eee',
      borderRadius: '50%',
      height: '40px',
      padding: '5px',
      width: '40px',
    },
    '& span': {
      color: '#292929',
      fontFamily: 'Red Hat Display medium',
      fontSize: '24px',
      padding: '0 14px',
    },
    alignItems: 'center',
    display: 'flex',
  },
  subheader: {
    '& .MuiButtonGroup-grouped': {
      '&:hover': {
        background: '#005EBF',
      },
      background: '#005EBF',
      borderColor: '#003F80 !important',
      fontFamily: 'Red Hat Display Bold',
      height: '40px',
      paddingLeft: '15px',
      textTransform: 'capitalize',
    },
    '& p': {
      '& span': {
        '& svg': {
          Height: '25px',
          Width: '35px',
          position: 'relative',
          top: '6px',
        },
        fontFamily: 'Red Hat Display Bold',
      },
      color: '#979598',
      fontFamily: 'Red Hat Display Medium',
      fontSize: '14px',
      padding: '0 20px 0 0',
    },
    alignItems: 'center',
    background: '#fff',
    marginTop: 5,
    padding: '8px 20px',
  },
}));

export default useStyles;
