import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Modal,
  Stack,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import arrowcolumns from 'assets/images/arrowcolumns.svg';
import DotsIcon from 'assets/images/dots.png';
import profileimg from 'assets/images/profileimg.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import InfinityScroll from 'components/InfinityScroll';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { AddAdmin } from 'types';
import useWindowDimensions from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addAdminSchema } from 'utils/ValidatorSchema';

import useStyles from './style';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display Regular',
    fontSize: 12,
    padding: 8,
    paddingBottom: 12,
    paddingLeft: 0,
    paddingTop: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fff',
    borderColor: '#d4d4d6',
    borderWidth: 1,
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    padding: 5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const style1 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const staticParam = {
  order: 'asc',
  searchWord: '',
  skip: 0,
  sortby: 'name',
  take: 20,
};

const Admins: React.FC<any> = () => {
  const { height } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [open1, setOpen1] = React.useState(false);
  const handleClick =
    (newPlacement: PopperPlacementType, row: any) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen1((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      setEditOrRemoveRow(row);
    };
  const classes = useStyles();
  const [param, setParam] = React.useState(staticParam);
  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [open2, setOpen2] = React.useState(false);
  const [removeId, setRemoveId] = React.useState('');
  const [editId, setEditId] = React.useState('');
  const [roleList, setRoleList] = React.useState<any>([]);
  const [editOrRemoveRow, setEditOrRemoveRow] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const handleOpen2 = () => {
    setOpen2(true);
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
    handleClose();
  };
  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control, formState, reset, setValue } =
    useForm<AddAdmin>({
      defaultValues: {
        email: '',
        empCode: '',
        firstName: '',
        lastName: '',
        roleId: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(addAdminSchema),
    });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditId('');
    setEditOrRemoveRow({});
    reset();
  };

  const removeAdmin = () => {
    deleteData(removeId, MASTER_ENDPOINT.Admins, true)
      .then((resp: any) => {
        setRemoveId('');
        handleClose2();
        setParam(staticParam);
      })
      .catch((err: any) => {});
  };

  const [loader, setLoader] = React.useState<boolean>(false);
  const [loader1, setLoader1] = React.useState<boolean>(true);
  const getData = () => {
    if (loader1) {
      setLoader1(false);
      setLoader(true);
    }
    let url =
      MASTER_ENDPOINT.Admins +
      '?order=' +
      param.order +
      '&sortby=' +
      param.sortby +
      '&skip=' +
      param.skip +
      '&take=' +
      param.take +
      '&excludeRoles=1,2,3,4,5,6';
    if (param.searchWord) {
      url += `&search=${String(param.searchWord)}`;
    }
    getAllListData(url)
      .then((resp: any) => {
        const mergedData =
          param.skip === 0 ? [...resp.data] : [...list, ...resp.data];
        setList(mergedData);
        setTotal(resp?.total);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const getRoles = () => {
    getAllListData(MASTER_ENDPOINT.Roles)
      .then((resp: any) => {
        if (resp?.data) {
          const filterData = resp?.data.filter((item: any) => item.id !== 7);
          setRoleList(
            filterData.sort(function (a, b) {
              return a.role === b.role ? 0 : a.role < b.role ? -1 : 1;
            }),
          );
        }
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const { searchText }: any = useAppSelector((store: any) => store.userLogin);

  const [searchLoad, setSearchLoad] = React.useState(false);
  useEffect(() => {
    if (searchLoad && param.searchWord !== searchText) {
      setParam({
        ...staticParam,
        searchWord: searchText,
      });
    }
    setSearchLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchLoad]);

  const setParamData = (str: string) => {
    if (param.sortby === str) {
      setParam({
        ...staticParam,
        order: param.order === 'asc' ? 'desc' : 'asc',
        searchWord: param.searchWord,
      });
    } else {
      setParam({
        ...staticParam,
        order: 'desc',
        searchWord: param.searchWord,
        sortby: str,
      });
    }
  };

  const submitForm = (data: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('status', 'true');
    formData.append('roleId', data['roleId']);
    formData.append('email', data['email']);
    formData.append('empCode', data['empCode']);
    formData.append('firstName', data['firstName']);
    formData.append('lastName', data['lastName']);
    if (editId) {
      updateData(editId, formData, MASTER_ENDPOINT.Admins, true)
        .then((resp: any) => {
          setLoading(false);
          handleClose();
          setParam({
            ...staticParam,
            order: param.order === 'asc' ? 'desc' : 'asc',
          });
        })
        .catch((err: any) => {
          setLoading(false);
        });
    } else {
      addNewData(formData, MASTER_ENDPOINT.Admins, {}, true)
        .then((resp: any) => {
          setLoading(false);
          handleClose();
          setParam({
            ...staticParam,
            order: param.order === 'asc' ? 'desc' : 'asc',
          });
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  const setEditRoW = (row: any) => {
    setEditId(row.id);
    setValue('email', row?.email);
    setValue('empCode', row?.empCode);
    setValue('firstName', row?.firstName);
    setValue('lastName', row?.lastName);
    setValue('roleId', row?.roleId);
    handleOpen();
  };

  const headRow = () => {
    return (
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ width: 60 }} />
          <StyledTableCell>
            Name
            <Link href={'#'} onClick={() => setParamData('name')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Email
            <Link href={'#'} onClick={() => setParamData('email')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Employee Code
            <Link href={'#'} onClick={() => setParamData('empCode')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Role
            <Link href={'#'} onClick={() => setParamData('roleId')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell align="right" />
        </TableRow>
      </TableHead>
    );
  };

  const rowProps = (row: any, index: number) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell
          component="th"
          scope="row"
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            borderWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            width: 60,
          }}>
          <Avatar
            variant="square"
            alt="Profile"
            src={
              row?.imageId
                ? `${process.env.REACT_APP_APIURL}${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    MASTER_ENDPOINT.Files
                  }/${String(row?.imageId)}`
                : profileimg
            }
            style={{
              background: '#e7e7e7',
              borderRadius: '10%',
              height: 40,
              width: 40,
            }}
          />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row?.lastName + ', ' + row?.firstName}
        </StyledTableCell>
        <StyledTableCell>{row?.email}</StyledTableCell>
        <StyledTableCell>{row?.empCode}</StyledTableCell>
        <StyledTableCell style={{ textTransform: 'capitalize' }}>
          {row?.role == 'Compliance' ? 'Compliance Admin' : row?.role}
        </StyledTableCell>
        <StyledTableCell align="right">
          <Button
            onClick={handleClick('bottom-end', row)}
            style={{ backgroundColor: 'transparent' }}>
            <Avatar
              variant="square"
              alt="Profile"
              src={DotsIcon}
              style={{ borderRadius: 10, height: 22, width: 20 }}
            />
          </Button>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container className={classes.header}>
          <Grid item md={8}>
            <Typography component={'div'}>
              <span className={classes.totalText}>Total Admins: {total}</span>
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Stack direction="row" justifyContent="end">
              <Button
                onClick={handleOpen}
                variant="contained"
                endIcon={<AddIcon />}
                className={'plusAddButton'}>
                Add Admin
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid container className={classes.tableview}>
          <TableContainer>
            <InfinityScroll
              paramState={setParam}
              height={height - 160}
              scrollableTargetId={'Admins'}
              data={list}
              rowPorops={rowProps}
              headRow={headRow}
              count={total}
              columnCount={6}
            />
          </TableContainer>
        </Grid>
        {open1 && (
          <ClickAwayListener onClickAway={() => setOpen1(false)}>
            <Popper
              className="popwidget"
              open={open1}
              anchorEl={anchorEl}
              placement={placement}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography sx={{ p: 2 }}>
                      <p style={{ margin: 0, padding: 5 }}>
                        <Button
                          onClick={() => {
                            setOpen1(false);
                            setEditRoW(editOrRemoveRow);
                          }}
                          sx={{
                            backgroundColor: 'transparent',
                            color: '#fff',
                            fontFamily: 'Red Hat Display Bold',
                            fontSize: 15,
                            justifyContent: 'flex-start',
                            margin: 0,
                            padding: 0,
                            textTransform: 'capitalize',
                          }}>
                          Edit
                        </Button>
                      </p>
                      <p style={{ margin: 0, padding: 5 }}>
                        <Button
                          onClick={() => {
                            handleOpen2();
                            setRemoveId(editOrRemoveRow?.['id']);
                          }}
                          sx={{
                            backgroundColor: 'transparent',
                            color: '#fff',
                            fontFamily: 'Red Hat Display Bold',
                            fontSize: 15,
                            justifyContent: 'flex-start',
                            margin: 0,
                            padding: 0,
                            textTransform: 'capitalize',
                          }}>
                          Remove
                        </Button>
                      </p>
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style1} className="confirmation-widget">
            <Typography id="modal-modal-title" component="h2">
              Are you sure you want to Remove this Admin?
            </Typography>
            <Typography className="confirm-btn model-btn" component={'div'}>
              <Button variant="outlined" onClick={handleClose2}>
                No, Cancel
              </Button>
              <Button variant="contained" onClick={removeAdmin}>
                YES, Remove Admin
              </Button>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <form onSubmit={handleSubmit(submitForm)}>
            <Box sx={style} className="model-widget">
              <Typography id="modal-modal-title" component="h2">
                {editId ? 'Edit' : 'Add'} Admin
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Typography component={'div'}>
                  <Typography>
                    <label>
                      First Name<span className="errorText">{'*'}</span>
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="firstName"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.firstName?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>
                      Last Name<span className="errorText">{'*'}</span>
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="lastName"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            ref={ref}
                            className={classes.custominput}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.lastName?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>
                      Email<span className="errorText">{'*'}</span>
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            disabled={editId ? true : false}
                            placeHolder=""
                            className={classes.custominput}
                            value={value}
                            name={name}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.email?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>
                      Employee Code<span className="errorText">{'*'}</span>
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="empCode"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            className={classes.custominput}
                            value={value}
                            name={name}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.empCode?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography>
                    <label>
                      Role<span className="errorText">{'*'}</span>
                    </label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="roleId"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <>
                            <Select
                              style={{ textTransform: 'capitalize' }}
                              className="common-select"
                              value={value}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              name={name}
                              ref={ref}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}>
                              {roleList.map((row: any, index: number) => {
                                return (
                                  <MenuItem
                                    value={row.id.toString()}
                                    key={index}
                                    style={{ textTransform: 'capitalize' }}>
                                    {row.role == 'Compliance'
                                      ? 'Compliance Admin'
                                      : row.role}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {formState.errors?.roleId?.message && (
                              <FormHelperText className="errorText">
                                {formState.errors?.roleId?.message}
                              </FormHelperText>
                            )}
                          </>
                        );
                      }}
                    />
                  </Typography>
                </Typography>
              </Typography>
              <Typography sx={{ paddingTop: '20px' }} />
              <Divider />
              <Typography sx={{ paddingTop: '20px' }} />
              <Typography className="model-btn" component={'div'}>
                <Button variant="text" onClick={handleClose}>
                  Cancel
                </Button>
                <CustomButton
                  variant="contained"
                  type="submit"
                  name={editId ? 'Save changes' : 'Send Invite'}
                  loading={loading}
                  isStyle={true}
                />
              </Typography>
            </Box>
          </form>
        </Modal>
      </Typography>
    </LoadingOverlay>
  );
};

export default Admins;
