import 'react-phone-input-2/lib/style.css';

import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ChangePassword from 'pages/common/ChangePassword';
import LogOut from 'pages/common/Logout';
import Profile from 'pages/common/Profile';
import React from 'react';

import useStyles from '../PrivateStyle';

export const Myprofile: React.FC<any> = () => {
  const classes = useStyles();

  return (
    <Typography component={'div'} style={{ marginTop: 50 }}>
      <Grid container>
        <Grid item md={12}>
          <Typography component={'div'} className={classes.profilecard}>
            <Typography component="h6">Personal Info</Typography>
            <Profile />
          </Typography>
          <Typography component={'div'} className={classes.profilecard}>
            <Typography component="h6">More Options</Typography>
            <Card sx={{ boxShadow: 0 }}>
              <CardContent>
                <Typography component={'div'}>
                  <ul className={classes.optiondetails}>
                    <li>
                      <ChangePassword />
                    </li>
                    <li>Settings</li>
                    <li>
                      <a
                        style={{ color: 'inherit', textDecoration: 'unset' }}
                        href="https://riseits.com/company-privacy-policy/"
                        target="_blank"
                        rel="noreferrer">
                        Privacy Policy
                      </a>
                    </li>
                    <li>Terms & Conditions</li>
                    <li className={classes.logout}>
                      <LogOut />
                    </li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Myprofile;
