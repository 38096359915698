import {
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import SidebarLogo from 'assets/images/RiseIt.svg';
import { CKEditor } from 'ckeditor4-react';
import CustomInput from 'components/CustomInput/customInput';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

// import useStyles from '../publicStyles';
const drawerWidth = 0;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    borderColor: '#d4d4d6',
    borderWidth: 1,
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 12,
    letterSpacing: '1.0px',
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: 'transparent',
    borderColor: '#E0E0E0',
    borderWidth: 0.3,
    color: '#241A2E',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
    padding: 8,
    paddingBottom: 15,
    paddingTop: 15,
    verticalAlign: 'top',
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#340002',
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const VersionHistoryAdd: React.FC = () => {
  const [show, setShow] = useState<boolean>(true);
  const [editId, setEditId] = useState<string>('');
  const [versionId, setversionId] = useState<string>('');
  const [releaseNotes, setReleaseNotes] = useState<any>('');
  const handleInstanceReady = ({ editor }: any) => {
    setReleaseNotes(editor.getData());
  };

  const handleCancel = () => {
    setversionId('');
    setShow(false);
    setReleaseNotes('');
    setTimeout(() => setShow(true), 500);
    setEditId('');
  };

  const [list, setList] = useState<any>([]);
  useEffect(() => {
    getVersionHistory();
  }, []);

  const getVersionHistory = () => {
    getAllListData(MASTER_ENDPOINT.VersionHistory)
      .then((resp: any) => {
        setList(resp?.data);
      })
      .catch((err: any) => {});
  };

  const handleSave = () => {
    if (!versionId) {
      toast.error('Please enter version code', alertOptions);
      return;
    } else if (!releaseNotes) {
      toast.error('Please enter version description', alertOptions);
      return;
    }
    const postJson = {
      releaseNotes,
      type: 'web',
      versionId,
    };
    addNewData(postJson, MASTER_ENDPOINT.VersionHistory, {}, true)
      .then((resp: any) => {
        setShow(false);
        setReleaseNotes('');
        setTimeout(() => setShow(true), 500);
        setversionId('');
        getVersionHistory();
      })
      .catch((err: any) => {});
  };

  const handleUpdate = () => {
    if (!versionId) {
      toast.error('Please enter version code', alertOptions);
      return;
    } else if (!releaseNotes) {
      toast.error('Please enter version description', alertOptions);
      return;
    }
    const postJson = {
      releaseNotes,
      type: 'web',
      versionId,
    };
    updateData(editId, postJson, MASTER_ENDPOINT.VersionHistory, true)
      .then((resp: any) => {
        setShow(false);
        setReleaseNotes('');
        setTimeout(() => setShow(true), 500);
        setversionId('');
        getVersionHistory();
        setEditId('');
      })
      .catch((err: any) => {});
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={true} style={{ boxShadow: 'unset' }}>
          <Toolbar sx={{ display: 'inline' }}>
            <Typography variant="h6" noWrap component="div">
              <Typography
                component={'div'}
                className="d-flex-ja flex-column"
                sx={{
                  padding: '12px',
                }}>
                <img
                  src={SidebarLogo}
                  alt="Riseit"
                  style={{
                    width: 150,
                  }}
                />
              </Typography>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Typography sx={{ paddingTop: '100px' }} />
      <Grid container sx={{ padding: '20px' }}>
        <Grid item xs={6}>
          <Typography className={'_version_bold_text'} component="h2">
            Version History Details
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end' }}>
          <Button
            variant="contained"
            className={'whiteButton'}
            onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={'orangeButton'}
            onClick={() => (editId ? handleUpdate() : handleSave())}>
            {editId ? 'Update' : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingX: '20px' }}>
        <Grid item xs={8}>
          <Card sx={{ boxShadow: 0, marginRight: '20px' }}>
            <CardContent>
              <Typography component={'div'}>
                <Typography style={{ width: '100%' }}>
                  <label>Version Code</label>
                  <CustomInput
                    extraStyle={{ width: '100%' }}
                    placeHolder="Version Code"
                    value={versionId}
                    onChange={(e) => setversionId(e.target.value)}
                  />
                  <Typography sx={{ paddingTop: '10px' }} />
                  <label>Version Description</label>
                  {show && (
                    <CKEditor
                      initData={releaseNotes}
                      onInstanceReady={handleInstanceReady}
                      onChange={handleInstanceReady}
                      style={{ height: 500 }}
                    />
                  )}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ boxShadow: 0 }}>
            <CardContent
              sx={{
                maxHeight: '552px',
                overflowY: 'scroll',
              }}>
              <Typography component={'div'}>
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">
                          VERSION CODE
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          UPDATED ON
                        </StyledTableCell>
                        <StyledTableCell align="left">ACTIONS</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.map((item: any, index: number) => {
                        return (
                          <TableRow key={index}>
                            <StyledTableCell align="left">
                              {item.versionId}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {moment(item.createdAt).format('DD-MM-YYYY')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <a
                                href={'javascript:void(0)'}
                                onClick={() => {
                                  setversionId(item.versionId);
                                  setShow(false);
                                  setReleaseNotes(item.releaseNotes);
                                  setTimeout(() => setShow(true), 500);
                                  setEditId(item.id);
                                }}>
                                Edit
                              </a>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default VersionHistoryAdd;
