import 'react-phone-input-2/lib/style.css';

import { Link, Typography } from '@mui/material';
import { PublicRoutes } from 'config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../public/publicStyles';

export const CopyAndVersionHistory: React.FC<any> = () => {
  const navigate = useNavigate();
  const [versionName, setVersionName] = useState<any>('');
  useEffect(() => {
    getVersionHistory();
  }, []);

  const getVersionHistory = () => {
    getAllListData(MASTER_ENDPOINT.VersionHistory)
      .then((resp: any) => {
        if (resp?.data?.length) {
          setVersionName(resp?.data?.[0]?.versionId);
        }
      })
      .catch((err: any) => {});
  };
  const classes = useStyles();
  return (
    <Typography className={classes.footercopyright}>
      © Copyright {moment().format('YYYY')} | RiseIT® Solutions | All Rights
      Reserved | Privacy Policy |{' '}
      {versionName && (
        <Link
          href="javascript:void(0);"
          underline="always"
          style={{
            color: '#979598',
            fontFamily: 'Red Hat Display SemiBold',
            fontSize: 12,
            textDecoration: 'unset',
            textDecorationColor: '#979598',
          }}
          onClick={() => navigate(PublicRoutes.VERSIONHISTORY)}>
          {versionName}
        </Link>
      )}
    </Typography>
  );
};

export default CopyAndVersionHistory;
