import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'utils/Handlers';

export const getSingleData = async (
  id: string,
  endpoint: string,
  toastShow?: boolean,
) => {
  const apiParams = {
    url: id !== '' ? `${endpoint}/?id=${id}` : endpoint,
  };

  return await getRequest(apiParams)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      if (error.error.status === 400) {
        let errorMessage = '';
        if (Array.isArray(error.error.data?.message)) {
          if (typeof error.error.data?.message?.[0] === 'string') {
            errorMessage = error.error.data?.message?.[0];
          }
        } else {
          // Array type of message
          errorMessage = error.error.data?.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      } else {
        let errorMessage = '';
        if (typeof error.error.message === 'string') {
          errorMessage = error.error.message;
        } else {
          // Array type of message
          errorMessage = error.error.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
      }
    });
};

export const getAllListData = async (endpoint: string, toastShow?: boolean) => {
  const apiParams = {
    url: endpoint,
  };
  return await getRequest(apiParams)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      if (error.error.status === 400) {
        let errorMessage = '';
        if (Array.isArray(error.error.data?.message)) {
          if (typeof error.error.data?.message?.[0] === 'string') {
            errorMessage = error.error.data?.message?.[0];
          }
        } else {
          // Array type of message
          errorMessage = error.error.data?.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      } else {
        let errorMessage = '';
        if (typeof error.error.message === 'string') {
          errorMessage = error.error.message;
        } else {
          // Array type of message
          errorMessage = error.error.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      }
    });
};

export const addNewData = async (
  data: any,
  endpoint: string,
  options?: any,
  toastShow?: boolean,
) => {
  const apiParams = {
    data,
    url: endpoint,
  };

  return await postRequest(apiParams, options)
    .then((response: any) => {
      if (toastShow) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      if (error.error.status === 400) {
        let errorMessage = '';
        if (Array.isArray(error.error.data?.message)) {
          if (typeof error.error.data?.message?.[0] === 'string') {
            errorMessage = error.error.data?.message?.[0];
          }
        } else {
          // Array type of message
          errorMessage = error.error.data?.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      } else {
        let errorMessage = '';
        if (typeof error.error.message === 'string') {
          errorMessage = error.error.message;
        } else {
          // Array type of message
          errorMessage = error.error.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      }
    });
};

export const updateData = async (
  id: string,
  data: any,
  endpoint: string,
  toastShow?: boolean,
) => {
  const apiParams = {
    data,
    url: id !== '' ? `${endpoint}/${id}` : `${endpoint}`,
  };

  return await putRequest(apiParams)
    .then((response: any) => {
      if (toastShow) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      if (error.error.status === 400) {
        let errorMessage = '';
        if (Array.isArray(error.error.data?.message)) {
          if (typeof error.error.data?.message?.[0] === 'string') {
            errorMessage = error.error.data?.message?.[0];
          }
        } else {
          // Array type of message
          errorMessage = error.error.data?.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      } else {
        let errorMessage = '';
        if (typeof error.error.message === 'string') {
          errorMessage = error.error.message;
        } else {
          // Array type of message
          errorMessage = error.error.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      }
    });
};

export const deleteData = async (
  id: string,
  endpoint: string,
  toastShow?: boolean,
) => {
  const apiParams = {
    url: id ? `${endpoint}/${id}` : `${endpoint}`,
  };

  return await deleteRequest(apiParams)
    .then((response: any) => {
      if (toastShow) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      if (error.error.status === 400) {
        let errorMessage = '';
        if (Array.isArray(error.error.data?.message)) {
          if (typeof error.error.data?.message?.[0] === 'string') {
            errorMessage = error.error.data?.message?.[0];
          }
        } else {
          // Array type of message
          errorMessage = error.error.data?.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      } else {
        let errorMessage = '';
        if (typeof error.error.message === 'string') {
          errorMessage = error.error.message;
        } else {
          // Array type of message
          errorMessage = error.error.message.toString();
        }
        if (toastShow) {
          toast.error(errorMessage || String(error), alertOptions);
        }
        throw errorMessage;
      }
    });
};

export const getS3file = async (key: any, url: string) => {
  const fileCheck = checkContentType(key.key);
  key.contentType = fileCheck;
  return await addNewData(key, url, { responseType: 'blob' });
};

export const checkContentType = (fileKey) => {
  if (fileKey) {
    const formate = fileKey.split('.').pop();
    const fileFormat = formate ? formate.toLowerCase() : '';
    switch (fileFormat) {
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'pdf':
        return 'application/pdf';
      case 'csv':
        return 'text/csv';
      case 'doc':
        return 'application/msword';
      case 'rtf':
        return 'application/rtf';
      case 'txt':
        return 'text/plain';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'bmp':
        return 'image/bmp';
      case 'gif':
        return 'image/gif';
      case 'ico':
        return 'image/vnd.microsoft.icon';
      case 'jpeg' || 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'svg':
        return 'image/svg+xml';
      case 'tif' || 'tiff':
        return 'image/tiff';
      case 'webp':
        return 'image/webp';
      default:
        return '';
    }
  }
};
