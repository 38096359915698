// import { BorderBottom } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  colheadersticky: {
    background: '#DCE6EC',
    position: 'sticky',
    zIndex: '1',
  },
  colsticky: {
    background: '#EDF3F7',
    position: 'sticky',
    zIndex: 1,
  },
  colsticky1: {
    background: '#EDF3F7',
    position: 'sticky',
    zIndex: '1',
  },
  colsticky2: {
    background: '#EDF3F7',
    position: 'sticky',
    zIndex: '1',
  },
  custominput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px 12.5px 20px !important',
    },
    width: '100%',
  },
  defaultpagination: {
    '& .MuiTablePagination-actions': {
      '& .MuiButtonBase-root': {
        '&:last-child': {
          borderRadius: '0px 10px 10px 0px',
        },
        border: '1px solid #E0E0E0',
        borderRadius: '10px 0px 0px 10px',
      },
    },
    '& .MuiTablePagination-displayedRows': {
      fontFamily: 'Red Hat Display Regular !important',
    },
  },
  logout: {
    color: '#BF0000 !important',
  },
  optiondetails: {
    '& li': {
      borderBottom: '1px solid #E0E0E0',
      color: '#292929',
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      lineHeight: '55px',
      listStyle: 'none',
      padding: '0',
    },
    margin: '0px',
    padding: '0 15px',
  },
  passwordinput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 50px 12.5px 20px !important',
    },
    width: '100%',
  },
  profilebox: {
    '& img': {
      background: '#eee',
      borderRadius: '50%',
      height: '80px',
      padding: '5px',
      width: '80px',
    },
    display: 'flex',
  },

  profilecard: {
    '& h6': {
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      margin: '10px 0',
    },

    '& p': {
      color: '#979598',
      fontFamily: 'Red Hat Display SemiBold',
      fontSize: '14px',
      textAlign: 'right',
      textDecoration: 'underline',
    },
    margin: '0 auto !important',
    width: '442px',
  },

  profiledetails: {
    '& li': {
      '& span': {
        display: 'inline-block',
        fontFamily: 'Red Hat Display SemiBold',
        width: '70px',
      },
      color: '#292929',
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '22px',
      listStyle: 'none',
    },
    margin: '0px',
    padding: '0 30px 0',
  },

  summarytable: {
    '& tbody': {
      '& th': {
        borderBottom: '0px solid red',
        padding: '10px',
      },
      '& tr, td': {
        border: '1px solid #eee',
        padding: '10px',
      },
    },
    '& thead': {
      '& th': {
        padding: '5px 10px',
      },
      background: '#DCE6EC',
    },
  },
}));

export default useStyles;
