import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { ReactElement } from 'react';

type ButtonProps = {
  name: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onPress?: (src: any) => void;
  extraStyle?: any;
  variant?: any;
  loading?: boolean;
  form?: string;
  isStyle?: boolean;
};

const CustomButton = (props: ButtonProps): ReactElement => {
  const {
    name,
    className,
    onPress,
    type,
    extraStyle,
    variant,
    loading,
    form,
    isStyle,
  } = props;
  if (loading) {
    return (
      <LoadingButton
        sx={
          isStyle
            ? {
                paddingLeft: '40px !important',
                textTransform: 'capitalize',
              }
            : {
                '& .MuiLoadingButton-loadingIndicator': {
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
                backgroundColor: '#9c9a9d !important',
                borderRadius: '8px !important',
                color: '#FFFFFF !important',
                display: 'table !important',
                fontFamily: 'Red Hat Display Bold !important',
                fontSize: '16px !important',
                height: '40px',
                margin: '30px auto 65px !important',
                textTransform: 'capitalize',
                width: '380px',
              }
        }
        loading
        loadingPosition="start"
        variant="contained">
        Loading
      </LoadingButton>
    );
  }
  return (
    <Button
      className={className}
      sx={
        isStyle
          ? {}
          : {
              '& .MuiLoadingButton-loadingIndicator': {
                top: '50%',
                transform: 'translateY(-50%)',
              },
              backgroundColor: '#BF0000 !important',
              borderRadius: '8px !important',
              color: '#FFFFFF !important',
              display: 'table !important',
              fontFamily: 'Red Hat Display Bold !important',
              fontSize: '16px !important',
              height: '40px',
              margin: '30px auto 65px !important',
              width: '380px',
            }
      }
      onClick={onPress && onPress}
      style={extraStyle}
      variant={variant}
      form={form}
      type={type}>
      {name}
    </Button>
  );
};

export default CustomButton;
