import CreatePassword from 'pages/public/CreatePassword';
import ForgotPassword from 'pages/public/ForgotPassword';
import Login from 'pages/public/Login';
import VersionHistory from 'pages/public/VersionHistory';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PublicRoutes as PublicRoutesDirectives } from '../index';

const PublicRoutes: React.FC = () => {
  const {
    LOGIN,
    FORGOTPASSWORD,
    CREATEPASSWORD,
    RESETPASSWORD,
    VERSIONHISTORY,
  } = PublicRoutesDirectives;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={'/login'} replace />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={CREATEPASSWORD} element={<CreatePassword />} />
      <Route path={RESETPASSWORD} element={<CreatePassword />} />
      <Route path={VERSIONHISTORY} element={<VersionHistory />} />
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Routes>
  );
};

export default PublicRoutes;
