import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  custominput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px 12.5px 20px !important',
    },
    width: '100%',
  },
  header: {
    alignItems: 'center',
    background: '#fff',
    marginTop: 5,
    padding: 15,
  },
  tableview: {
    marginTop: 0,
  },
  totalText: {
    color: '#979598',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 14,
  },
}));

export default useStyles;
