import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Link,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import accordionarrow from 'assets/images/arrow-accordin.svg';
import CustomInput from 'components/CustomInput/customInput';
import DatePicker from 'components/DatePickerTable';
import moment from 'moment';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { addNewData, getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from './style';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 515,
};

export const PayPeriod: React.FC<any> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [list, setList] = React.useState<any>([]);
  const [existList, setExistList] = React.useState<any>({});
  const [year, setYear] = React.useState<number>();
  const currentYear = parseInt(moment().format('YYYY'));
  const [expand, setExpand] = React.useState<number>(0);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAcordion = (index) => {
    setExpand(index === expand ? -1 : index);
  };

  const [loader, setLoader] = React.useState<boolean>(false);
  const [loader1, setLoader1] = React.useState<boolean>(true);

  const getData = () => {
    if (loader1) {
      setLoader1(false);
      setLoader(true);
    }
    getAllListData(MASTER_ENDPOINT.Payperiods)
      .then((resp: any) => {
        if (resp?.data.length) {
          resp?.data.map((item: any) => {
            const fData1 = item.payPeriodQuarters.findIndex(
              (item1) => item1.quarter === 1,
            );
            const fData2 = item.payPeriodQuarters.findIndex(
              (item2) => item2.quarter === 2,
            );
            const fData3 = item.payPeriodQuarters.findIndex(
              (item3) => item3.quarter === 3,
            );
            const fData4 = item.payPeriodQuarters.findIndex(
              (item4) => item4.quarter === 4,
            );
            let r1 = {
              payPeriods: [],
              quarter: 1,
            };
            if (fData1 !== -1) {
              r1 = item.payPeriodQuarters[fData1];
            }
            let r2 = {
              payPeriods: [],
              quarter: 2,
            };
            if (fData2 !== -1) {
              r2 = item.payPeriodQuarters[fData2];
            }
            let r3 = {
              payPeriods: [],
              quarter: 3,
            };
            if (fData3 !== -1) {
              r3 = item.payPeriodQuarters[fData3];
            }
            let r4 = {
              payPeriods: [],
              quarter: 4,
            };
            if (fData4 !== -1) {
              r4 = item.payPeriodQuarters[fData4];
            }
            const rowData = [r1, r2, r3, r4];
            item.payPeriodQuarters = rowData;
            return item;
          });
          // eslint-disable-next-line no-unsafe-optional-chaining
          const maxC = Math.max(...resp?.data.map((o) => o.year));
          setYear(maxC + 1);
          setList(resp?.data ? resp?.data.sort((a, b) => b.year - a.year) : []);
        } else {
          setYear(currentYear);
          setExpand(currentYear);
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const saveDataExtra = () => {
    const filterArray = existList.PayPeriodQuarter.filter((item) =>
      item.payPeriod.length ? true : false,
    );
    existList.PayPeriodQuarter = filterArray;
    addNewData(existList, MASTER_ENDPOINT.Payperiods, {}, true)
      .then((resp: any) => {
        setExistList({});
        getData();
      })
      .catch((err: any) => {});
  };

  const saveData = () => {
    addNewData(
      {
        PayPeriodQuarter: [],
        year: year,
      },
      MASTER_ENDPOINT.Payperiods,
      {},
      true,
    )
      .then((resp: any) => {
        getData();
      })
      .catch((err: any) => {});
  };

  const yesNextYearData = () => {
    saveData();
    handleClose();
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container>
          <Grid
            container
            className={classes.header}
            sx={{
              justifyContent: 'flex-end',
            }}>
            <Typography
              onClick={() => handleOpen()}
              component={'div'}
              sx={{
                color: '#005EBF',
                cursor: 'pointer',
                fontFamily: 'Red Hat Display SemiBold',
                fontSize: '14px',
              }}>
              Do you want to add
              <Typography
                component={'span'}
                sx={{
                  color: '#005EBF',
                  cursor: 'pointer',
                  fontFamily: 'Red Hat Display Bold',
                  fontSize: '14px',
                }}>
                {' '}
                {year} Payroll Calendar?
              </Typography>
            </Typography>
          </Grid>
          <Typography component="div" className="container-block">
            <Typography
              sx={{ p: 1 }}
              component={'div'}
              className="common-accordin payroll-box">
              {list.map((item: any, index: number) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expand == index ? true : false}>
                    <Typography
                      component={'div'}
                      sx={{
                        display: 'flex',
                        padding: '8px !important',
                        width: '100%',
                      }}>
                      <Typography
                        component={'div'}
                        className="accordin-details"
                        sx={{
                          paddingLeft: '20px !important',
                        }}>
                        <Typography component={'p'}>
                          {item?.year}
                          <strong> Payroll Calendar</strong>
                        </Typography>
                        {existList?.year === item?.year && (
                          <Typography component={'p'}>
                            <Link
                              href="javascript:void(0);"
                              underline="always"
                              style={{
                                color: '#bf0500',
                                fontFamily: 'Red Hat Display SemiBold',
                                fontSize: 14,
                                textDecorationColor: '#bf0500',
                              }}
                              onClick={saveDataExtra}>
                              {'Save'}
                            </Link>
                          </Typography>
                        )}
                      </Typography>
                      <a
                        href={'#'}
                        onClick={(e) => toggleAcordion(index)}
                        style={{ margin: 15, marginBottom: 10, marginTop: 10 }}>
                        <img
                          src={accordionarrow}
                          className={expand == index ? 'expand-new' : ''}
                        />
                      </a>
                    </Typography>
                    <AccordionDetails sx={{ paddingX: '0px', paddingY: '0px' }}>
                      {item.payPeriodQuarters.map(
                        (item1: any, index1: number) => {
                          let hours = 0;
                          let holidays = 0;
                          let filterDataIndex = -1;
                          if (existList?.year) {
                            if (existList?.year === item?.year) {
                              filterDataIndex =
                                existList?.PayPeriodQuarter.findIndex(
                                  (d) => d.quarter === item1?.quarter,
                                );
                            }
                          }
                          return (
                            <Grid container key={index1}>
                              <Grid item xs={12}>
                                <Typography
                                  component={'div'}
                                  style={{ display: 'grid', width: '100%' }}
                                  className="common-table">
                                  <TableContainer
                                    className={classes.summarytable}
                                    sx={{
                                      '&::-webkit-scrollbar': {
                                        height: '8px',
                                        width: '8px',
                                      },
                                      '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#cfcfcf',
                                        borderRadius: 2,
                                      },
                                      '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#fffff',
                                        borderRadius: 2,
                                      },
                                    }}>
                                    <Table
                                      sx={{ width: '100%' }}
                                      aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className={classes.colheadersticky}
                                            style={{
                                              left: '0px',
                                              minWidth: '50px',
                                            }}>
                                            {item1?.quarter}st Qtr
                                          </TableCell>
                                          <TableCell
                                            className={classes.colheadersticky}
                                            style={{
                                              left: '50px',
                                              minWidth: '75px',
                                            }}
                                            align="left">
                                            Pay Periods Start
                                            <span
                                              className="errorText"
                                              style={{
                                                margin: 1,
                                              }}>
                                              {'*'}
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className={classes.colheadersticky}
                                            style={{
                                              left: '125px',
                                              minWidth: '200px',
                                            }}
                                            align="left">
                                            Pay Periods End
                                            <span
                                              className="errorText"
                                              style={{
                                                margin: 1,
                                              }}>
                                              {'*'}
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            style={{ minWidth: '200px' }}
                                            align="left">
                                            Pay Date
                                          </TableCell>
                                          <TableCell
                                            style={{ minWidth: '100px' }}
                                            align="left">
                                            Process w/ADP
                                          </TableCell>
                                          <TableCell
                                            style={{ minWidth: '100px' }}
                                            align="left">
                                            Workable Hrs
                                          </TableCell>
                                          <TableCell
                                            style={{ minWidth: '100px' }}
                                            align="left">
                                            Holiday
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {item1.payPeriods.map(
                                          (row: any, index2: number) => {
                                            hours += row.workableHours
                                              ? parseInt(row.workableHours)
                                              : 0;
                                            holidays += row.holidays
                                              ? parseInt(row.holidays)
                                              : 0;
                                            return (
                                              <TableRow key={index2}>
                                                <TableCell
                                                  align="center"
                                                  className={classes.colsticky}
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    left: '0px',
                                                    minWidth: '50px',
                                                  }}>
                                                  {index2 + 1}
                                                </TableCell>
                                                <TableCell
                                                  className={classes.colsticky}
                                                  align="left"
                                                  style={{
                                                    left: '50px',
                                                    minWidth: '75px',
                                                  }}>
                                                  {moment(row.startDate).format(
                                                    'MM/DD/YYYY',
                                                  )}
                                                </TableCell>
                                                <TableCell
                                                  className={classes.colsticky}
                                                  style={{
                                                    left: '125px',
                                                    minWidth: '200px',
                                                  }}
                                                  align="left">
                                                  {moment(row.endDate).format(
                                                    'MM/DD/YYYY',
                                                  )}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.payDate
                                                    ? moment(
                                                        row.payDate,
                                                      ).format('MM/DD/YYYY')
                                                    : '-'}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.processAdp
                                                    ? moment(
                                                        row.processAdp,
                                                      ).format('MM/DD/YYYY')
                                                    : '-'}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.workableHours}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {row.holidays}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          },
                                        )}
                                        {filterDataIndex !== -1 &&
                                          existList?.PayPeriodQuarter[
                                            filterDataIndex
                                          ] &&
                                          existList?.PayPeriodQuarter[
                                            filterDataIndex
                                          ].payPeriod.map(
                                            (
                                              extraRow: any,
                                              extraIndex: number,
                                            ) => {
                                              hours += extraRow.workableHours
                                                ? parseInt(
                                                    extraRow.workableHours,
                                                  )
                                                : 0;
                                              holidays += extraRow.holidays
                                                ? parseInt(extraRow.holidays)
                                                : 0;
                                              const tableNumber =
                                                item1.payPeriods.length +
                                                1 +
                                                extraIndex;
                                              return (
                                                <TableRow key={extraIndex}>
                                                  <TableCell
                                                    align="center"
                                                    className={
                                                      classes.colsticky
                                                    }
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      left: '0px',
                                                      // minWidth: '75px',
                                                    }}>
                                                    {String(tableNumber)}
                                                  </TableCell>
                                                  <TableCell
                                                    className={
                                                      classes.colsticky
                                                    }
                                                    align="left"
                                                    style={{
                                                      left: '50px',
                                                      // minWidth: '75px',
                                                    }}>
                                                    <DatePicker
                                                      placeHolder="Select"
                                                      value={extraRow.startDate}
                                                      onChange={(e: any) => {
                                                        extraRow.startDate =
                                                          moment(
                                                            new Date(e),
                                                          ).format(
                                                            'YYYY-MM-DD',
                                                          );
                                                        existList.PayPeriodQuarter[
                                                          filterDataIndex
                                                        ].payPeriod[
                                                          extraIndex
                                                        ] = extraRow;
                                                        setExistList({
                                                          ...existList,
                                                        });
                                                      }}
                                                      minDate={
                                                        new Date(
                                                          `12-10-${String(
                                                            item?.year - 1,
                                                          )}`,
                                                        )
                                                      }
                                                      maxDate={
                                                        new Date(
                                                          `12-31-${String(
                                                            item?.year,
                                                          )}`,
                                                        )
                                                      }
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    className={
                                                      classes.colsticky
                                                    }
                                                    style={{
                                                      left: '125px',
                                                      // minWidth: '200px',
                                                    }}
                                                    align="left">
                                                    {/* {row.endDate} */}
                                                    <DatePicker
                                                      placeHolder="Select"
                                                      value={extraRow.endDate}
                                                      onChange={(e: any) => {
                                                        extraRow.endDate =
                                                          moment(
                                                            new Date(e),
                                                          ).format(
                                                            'YYYY-MM-DD',
                                                          );
                                                        existList.PayPeriodQuarter[
                                                          filterDataIndex
                                                        ].payPeriod[
                                                          extraIndex
                                                        ] = extraRow;
                                                        setExistList({
                                                          ...existList,
                                                        });
                                                      }}
                                                      minDate={
                                                        new Date(
                                                          `12-10-${String(
                                                            item?.year - 1,
                                                          )}`,
                                                        )
                                                      }
                                                      maxDate={
                                                        new Date(
                                                          `12-31-${String(
                                                            item?.year,
                                                          )}`,
                                                        )
                                                      }
                                                    />
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {/* {row.payDate} */}
                                                    <DatePicker
                                                      placeHolder="Select"
                                                      value={extraRow.payDate}
                                                      onChange={(e: any) => {
                                                        extraRow.payDate =
                                                          moment(
                                                            new Date(e),
                                                          ).format(
                                                            'YYYY-MM-DD',
                                                          );
                                                        existList.PayPeriodQuarter[
                                                          filterDataIndex
                                                        ].payPeriod[
                                                          extraIndex
                                                        ] = extraRow;
                                                        setExistList({
                                                          ...existList,
                                                        });
                                                      }}
                                                      minDate={
                                                        new Date(
                                                          `12-10-${String(
                                                            item?.year - 1,
                                                          )}`,
                                                        )
                                                      }
                                                      maxDate={
                                                        new Date(
                                                          `12-31-${String(
                                                            item?.year,
                                                          )}`,
                                                        )
                                                      }
                                                    />
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {/* {row.processAdp} */}
                                                    <DatePicker
                                                      placeHolder="Select"
                                                      value={
                                                        extraRow.processAdp
                                                      }
                                                      onChange={(e: any) => {
                                                        extraRow.processAdp =
                                                          moment(
                                                            new Date(e),
                                                          ).format(
                                                            'YYYY-MM-DD',
                                                          );
                                                        existList.PayPeriodQuarter[
                                                          filterDataIndex
                                                        ].payPeriod[
                                                          extraIndex
                                                        ] = extraRow;
                                                        setExistList({
                                                          ...existList,
                                                        });
                                                      }}
                                                      minDate={
                                                        new Date(
                                                          `12-10-${String(
                                                            item?.year - 1,
                                                          )}`,
                                                        )
                                                      }
                                                      maxDate={
                                                        new Date(
                                                          `12-31-${String(
                                                            item?.year,
                                                          )}`,
                                                        )
                                                      }
                                                    />
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {/* {row.workableHours} */}
                                                    <CustomInput
                                                      extraStyle={{
                                                        width: 100,
                                                      }}
                                                      placeHolder=""
                                                      value={
                                                        extraRow.workableHours
                                                      }
                                                      // className={classes.custominput}
                                                      // name={name}
                                                      // ref={ref}
                                                      type={'number'}
                                                      onChange={(e: any) => {
                                                        extraRow.workableHours =
                                                          parseInt(
                                                            e.target.value,
                                                          );
                                                        existList.PayPeriodQuarter[
                                                          filterDataIndex
                                                        ].payPeriod[
                                                          extraIndex
                                                        ] = extraRow;
                                                        setExistList({
                                                          ...existList,
                                                        });
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                    }}>
                                                    {/* {row.holidays} */}
                                                    <CustomInput
                                                      extraStyle={{
                                                        width: 100,
                                                      }}
                                                      placeHolder=""
                                                      value={extraRow.holidays}
                                                      // className={classes.custominput}
                                                      // name={name}
                                                      // ref={ref}
                                                      type={'number'}
                                                      onChange={(e: any) => {
                                                        extraRow.holidays =
                                                          parseInt(
                                                            e.target.value,
                                                          );
                                                        existList.PayPeriodQuarter[
                                                          filterDataIndex
                                                        ].payPeriod[
                                                          extraIndex
                                                        ] = extraRow;
                                                        setExistList({
                                                          ...existList,
                                                        });
                                                      }}
                                                    />
                                                    {existList
                                                      ?.PayPeriodQuarter[
                                                      filterDataIndex
                                                    ].payPeriod.length -
                                                      1 ===
                                                      extraIndex && (
                                                      <Typography
                                                        component={'a'}
                                                        onClick={() => {
                                                          const newArr =
                                                            existList.PayPeriodQuarter[
                                                              filterDataIndex
                                                            ].payPeriod.slice(
                                                              0,
                                                              -1,
                                                            );
                                                          existList.PayPeriodQuarter[
                                                            filterDataIndex
                                                          ].payPeriod = newArr;
                                                          setExistList({
                                                            ...existList,
                                                          });
                                                        }}
                                                        sx={{
                                                          color: '#DB2426',
                                                          cursor: 'pointer',
                                                          fontFamily:
                                                            'Red Hat Display Bold',
                                                          fontSize: '12px',
                                                          paddingLeft: '10px',
                                                          textDecoration:
                                                            'underline',
                                                        }}>
                                                        Remove
                                                      </Typography>
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            },
                                          )}
                                        <TableRow>
                                          <TableCell
                                            colSpan={4}
                                            sx={{
                                              border:
                                                '1px solid #fff !important',
                                            }}>
                                            {currentYear <= item?.year && (
                                              <Typography
                                                component={'a'}
                                                onClick={() => {
                                                  if (existList?.year) {
                                                    if (
                                                      existList?.year ===
                                                      item?.year
                                                    ) {
                                                      const fData =
                                                        existList?.PayPeriodQuarter.findIndex(
                                                          (rw) =>
                                                            rw.quarter ===
                                                            item1?.quarter,
                                                        );
                                                      if (fData !== -1) {
                                                        existList.PayPeriodQuarter[
                                                          fData
                                                        ].payPeriod.push({
                                                          endDate: '',
                                                          holidays: 0,
                                                          payDate: null,
                                                          processAdp: null,
                                                          startDate: '',
                                                          workableHours: 0,
                                                        });
                                                      } else {
                                                        existList.PayPeriodQuarter.push(
                                                          {
                                                            payPeriod: [
                                                              {
                                                                endDate: '',
                                                                holidays: 0,
                                                                payDate: null,
                                                                processAdp:
                                                                  null,
                                                                startDate: '',
                                                                workableHours: 0,
                                                              },
                                                            ],
                                                            quarter:
                                                              item1?.quarter,
                                                          },
                                                        );
                                                      }
                                                      setExistList({
                                                        PayPeriodQuarter:
                                                          existList.PayPeriodQuarter,
                                                        year: item?.year,
                                                      });
                                                    } else {
                                                      setExistList({
                                                        PayPeriodQuarter: [
                                                          {
                                                            payPeriod: [
                                                              {
                                                                endDate: '',
                                                                holidays: 0,
                                                                payDate: null,
                                                                processAdp:
                                                                  null,
                                                                startDate: '',
                                                                workableHours: 0,
                                                              },
                                                            ],
                                                            quarter:
                                                              item1?.quarter,
                                                          },
                                                        ],
                                                        year: item?.year,
                                                      });
                                                    }
                                                  } else {
                                                    setExistList({
                                                      PayPeriodQuarter: [
                                                        {
                                                          payPeriod: [
                                                            {
                                                              endDate: '',
                                                              holidays: 0,
                                                              payDate: null,
                                                              processAdp: null,
                                                              startDate: '',
                                                              workableHours: 0,
                                                            },
                                                          ],
                                                          quarter:
                                                            item1?.quarter,
                                                        },
                                                      ],
                                                      year: item?.year,
                                                    });
                                                  }
                                                }}
                                                sx={{
                                                  color: '#005EBF',
                                                  cursor: 'pointer',
                                                  fontFamily:
                                                    'Red Hat Display Bold',
                                                  fontSize: '12px',
                                                  padding: '10px',
                                                  textDecoration: 'underline',
                                                }}>
                                                +Add Row
                                              </Typography>
                                            )}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              border:
                                                '1px solid #fff !important',
                                            }}>
                                            <label
                                              className={'tableFooterlabel'}>
                                              Total Workable Hrs / Hol:{' '}
                                            </label>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              border:
                                                '1px solid #fff !important',
                                            }}>
                                            <label
                                              className={'tableFooterlabel1'}>
                                              {hours ? hours : ''}
                                            </label>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              border:
                                                '1px solid #fff !important',
                                            }}>
                                            <label
                                              className={'tableFooterlabel1'}>
                                              {holidays ? holidays : ''}
                                            </label>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        },
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Typography>
          </Typography>
        </Grid>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="confirmation-widget">
            <Typography
              id="modal-modal-title"
              component="h2"
              sx={{
                lineHeight: '36px',
                paddingX: '50px',
              }}>
              Do you want to add {year} Payroll Calendar?
            </Typography>
            <Typography className="confirm-btn model-btn" component={'div'}>
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ textTransform: 'capitalize' }}>
                No, Cancel
              </Button>
              <Button variant="contained" onClick={yesNextYearData}>
                YES, Add {year} Payroll Calendar
              </Button>
            </Typography>
          </Box>
        </Modal>
      </Typography>
    </LoadingOverlay>
  );
};
