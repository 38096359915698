import { Box, FormHelperText, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { ReactElement, useEffect, useState } from 'react';

type DatePickerProps = {
  placeHolder?: string;
  onChange?: (src: any) => void;
  value?: any;
  error?: any;
  name?: string;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
};

const DatePickerCom = (props: DatePickerProps): ReactElement => {
  const {
    placeHolder,
    onChange,
    value,
    error,
    name,
    minDate,
    maxDate,
    disabled = false,
  } = props;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const detectMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const mobileKeywords = [
        'android',
        'webos',
        'iphone',
        'ipad',
        'ipod',
        'blackberry',
        'windows phone',
      ];

      const isMobileDevice = mobileKeywords.some(
        (keyword) => userAgent.indexOf(keyword) > -1,
      );
      setIsMobile(isMobileDevice);
    };

    detectMobile();

    // Optional: You can listen for browser updates in real-time, if needed.
    window.addEventListener('resize', detectMobile);

    return () => {
      window.removeEventListener('resize', detectMobile);
    };
  }, []);

  return (
    <Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Custom input"
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          disabled={disabled}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box
              sx={{
                '& .MuiInputAdornment-positionEnd': {
                  alignItems: 'center',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  marginLeft: '20px',
                  width: '20%',
                },
                '& button': {
                  marginLeft: '-20px',
                },
                '& input': {
                  backgroundColor: isMobile
                    ? disabled
                      ? '#f8f8fa  !important'
                      : '#ffffff !important'
                    : '#f8f8fa !important',
                  paddingTop: '5px !important',
                  width: '100% !important',
                },
                '& svg': {
                  color: disabled ? 'gray' : '#005EBF !important',
                },
                alignItems: 'center',
                background: '#f8f8fa',
                borderRadius: '10px',
                display: 'flex',
              }}>
              {InputProps?.endAdornment}
              <input
                ref={inputRef}
                {...inputProps}
                name={name}
                placeholder={placeHolder}
                disabled={disabled}
              />
            </Box>
          )}
        />
      </LocalizationProvider>
      {error && <FormHelperText className="errorText">{error}</FormHelperText>}
    </Typography>
  );
};

export default DatePickerCom;
