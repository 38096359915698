// import { yupResolver } from '@hookform/resolvers/yup';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
// import Modal from '@mui/material/Modal';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import TextField from '@mui/material/TextField';
// import accordionarrow from 'assets/images/arrow-accordin.svg';
// import CheckGreen from 'assets/images/check-alert-green.svg';
// import CheckRed from 'assets/images/check-alert-red.svg';
// import CloseCircleIcon from 'assets/images/close-circle.svg';
// import PaidIcon from 'assets/images/dollar-sign-green.svg';
// import CustomInput from 'components/CustomInput/customInput';
// import { PublicRoutes } from 'config';
// import UploadAlert from 'assets/images/upload-alert.svg';
// import arrowcolumns from 'assets/images/arrowcolumns.svg';
// import Loginbg from 'assets/images/login-bg.png';
// import Loginlogo from 'assets/images/login-logo.png';
// import CustomButton from 'components/CustomButton/customButton';
// import CustomInput from 'components/CustomInput/customInput';
// import CustomSelect from 'components/CustomSelect/customSelect';
// import cloneDeep from 'lodash/cloneDeep';
// import moment from 'moment';
// import CustomButton from 'components/CustomButton/customButton';
import React from 'react';
// import FileViewer from 'react-file-viewer';
// import { Controller, useForm } from 'react-hook-form';
// import {
//   addNewData,
//   getAllListData,
//   updateData,
// } from 'services/CommonServices';
// import { Controller, useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

// import { addNotesForm } from 'types';
// import { MASTER_ENDPOINT } from 'utils/Constant';
// import { json } from 'stream/consumers';
// import { TIMESHEET } from 'utils/Constant';
// import { addNotesSchema } from 'utils/ValidatorSchema';
import TimesheetComponent from '../../common/Timesheet';
// import useStyles from '../publicStyles';

const TimeSheet: React.FC = () => {
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);
  return (
    <Grid container>
      {userInfo?.id && (
        <TimesheetComponent
          consultantId={userInfo?.id}
          showPaid={false}
          showApproved={false}
          isAdminScreen={false}
          showHours={true}
          jobWcCode={userInfo?.jobWcCode}
        />
      )}
      {/* <Accordion>
            <AccordionSummary
              sx={{ m: 0 }}
              expandIcon={<img src={accordionarrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography component={'div'} className="accordin-details">
                <Typography component={'p'}>
                  April <strong>15-30</strong> - May <strong>14 </strong>
                </Typography>
                <Typography component={'p'}>Approved Hours: : 50</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<img src={accordionarrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography component={'div'} className="accordin-details">
                <Typography component={'p'}>
                  February <strong>1 - 15</strong>
                </Typography>
                <Typography component={'p'}>Approved Hours: : 30</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={'div'} className="timesheet-table">
                <Box
                  sx={{
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    m: 0,
                    p: 0,
                  }}>
                  <Typography>
                    Feb <strong>1-7</strong>
                  </Typography>
                  <Typography style={{ paddingRight: '100px' }}>
                    Total: <strong>40</strong>
                  </Typography>
                </Box>
                <TableContainer component={'div'}>
                  <Table sx={{ Width: '100%' }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Project Worked On:</TableCell>
                        <TableCell align="left">Paid:</TableCell>
                        <TableCell align="left">Hours:</TableCell>
                        <TableCell align="left">Adj. Hours:</TableCell>
                        <TableCell>Timesheet:</TableCell>
                        <TableCell align="left">Current Rate:</TableCell>
                        <TableCell align="left">Adj. Amount:</TableCell>
                        <TableCell align="left">Discount:</TableCell>
                        <TableCell align="left">Adj. Rate</TableCell>
                        <TableCell align="left">Admin Fee:</TableCell>
                        <TableCell align="left">Notes:</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="active-blue">
                        <TableCell component="th" scope="row">
                          iTech
                        </TableCell>
                        <TableCell align="left">
                          <img src={CheckGreen} />
                        </TableCell>
                        <TableCell align="left">20</TableCell>
                        <TableCell align="left">8</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                        <TableCell align="left">85</TableCell>
                        <TableCell align="left">3,258</TableCell>
                        <TableCell align="left">0.85</TableCell>
                        <TableCell align="left">84.15</TableCell>
                        <TableCell align="left">9.00</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                      </TableRow>
                      <TableRow className="active-blue">
                        <TableCell component="th" scope="row">
                          Miller Project
                        </TableCell>
                        <TableCell align="left">
                          <img src={CheckGreen} />
                        </TableCell>
                        <TableCell align="left">20</TableCell>
                        <TableCell align="left">8</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                        <TableCell align="left">85</TableCell>
                        <TableCell align="left">3,258</TableCell>
                        <TableCell align="left">0.85</TableCell>
                        <TableCell align="left">84.15</TableCell>
                        <TableCell align="left">9.00</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                      </TableRow>
                      <TableRow className="active-yellow">
                        <TableCell component="th" scope="row">
                          Project 3
                        </TableCell>
                        <TableCell align="left">
                          <img src={CheckRed} />
                        </TableCell>
                        <TableCell align="left">20</TableCell>
                        <TableCell align="left">8</TableCell>
                        <TableCell align="left">
                          <a href="#" style={{ color: '#DB2426' }}>
                            Upload
                          </a>
                        </TableCell>
                        <TableCell align="left">85</TableCell>
                        <TableCell align="left">3,258</TableCell>
                        <TableCell align="left">0.85</TableCell>
                        <TableCell align="left">84.15</TableCell>
                        <TableCell align="left">9.00</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Typography component={'div'} className="timesheet-table">
                <Box
                  sx={{
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    m: 0,
                    p: 0,
                  }}>
                  <Typography>
                    Feb <strong>1-7</strong>
                  </Typography>
                  <Typography style={{ paddingRight: '100px' }}>
                    Total: <strong>40</strong>
                  </Typography>
                </Box>
                <TableContainer component={'div'}>
                  <Table sx={{ Width: '100%' }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Project Worked On:</TableCell>
                        <TableCell align="left">Paid:</TableCell>
                        <TableCell align="left">Hours:</TableCell>
                        <TableCell align="left">Adj. Hours:</TableCell>
                        <TableCell>Timesheet:</TableCell>
                        <TableCell align="left">Current Rate:</TableCell>
                        <TableCell align="left">Adj. Amount:</TableCell>
                        <TableCell align="left">Discount:</TableCell>
                        <TableCell align="left">Adj. Rate</TableCell>
                        <TableCell align="left">Admin Fee:</TableCell>
                        <TableCell align="left">Notes:</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="active-blue">
                        <TableCell component="th" scope="row">
                          iTechs
                        </TableCell>
                        <TableCell align="left">
                          <img src={CheckGreen} />
                        </TableCell>
                        <TableCell align="left">20</TableCell>
                        <TableCell align="left">8</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                        <TableCell align="left">85</TableCell>
                        <TableCell align="left">3,258</TableCell>
                        <TableCell align="left">0.85</TableCell>
                        <TableCell align="left">84.15</TableCell>
                        <TableCell align="left">9.00</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                      </TableRow>
                      <TableRow className="active-blue">
                        <TableCell component="th" scope="row">
                          Miller Project
                        </TableCell>
                        <TableCell align="left">
                          <img src={CheckGreen} />
                        </TableCell>
                        <TableCell align="left">20</TableCell>
                        <TableCell align="left">8</TableCell>
                        <TableCell align="left">
                          <a href="#">Upload</a>
                        </TableCell>
                        <TableCell align="left">85</TableCell>
                        <TableCell align="left">3,258</TableCell>
                        <TableCell align="left">0.85</TableCell>
                        <TableCell align="left">84.15</TableCell>
                        <TableCell align="left">9.00</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                      </TableRow>
                      <TableRow className="active-yellow">
                        <TableCell component="th" scope="row">
                          Project 3
                        </TableCell>
                        <TableCell align="left">
                          <img src={CheckRed} />
                        </TableCell>
                        <TableCell align="left">20</TableCell>
                        <TableCell align="left">8</TableCell>
                        <TableCell align="left">
                          <a href="#" style={{ color: '#DB2426' }}>
                            Upload
                          </a>
                        </TableCell>
                        <TableCell align="left">85</TableCell>
                        <TableCell align="left">3,258</TableCell>
                        <TableCell align="left">0.85</TableCell>
                        <TableCell align="left">84.15</TableCell>
                        <TableCell align="left">9.00</TableCell>
                        <TableCell align="left">
                          <a href="#">View</a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography component={'div'} className="notices-details">
                  <Typography>
                    Consultant Notes: Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem Ipsum has been the
                    industry&apos;s standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book.
                  </Typography>
                </Typography>
              </Typography>
            </AccordionDetails>
          </Accordion> */}
    </Grid>
  );
};

export default TimeSheet;
