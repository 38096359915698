import SearchIcon from '@mui/icons-material/Search';
import {
  FormHelperText,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: string;
  error?: any;
  options?: any;
  labelKey?: string;
  label1Key?: string;
  valueKey?: string;
  id?: string;
  name?: string;
  ref?: any;
  disabled?: boolean;
  selectedValue?: string;
  searchable?: boolean;
};

const CustomSelect = (props: InputProps): ReactElement => {
  const {
    placeHolder,
    className,
    onChange,
    extraStyle,
    value,
    error,
    options,
    labelKey,
    label1Key,
    valueKey,
    id,
    name,
    ref,
    disabled,
    selectedValue,
    searchable,
  } = props;
  const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(false);
  const [optionList, setOptionList] = useState<any>([]);
  const [optionListAll, setOptionListAll] = useState<any>([]);

  useEffect(() => {
    options.slice().sort((a, b) =>
      String(a[labelKey]).localeCompare(b[labelKey], 'es', {
        sensitivity: 'base',
      }),
    );
    setOptionList(options);
    setOptionListAll(options);
  }, [options, labelKey]);

  const onChangeText = (text) => {
    const searchOptions = optionListAll.filter(
      (option) =>
        option[labelKey].toLowerCase().indexOf(text.toLowerCase()) !== -1,
    );
    setOptionList(searchOptions);
  };

  return (
    <>
      <Select
        className={className}
        onOpen={() => setShowPlaceHolder(true)}
        onClose={() => setShowPlaceHolder(false)}
        onChange={onChange && onChange}
        style={extraStyle}
        value={value}
        displayEmpty
        disabled={disabled}
        id={id}
        name={name}
        ref={ref}
        error={!!error}>
        {searchable && (
          <ListSubheader style={{ backgroundColor: '#fff' }}>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => onChangeText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {!showPlaceHolder && (
          <MenuItem value="">
            <span style={{ color: '#8f8a8a' }}>{placeHolder}</span>
          </MenuItem>
        )}

        {optionList.length ? (
          optionList.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                value={
                  valueKey === 'date'
                    ? moment(item[valueKey]).format('DD-MM-YYYY')
                    : item[valueKey]
                }
                selected={
                  selectedValue !== '' &&
                  String(item[valueKey]) === String(selectedValue)
                }>
                {labelKey === 'date'
                  ? moment(item[labelKey]).format('DD-MM-YYYY')
                  : item[labelKey]}
                {label1Key ? `/ ${String(item[label1Key])}` : ''}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={''} disabled>
            No Items
          </MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText style={{ color: '#ff0000' }}>{error}</FormHelperText>
      )}
    </>
  );
};

export default CustomSelect;
