import 'react-toastify/dist/ReactToastify.css';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import App from 'App';
import theme from 'materialtheme';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from 'store';

window.localStorage.setItem('currentTheme', 'light');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <CssBaseline />
        <ToastContainer />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    </ThemeProvider>
  </Provider>,
);
