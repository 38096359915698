import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ChangePassword from 'pages/common/ChangePassword';
import LogOut from 'pages/common/Logout';
import Profile from 'pages/common/Profile';
import React from 'react';

import useStyles from '../PrivateStyle';

export const Myprofile: React.FC<any> = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent={'center'} sx={{ width: '100%' }}>
      <Typography component={'div'} style={{ marginTop: 50 }}>
        <Grid container>
          <Grid item sm={6}>
            <Typography component={'div'} className={classes.profilecard}>
              <Typography component="h6">Personal Info</Typography>
              <Profile from={'consultant'} />
            </Typography>
            <Typography
              component={'div'}
              className={classes.profilecard}
              sx={{ paddingTop: '15px' }}>
              {/* <Typography component="h6">Settings</Typography> */}
              <Card sx={{ boxShadow: 0 }}>
                <CardContent>
                  <Typography
                    component={'div'}
                    sx={{
                      color: '#292929',
                      fontFamily: 'Red Hat Display Bold',
                      fontSize: '16px',
                      paddingBottom: '10px',
                      paddingLeft: '10px',
                      paddingTop: '10px',
                    }}>
                    Notification Preferences
                  </Typography>
                  <Typography component={'div'}>
                    <FormControlLabel
                      value="Texts"
                      control={<Radio checked={false} />}
                      label="Texts"
                      sx={{
                        '& .MuiTypography-body1': {
                          color: '#292929',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: '14px',
                          lineHeight: '35px',
                          listStyle: 'none',
                          padding: '0',
                        },
                        '& .PrivateSwitchBase-root': {
                          paddingBottom: '0',
                          paddingTop: '0',
                        },
                        paddingLeft: '15px',
                      }}
                    />
                  </Typography>
                  <Typography component={'div'}>
                    <FormControlLabel
                      value="Emails"
                      control={<Radio checked />}
                      label="Emails"
                      sx={{
                        '& .MuiTypography-body1': {
                          color: '#292929',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: '14px',
                          lineHeight: '35px',
                          listStyle: 'none',
                          padding: '0',
                        },
                        '& .PrivateSwitchBase-root': {
                          paddingBottom: '0',
                          paddingTop: '0',
                        },
                        paddingLeft: '15px',
                      }}
                    />
                  </Typography>
                  <Typography component={'div'}>
                    <FormControlLabel
                      value="Text & Emails"
                      control={<Radio checked={false} />}
                      label="Text & Emails"
                      sx={{
                        '& .MuiTypography-body1': {
                          color: '#292929',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: '14px',
                          lineHeight: '35px',
                          listStyle: 'none',
                          padding: '0',
                        },
                        '& .PrivateSwitchBase-root': {
                          paddingBottom: '0',
                          paddingTop: '0',
                        },
                        paddingLeft: '15px',
                      }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Typography>
            <Typography component={'div'} className={classes.profilecard}>
              <Typography component="h6">More Options</Typography>
              <Card sx={{ boxShadow: 0 }}>
                <CardContent>
                  <Typography component={'div'}>
                    <ul className={classes.optiondetails}>
                      <li>
                        <ChangePassword />
                      </li>
                      <li>Settings</li>
                      <li>
                        <a
                          style={{ color: 'inherit', textDecoration: 'unset' }}
                          href="https://riseits.com/company-privacy-policy/"
                          target="_blank"
                          rel="noreferrer">
                          Privacy Policy
                        </a>
                      </li>
                      <li>Terms & Conditions</li>
                      <li className={classes.logout}>
                        <LogOut />
                      </li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </Grid>
  );
};

export default Myprofile;
