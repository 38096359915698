import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
} from '@mui/material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DotsIcon from 'assets/images/dots.png';
import CheckboxCom from 'components/CheckBox';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import DatePicker from 'components/DatePicker';
import { AdminPrivateRoutes } from 'config';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import * as React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { EditLeaveForm } from 'types';
import useWindowDimensions, { RestrictionProd } from 'utils/Config';
// import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { EditLeaveSchema } from 'utils/ValidatorSchema';

import useStyles from './style';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#F0F2F4',
    border: '1px solid #d4d4d6 !important',
    borderLeftWidth: '0px !important',
    borderTopWidth: '0px !important',
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    lineHeight: 'inherit',
    padding: '15px',
    verticalAlign: 'center',
  },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#FFEBEB',
    border: '1px solid #d4d4d6 !important',
    borderLeftWidth: '0px !important',
    borderTopWidth: '0px !important',
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    lineHeight: 'inherit',
    padding: '15px',
    verticalAlign: 'center',
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #d4d4d6 !important',
    borderLeftWidth: '0px !important',
    borderTopWidth: '0px !important',
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    lineHeight: 'inherit',
    padding: '15px',
    verticalAlign: 'center',
  },
}));

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  maxHeight: 700,
  overflowY: 'scroll',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
};

const leaveStyle = {
  closeText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    textDecoration: 'underLine',
  },
  mediumText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '15px',
  },
  normalText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '12px',
  },
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-body': {
    border: '1px solid #d4d4d6',
  },
  // hide last border
  '& .MuiTableCell-head': {
    background: '#f8f8fa',
    borderWidth: 1,
    lineHeight: 'inherit',
    padding: '10px',
    paddingBottom: '5px',
    paddingTop: '25px',
  },
}));

const LeaveTracker: React.FC<any> = () => {
  const classes = useStyles();
  const [approvedList, setApprovedList] = React.useState<any>([]);
  const [returnedList, setReturnedList] = React.useState<any>([]);
  const [pendingList, setPendingList] = React.useState<any>([]);
  const [leaveTypes, setLeaveTypes] = React.useState<any>([]);
  const getLeaveTypes = () => {
    getAllListData(MASTER_ENDPOINT.LeaveTypes)
      .then((resp: any) => {
        if (resp?.data) {
          const leaveList = [];
          resp?.data.map((row: any) => {
            leaveList.push({ label: row.type, value: row.id.toString() });
          });
          setLeaveTypes(leaveList);
        }
      })
      .catch((err: any) => {});
  };
  const [removableLeaveTypes, setRemovableLeaveTypes] = React.useState<any>([]);
  const [removablePayDates, setRemovablePayDates] = React.useState<any>([]);
  const [removableWorkableDates, setRemovableWorkableDates] =
    React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [editOrRemoveRow, setEditOrRemoveRow] = React.useState<any>({});
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [open1, setOpen1] = React.useState(false);
  const handleClick =
    (newPlacement: PopperPlacementType, row: any, editStatus: boolean) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen1((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      const wDays = [];
      if (row['workingDays'] && row['workingDays']?.length) {
        row['workingDays'].map((item: any) => {
          wDays.push({
            fromDate: item.fromDate,
            id: item.id,
            toDate: item.toDate,
          });
        });
      }
      const pDays = [];
      if (row['payableDays'] && row['payableDays']?.length) {
        row['payableDays'].map((item: any) => {
          pDays.push({
            fromDate: item.fromDate,
            id: item.id,
            toDate: item.toDate,
          });
        });
      }
      const lTypes = [];
      if (row['leaveTypes'] && row['leaveTypes']?.length) {
        row['leaveTypes'].map((item: any) => {
          if (Number(item.leaveTypeId) === 5) {
            setValue('reasonComments', item.reason);
          }
          lTypes.push({
            id: item.id,
            leaveTypeId: item.leaveTypeId.toString(),
            reason: item.reason,
          });
        });
      }
      workingDaysArrayReplace(wDays);
      payableDaysArrayAppend(pDays);
      setValue('leaveTypes', lTypes);
      setValue('fromDate', row['fromDate']);
      setValue('toDate', row['toDate']);
      setValue('returnDate', row['returnDate']);
      setValue(
        'workingWhileOnLeave',
        row['workingWhileOnLeave'] ? 'yes' : 'no',
      );
      setValue('wishToBePaidOnLeave', row['wishToBePaidOnLeave']);
      setValue('notes', row['notes']);
      setValue('onLeaveCountry', row['onLeaveCountry']);
      // setValue('payableDays', pDays);
      setValue('adpUpdated', row['adpUpdated']);
      setValue('obtainI94', row['obtainI94']);
      // setValue(
      //   'remotePaid',
      //   row['remotePaid'] === null ? null : row['remotePaid'] ? 'yes' : 'no',
      // );
      row['edit'] = editStatus;
      setEditOrRemoveRow({ ...row });
    };

  const { searchText, companyStatus }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  React.useEffect(() => {
    getLeaveTypes();
  }, []);

  const [loader, setLoader] = React.useState(false);
  React.useEffect(() => {
    getLeaveHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, companyStatus]);

  const getLeaveHistory = async () => {
    setLoader(true);
    let leaveUrl = MASTER_ENDPOINT.Leaves + '?search=' + searchText;
    if (companyStatus !== 'all') {
      leaveUrl += `&organization=${String(companyStatus)}`;
    }
    await getAllListData(leaveUrl)
      .then((resp: any) => {
        setReturnedList([
          ...resp.data.filter((item: any) => item.status === 'returned'),
        ]);
        setApprovedList([
          ...resp.data.filter((item: any) => item.status === 'approved'),
        ]);
        setPendingList([
          ...resp.data.filter((item: any) => item.status === 'pending'),
        ]);
      })
      .catch((err: any) => {});
    setLoader(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditOrRemoveRow({});
    setRemovableWorkableDates([]);
    setRemovablePayDates([]);
    setRemovableLeaveTypes([]);
    reset();
  };

  const setEditRoW = (row: any) => {
    handleOpen();
  };

  const [loading, setLoading] = React.useState(false);
  const submitForm = (data: any) => {
    setLoading(true);
    data['workingWhileOnLeave'] =
      data['workingWhileOnLeave'] === 'yes' ? true : false;
    data['fromDate'] = moment(data['fromDate']).format('YYYY-MM-DD');
    data['toDate'] = moment(data['toDate']).format('YYYY-MM-DD');
    data['returnDate'] = moment(data['returnDate']).format('YYYY-MM-DD');
    const workingDays = [];
    if (data['workingWhileOnLeave']) {
      data['workingDays'].map((item: any) => {
        workingDays.push({
          fromDate: moment(item['fromDate']).format('YYYY-MM-DD'),
          id: item['id'] ? item['id'] : '',
          toDate: moment(item['toDate']).format('YYYY-MM-DD'),
        });
      });
    }
    data['workingDays'] = workingDays;
    const payableDays = [];
    if (
      data['wishToBePaidOnLeave'] === 'yes' ||
      data['wishToBePaidOnLeave'] === 'partialyPaid'
    ) {
      data['payableDays'].map((item: any) => {
        payableDays.push({
          fromDate: moment(item['fromDate']).format('YYYY-MM-DD'),
          id: item['id'] ? item['id'] : '',
          toDate: moment(item['toDate']).format('YYYY-MM-DD'),
        });
      });
    }
    if (data['leaveTypes'].length) {
      data['leaveTypes'] = data['leaveTypes'].map((item: any) => {
        item['leaveTypeId'] = parseInt(item['leaveTypeId']);
        if (item['leaveTypeId'] === 5) {
          item['reason'] = data['reasonComments'];
        }
        return item;
      });
    }
    data['removedLeaveTypes'] = removableLeaveTypes;
    data['payableDays'] = payableDays;
    data['consultantUserId'] = editOrRemoveRow['userId'];
    data['id'] = editOrRemoveRow['id'];
    // data['remotePaid'] =
    //   data['remotePaid'] === 'yes'
    //     ? true
    //     : data['remotePaid'] === 'no'
    //     ? false
    //     : null;
    data['removedPayableDays'] = removablePayDates;
    data['removedWorkingDays'] = removableWorkableDates;
    updateData(editOrRemoveRow.id, data, MASTER_ENDPOINT.Leaves, true)
      .then((resp: any) => {
        handleClose();
        setLoading(false);
        getLeaveHistory();
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const { handleSubmit, control, formState, reset, watch, setValue } =
    useForm<EditLeaveForm>({
      defaultValues: {
        adpUpdated: '',
        fromDate: null,
        leaveTypes: [],
        notes: '',
        obtainI94: '',
        onLeaveCountry: '',
        payableDays: [],
        reasonComments: '',
        // remotePaid: '',
        returnDate: null,
        toDate: null,
        wishToBePaidOnLeave: '',
        workingDays: [],
        workingWhileOnLeave: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(EditLeaveSchema),
    });

  const {
    fields: workingDaysArray,
    append: workingDaysArrayAppend,
    replace: workingDaysArrayReplace,
    remove: workingDaysArrayRomove,
  } = useFieldArray({ control, name: 'workingDays' });

  const {
    fields: payableDaysArray,
    append: payableDaysArrayAppend,
    remove: payableDaysArrayRomove,
  } = useFieldArray({ control, name: 'payableDays' });

  const onError = (errors, e) => console.log(errors, e);
  const { height } = useWindowDimensions();

  const handlePdfDownload = (row: any) => {
    const leave = row;
    let weeklyDays = '';
    if (leave?.workingDays && leave?.workingDays.length) {
      leave?.workingDays.map((item: any) => {
        weeklyDays +=
          `(${moment(item?.fromDate).format('MM-DD-YYYY')} - ${moment(
            item?.toDate,
          ).format('MM-DD-YYYY')}) ` + ', ';
      });
    }
    if (weeklyDays) {
      weeklyDays = weeklyDays.replace(/,\s*$/, '');
    }
    let payableDays = '';
    if (leave?.payableDays && leave?.payableDays.length) {
      leave?.payableDays.map((item: any) => {
        payableDays +=
          `(${moment(item?.fromDate).format('MM-DD-YYYY')} - ${moment(
            item?.toDate,
          ).format('MM-DD-YYYY')}) ` + ', ';
      });
    }
    if (payableDays) {
      payableDays = payableDays.replace(/,\s*$/, '');
    }
    let leaveTypesText = '';
    let leaveTypesReason = '';
    if (leave?.leaveTypes && leave?.leaveTypes.length) {
      leave?.leaveTypes.map((item: any) => {
        if (Number(item.leaveTypeId) === 5) {
          leaveTypesReason = item.reason;
        }
        leaveTypesText += item?.leaveType?.type + ', ';
      });
    }
    if (leaveTypesText) {
      leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
    }
    const doc = new jsPDF();
    const headFont = ['helvetica', 'bold'];
    doc.setFontSize(15);
    doc.setFont(headFont[0], headFont[1]);
    doc.setTextColor(0, 0, 0);
    doc.text('LEAVE REQUEST FORM', 16, 15);
    autoTable(doc, {
      body: [
        [
          'Consultant Name',
          String(leave.user.firstName) + ' ' + String(leave.user.lastName),
        ],
        [
          'Company Name',
          leave.user?.ultraStaffDbName
            ? ' ' + String(leave.user?.ultraStaffDbName)
            : '-',
        ],
        ['First Day of Leave', moment(leave.fromDate).format('MM-DD-YYYY')],
        ['Last Day of Leave', moment(leave.toDate).format('MM-DD-YYYY')],
        [
          'Exact Return to Work Date',
          moment(leave.returnDate).format('MM-DD-YYYY'),
        ],
        [
          'Will you be traveling outside the U.S.?',
          leave.travelingOutsideUS === true
            ? 'Yes'
            : leave.travelingOutsideUS === false
            ? 'No'
            : '-',
        ],
        [
          'Enter date of return/re-entry to the U.S.',
          leave.returnToUsOn
            ? moment(leave.returnToUsOn).format('MM-DD-YYYY')
            : '-',
        ],
        [
          'Will you be Working While on Leave?',
          leave.workingWhileOnLeave === true
            ? 'Yes'
            : leave.workingWhileOnLeave === false
            ? 'No'
            : '-',
        ],
        [
          'If yes, please indicate the dates you will be working',
          weeklyDays ? weeklyDays : '-',
        ],
        [
          'Do You Wish to be Paid While on Leave?',
          leave.wishToBePaidOnLeave === 'yes'
            ? 'Yes'
            : leave.wishToBePaidOnLeave === 'no'
            ? 'No'
            : leave.wishToBePaidOnLeave === 'partialyPaid'
            ? 'Partially Paid'
            : '-',
        ],
        [
          'Paid While on Leave From and To Date',
          payableDays ? payableDays : '-',
        ],
        ['Reason for Leave?', leaveTypesText ? leaveTypesText : '-'],
        ['Reason comment', leaveTypesReason ? leaveTypesReason : '-'],
        ['Country of Leave', leave.onLeaveCountry ? leave.onLeaveCountry : '-'],
        [
          'Preferred Method of Communication While on Leave',
          leave.modeOfCommunication ? leave.modeOfCommunication : '-',
        ],
        [
          'Enter Phone Number',
          leave.communicateVia ? leave.communicateVia : '-',
        ],
        ['Add Comment', leave.comments ? leave.comments : '-'],
        ['Work Auth', leave?.user?.workAuth ? leave?.user?.workAuth : '-'],
        ['ADP Updated', leave.adpUpdated ? leave.adpUpdated : '-'],
        [
          'Remote Work',
          leave.workingWhileOnLeave === null
            ? '-'
            : leave.workingWhileOnLeave
            ? 'Y'
            : 'N',
        ],
        [
          'Obtain I-94',
          leave.obtainI94
            ? leave.obtainI94.charAt(0).toUpperCase() + leave.obtainI94.slice(1)
            : '-',
        ],
        ['Admin Comments', leave.notes ? leave.notes : '-'],
      ],
      columnStyles: {
        0: {
          cellWidth: 95,
          fillColor: [255, 255, 255],
          font: 'helvetica',
          fontSize: 11,
          fontStyle: 'bold',
        },
        1: {
          cellWidth: 95,
          fillColor: [255, 255, 255],
          font: 'helvetica',
          fontSize: 11,
          fontStyle: 'normal',
        },
      },
      margin: { top: 20 },
      styles: { fillColor: [255, 255, 255] },
    });
    if (leave.eSignature) {
      const finalY = (doc as any).lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.setFont(headFont[0], headFont[1]);
      doc.setTextColor(0, 0, 0);
      doc.text('E signature', 170, finalY + 15);
      const imag = new Image();
      const src1 = leave.eSignature;
      imag.src = src1;
      doc.addImage(imag, 'png', 165, finalY + 23, 35, 15);
    }
    doc.save(
      String(leave.user.firstName) +
        ' ' +
        String(leave.user.lastName) +
        '' +
        '-leavedetails.pdf',
    );
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container>
          <TableContainer sx={{ maxHeight: height - 95 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ minWidth: 150 }}>
                    Consultant Name
                  </StyledTableCell>
                  <StyledTableCell align="right">Company</StyledTableCell>
                  <StyledTableCell align="right">Work Auth</StyledTableCell>
                  <StyledTableCell align="center" style={{ width: 20 }}>
                    Leave App Recd
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Leave Start Date
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Leave End Date
                  </StyledTableCell>
                  <StyledTableCell align="center">ADP Updated</StyledTableCell>
                  <StyledTableCell align="center">
                    Paid? (Y/N/P)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Remote Work (Y/N)
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 150 }}>
                    Leave Type
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 80 }}>
                    Country
                  </StyledTableCell>
                  <StyledTableCell align="center">Obtain I-94</StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 200 }}>
                    Admin Comments
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 200 }}>
                    Consultant Comments
                  </StyledTableCell>
                  <StyledTableCell align="center" />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!RestrictionProd && (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          background: '#FFFFFF',
                        }}
                        className={'norecordfound'}
                        colSpan={16}
                        align="left">
                        <Typography
                          sx={{
                            alignItems: 'center',
                            color: '#292929',
                            display: 'flex',
                            fontFamily: 'Red Hat Display Bold',
                            fontSize: '15px',
                            letterSpacing: '1.88px',
                            textTransform: 'uppercase',
                          }}>
                          <Typography
                            sx={{
                              background: '#005EBF',
                              height: '10px',
                              marginRight: '10px',
                              width: '10px',
                            }}
                          />
                          Pending on Leave
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {pendingList.map((row: any, index: number) => {
                      let leaveTypesText = '';
                      if (row?.leaveTypes && row?.leaveTypes.length) {
                        row?.leaveTypes.map((item: any) => {
                          leaveTypesText += item?.leaveType?.type + ', ';
                        });
                      }
                      if (leaveTypesText) {
                        leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
                      }
                      return (
                        <StyledTableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                            style={{ textDecoration: 'underline' }}>
                            <NavLink
                              style={{ color: '#241A2E' }}
                              to={`${String(
                                AdminPrivateRoutes.CONSULTANTS,
                              )}/profile/${String(row?.userId)}`}>
                              {row?.user?.lastName}, {row?.user?.firstName}
                            </NavLink>
                          </StyledTableCell2>
                          <StyledTableCell2 component="th" scope="row">
                            {row?.user?.ultraStaffDbName}
                          </StyledTableCell2>
                          <StyledTableCell2 component="th" scope="row">
                            {row?.user?.workAuth}
                          </StyledTableCell2>
                          <StyledTableCell2 component="th" scope="row">
                            {moment(row.createdAt).format('MM/DD/YYYY')}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {moment(row.fromDate).format('MM/DD/YYYY')}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {moment(row.toDate).format('MM/DD/YYYY')}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.adpUpdated}
                          </StyledTableCell2>
                          <StyledTableCell2
                            align="left"
                            style={{ textTransform: 'capitalize' }}>
                            {row.wishToBePaidOnLeave === 'yes'
                              ? 'Y'
                              : row.wishToBePaidOnLeave === 'no'
                              ? 'N'
                              : row.wishToBePaidOnLeave === 'partialyPaid'
                              ? 'P'
                              : ''}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.workingWhileOnLeave === null
                              ? ''
                              : row.workingWhileOnLeave
                              ? 'Y'
                              : 'N'}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {leaveTypesText}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row?.onLeaveCountry}
                          </StyledTableCell2>
                          <StyledTableCell2
                            align="left"
                            style={{ textTransform: 'capitalize' }}>
                            {row.obtainI94}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.notes}
                          </StyledTableCell2>
                          <StyledTableCell2 align="left">
                            {row.comments}
                          </StyledTableCell2>
                          <StyledTableCell2 align="right">
                            <Button
                              onClick={handleClick('bottom-end', row, true)}
                              style={{ backgroundColor: 'transparent' }}>
                              <Avatar
                                variant="square"
                                alt="Profile"
                                src={DotsIcon}
                                style={{
                                  borderRadius: 10,
                                  height: 22,
                                  width: 20,
                                }}
                              />
                            </Button>
                          </StyledTableCell2>
                        </StyledTableRow>
                      );
                    })}
                  </>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      background: '#FFFFFF',
                    }}
                    className={'norecordfound'}
                    colSpan={16}
                    align="left">
                    <Typography
                      sx={{
                        alignItems: 'center',
                        color: '#292929',
                        display: 'flex',
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: '15px',
                        letterSpacing: '1.88px',
                        textTransform: 'uppercase',
                      }}>
                      <Typography
                        sx={{
                          background: '#005EBF',
                          height: '10px',
                          marginRight: '10px',
                          width: '10px',
                        }}
                      />
                      Currently on Leave
                    </Typography>
                  </TableCell>
                </TableRow>
                {approvedList.map((row: any, index: number) => {
                  if (
                    moment(moment().format('YYYY-MM-DD')).isBetween(
                      moment(row.fromDate).format('YYYY-MM-DD'),
                      moment(row.toDate).format('YYYY-MM-DD'),
                    ) ||
                    moment(moment().format('YYYY-MM-DD')).isSame(
                      moment(row.fromDate).format('YYYY-MM-DD'),
                    ) ||
                    moment(moment().format('YYYY-MM-DD')).isSame(
                      moment(row.toDate).format('YYYY-MM-DD'),
                    ) ||
                    moment(moment().format('YYYY-MM-DD')).isAfter(
                      moment(row.toDate).format('YYYY-MM-DD'),
                    )
                  ) {
                    let leaveTypesText = '';
                    if (row?.leaveTypes && row?.leaveTypes.length) {
                      row?.leaveTypes.map((item: any) => {
                        leaveTypesText += item?.leaveType?.type + ', ';
                      });
                    }
                    if (leaveTypesText) {
                      leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
                    }
                    return (
                      <StyledTableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <StyledTableCell2
                          component="th"
                          scope="row"
                          style={{ textDecoration: 'underline' }}>
                          <NavLink
                            style={{ color: '#241A2E' }}
                            to={`${String(
                              AdminPrivateRoutes.CONSULTANTS,
                            )}/profile/${String(row?.userId)}`}>
                            {row?.user?.lastName}, {row?.user?.firstName}
                          </NavLink>
                        </StyledTableCell2>
                        <StyledTableCell2 component="th" scope="row">
                          {row?.user?.ultraStaffDbName}
                        </StyledTableCell2>
                        <StyledTableCell2 component="th" scope="row">
                          {row?.user?.workAuth}
                        </StyledTableCell2>
                        <StyledTableCell2 component="th" scope="row">
                          {moment(row.createdAt).format('MM/DD/YYYY')}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {moment(row.fromDate).format('MM/DD/YYYY')}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {moment(row.toDate).format('MM/DD/YYYY')}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {row.adpUpdated}
                        </StyledTableCell2>
                        <StyledTableCell2
                          align="left"
                          style={{ textTransform: 'capitalize' }}>
                          {row.wishToBePaidOnLeave === 'yes'
                            ? 'Y'
                            : row.wishToBePaidOnLeave === 'no'
                            ? 'N'
                            : row.wishToBePaidOnLeave === 'partialyPaid'
                            ? 'P'
                            : ''}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {row.workingWhileOnLeave === null
                            ? ''
                            : row.workingWhileOnLeave
                            ? 'Y'
                            : 'N'}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {leaveTypesText}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {row?.onLeaveCountry}
                        </StyledTableCell2>
                        <StyledTableCell2
                          align="left"
                          style={{ textTransform: 'capitalize' }}>
                          {row.obtainI94}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {row.notes}
                        </StyledTableCell2>
                        <StyledTableCell2 align="left">
                          {row.comments}
                        </StyledTableCell2>
                        <StyledTableCell2 align="right">
                          <Button
                            onClick={handleClick('bottom-end', row, true)}
                            style={{ backgroundColor: 'transparent' }}>
                            <Avatar
                              variant="square"
                              alt="Profile"
                              src={DotsIcon}
                              style={{
                                borderRadius: 10,
                                height: 22,
                                width: 20,
                              }}
                            />
                          </Button>
                        </StyledTableCell2>
                      </StyledTableRow>
                    );
                  } else {
                    return;
                  }
                })}
                <TableRow>
                  <TableCell
                    sx={{
                      background: '#F5D8D8',
                    }}
                    className={'norecordfound'}
                    colSpan={16}
                    align="left">
                    <Typography
                      sx={{
                        alignItems: 'center',
                        color: '#292929',
                        display: 'flex',
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: '15px',
                        letterSpacing: '1.88px',
                        textTransform: 'uppercase',
                      }}>
                      <Typography
                        sx={{
                          background: '#BF0000',
                          height: '10px',
                          marginRight: '10px',
                          width: '10px',
                        }}
                      />
                      Leave Planned
                    </Typography>
                  </TableCell>
                </TableRow>
                {approvedList.map((row: any, index: number) => {
                  if (
                    moment(moment().format('YYYY-MM-DD')).isBetween(
                      moment(row.fromDate).format('YYYY-MM-DD'),
                      moment(row.toDate).format('YYYY-MM-DD'),
                    ) ||
                    moment(moment().format('YYYY-MM-DD')).isSame(
                      moment(row.fromDate).format('YYYY-MM-DD'),
                    ) ||
                    moment(moment().format('YYYY-MM-DD')).isSame(
                      moment(row.toDate).format('YYYY-MM-DD'),
                    ) ||
                    moment(moment().format('YYYY-MM-DD')).isAfter(
                      moment(row.toDate).format('YYYY-MM-DD'),
                    )
                  ) {
                    return;
                  } else {
                    let leaveTypesText = '';
                    if (row?.leaveTypes && row?.leaveTypes.length) {
                      row?.leaveTypes.map((item: any) => {
                        leaveTypesText += item?.leaveType?.type + ', ';
                      });
                    }
                    if (leaveTypesText) {
                      leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
                    }
                    return (
                      <StyledTableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <StyledTableCell1
                          component="th"
                          scope="row"
                          style={{ textDecoration: 'underline' }}>
                          <NavLink
                            style={{ color: '#241A2E' }}
                            to={`${String(
                              AdminPrivateRoutes.CONSULTANTS,
                            )}/profile/${String(row?.userId)}`}>
                            {row?.user?.lastName}, {row?.user?.firstName}
                          </NavLink>
                        </StyledTableCell1>
                        <StyledTableCell1 component="th" scope="row">
                          {row?.user?.ultraStaffDbName}
                        </StyledTableCell1>
                        <StyledTableCell1 component="th" scope="row">
                          {row?.user?.workAuth}
                        </StyledTableCell1>
                        <StyledTableCell1 component="th" scope="row">
                          {moment(row.createdAt).format('MM/DD/YYYY')}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {moment(row.fromDate).format('MM/DD/YYYY')}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {moment(row.toDate).format('MM/DD/YYYY')}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {row.adpUpdated}
                        </StyledTableCell1>
                        <StyledTableCell1
                          align="left"
                          style={{ textTransform: 'capitalize' }}>
                          {row.wishToBePaidOnLeave === 'yes'
                            ? 'Y'
                            : row.wishToBePaidOnLeave === 'no'
                            ? 'N'
                            : row.wishToBePaidOnLeave === 'partialyPaid'
                            ? 'P'
                            : ''}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {row.workingWhileOnLeave === null
                            ? ''
                            : row.workingWhileOnLeave
                            ? 'Y'
                            : 'N'}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {leaveTypesText}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {row?.onLeaveCountry}
                        </StyledTableCell1>
                        <StyledTableCell1
                          align="left"
                          style={{ textTransform: 'capitalize' }}>
                          {row.obtainI94}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {row.notes}
                        </StyledTableCell1>
                        <StyledTableCell1 align="left">
                          {row.comments}
                        </StyledTableCell1>
                        <StyledTableCell1 align="right">
                          <Button
                            onClick={handleClick('bottom-end', row, false)}
                            style={{ backgroundColor: 'transparent' }}>
                            <Avatar
                              variant="square"
                              alt="Profile"
                              src={DotsIcon}
                              style={{
                                borderRadius: 10,
                                height: 22,
                                width: 20,
                              }}
                            />
                          </Button>
                        </StyledTableCell1>
                      </StyledTableRow>
                    );
                  }
                })}
                <TableRow>
                  <TableCell
                    sx={{
                      background: '#E3E7EC',
                    }}
                    className={'norecordfound'}
                    colSpan={16}
                    align="left">
                    <Typography
                      sx={{
                        alignItems: 'center',
                        color: '#292929',
                        display: 'flex',
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: '15px',
                        letterSpacing: '1.88px',
                        textTransform: 'uppercase',
                      }}>
                      <Typography
                        sx={{
                          background: '#292929',
                          height: '10px',
                          marginRight: '10px',
                          width: '10px',
                        }}
                      />
                      Returned from Leave
                    </Typography>
                  </TableCell>
                </TableRow>
                {returnedList.map((row: any, index: number) => {
                  let leaveTypesText = '';
                  if (row?.leaveTypes && row?.leaveTypes.length) {
                    row?.leaveTypes.map((item: any) => {
                      leaveTypesText += item?.leaveType?.type + ', ';
                    });
                  }
                  if (leaveTypesText) {
                    leaveTypesText = leaveTypesText.replace(/,\s*$/, '');
                  }
                  return (
                    <StyledTableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ textDecoration: 'underline' }}>
                        <NavLink
                          style={{ color: '#241A2E' }}
                          to={`${String(
                            AdminPrivateRoutes.CONSULTANTS,
                          )}/profile/${String(row?.userId)}`}>
                          {row?.user?.lastName}, {row?.user?.firstName}
                        </NavLink>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.user?.ultraStaffDbName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.user?.workAuth}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.createdAt).format('MM/DD/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row.fromDate).format('MM/DD/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row.toDate).format('MM/DD/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.adpUpdated}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ textTransform: 'capitalize' }}>
                        {row.wishToBePaidOnLeave === 'yes'
                          ? 'Y'
                          : row.wishToBePaidOnLeave === 'no'
                          ? 'N'
                          : row.wishToBePaidOnLeave === 'partialyPaid'
                          ? 'P'
                          : ''}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.workingWhileOnLeave === null
                          ? ''
                          : row.workingWhileOnLeave
                          ? 'Y'
                          : 'N'}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {leaveTypesText}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.onLeaveCountry}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ textTransform: 'capitalize' }}>
                        {row.obtainI94}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.notes}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.comments}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          onClick={handleClick('bottom-end', row, false)}
                          style={{ backgroundColor: 'transparent' }}>
                          <Avatar
                            variant="square"
                            alt="Profile"
                            src={DotsIcon}
                            style={{
                              borderRadius: 10,
                              height: 22,
                              width: 20,
                            }}
                          />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Popper
            className="popwidget"
            open={open1}
            anchorEl={anchorEl}
            placement={placement}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 2 }}>
                    <p style={{ margin: 0, padding: 5 }}>
                      <Button
                        onClick={() => {
                          setOpen1(false);
                          setEditRoW(editOrRemoveRow);
                        }}
                        sx={{
                          backgroundColor: 'transparent',
                          color: '#fff',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: 15,
                          justifyContent: 'flex-start',
                          margin: 0,
                          padding: 0,
                          textTransform: 'capitalize',
                        }}>
                        Edit
                      </Button>
                    </p>
                    <p style={{ margin: 0, padding: 5 }}>
                      <Button
                        onClick={() => {
                          setOpen1(false);
                          handlePdfDownload(editOrRemoveRow);
                        }}
                        sx={{
                          backgroundColor: 'transparent',
                          color: '#fff',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: 15,
                          justifyContent: 'flex-start',
                          margin: 0,
                          padding: 0,
                          textTransform: 'capitalize',
                        }}>
                        Download
                      </Button>
                    </p>
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <form
              id={'leaveApply'}
              onSubmit={handleSubmit(submitForm, onError)}>
              <Box sx={style} className="model-widget">
                <Typography id="modal-modal-title" component="h2">
                  Edit Leave
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Grid container>
                    <Grid item md={4} sx={{ paddingRight: '14px' }}>
                      <Typography>
                        <label>Start Date</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="fromDate"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <DatePicker
                                placeHolder="Select"
                                value={value}
                                name={name}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={formState.errors?.fromDate?.message}
                                disabled={editOrRemoveRow.status === 'returned'}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={4} sx={{ paddingRight: '14px' }}>
                      <Typography>
                        <label>End Date</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="toDate"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <DatePicker
                                placeHolder="Select"
                                value={value}
                                name={name}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={formState.errors?.toDate?.message}
                                disabled={
                                  editOrRemoveRow.status === 'returned'
                                    ? true
                                    : watch('fromDate')
                                    ? false
                                    : true
                                }
                                minDate={watch('fromDate')}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography>
                        <label>Return Date</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="returnDate"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <DatePicker
                                placeHolder="Select"
                                value={value}
                                name={name}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={formState.errors?.returnDate?.message}
                                disabled={
                                  editOrRemoveRow.status === 'returned'
                                    ? true
                                    : watch('fromDate')
                                    ? false
                                    : true
                                }
                                minDate={watch('fromDate')}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{ alignItems: 'center', paddingTop: '8px' }}>
                    <Grid item xs={5}>
                      <label>Will you be Working While on Leave?</label>
                    </Grid>
                    <Grid item xs={7} sx={{ paddingLeft: '20px' }}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="workingWhileOnLeave"
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CheckboxCom
                              name={name}
                              checkboxArray={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                              ]}
                              onChange={(e) => {
                                onChange(e);
                                if (
                                  e === 'yes' &&
                                  workingDaysArray.length === 0
                                ) {
                                  workingDaysArrayAppend({
                                    fromDate: '',
                                    toDate: '',
                                  });
                                }
                              }}
                              value={value}
                              error={
                                formState.errors?.workingWhileOnLeave?.message
                              }
                              disabled={editOrRemoveRow.status === 'returned'}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  {watch('workingWhileOnLeave') === 'yes' && (
                    <Grid container sx={{ paddingLeft: '20px' }}>
                      <Grid item xs={12}>
                        <Typography sx={leaveStyle.normalText}>
                          If yes, please indicate the dates you will be working
                        </Typography>
                      </Grid>
                      {workingDaysArray.length &&
                        watch('workingDays').map((item: any, index: number) => {
                          return (
                            <Grid container key={index}>
                              <Grid item xs={4} sx={{ paddingRight: '10px' }}>
                                <Typography sx={{ paddingTop: '5px' }}>
                                  <Controller
                                    control={control}
                                    defaultValue=""
                                    name={`workingDays.${index}.fromDate`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                    }) => {
                                      let workMinDate1 = watch('fromDate');
                                      if (index !== 0) {
                                        workMinDate1 = new Date(
                                          watch('workingDays')?.[
                                            index - 1
                                          ]?.toDate,
                                        );
                                        workMinDate1.setDate(
                                          workMinDate1.getDate() + 1,
                                        );
                                      }
                                      return (
                                        <DatePicker
                                          placeHolder="Select"
                                          value={value}
                                          name={name}
                                          onChange={(e: any) => {
                                            onChange(e);
                                          }}
                                          error={
                                            formState.errors?.workingDays?.[
                                              index
                                            ]?.fromDate?.message
                                          }
                                          minDate={workMinDate1}
                                          maxDate={watch('returnDate')}
                                          disabled={
                                            editOrRemoveRow.status ===
                                            'returned'
                                              ? true
                                              : index === 0 &&
                                                watch('fromDate') &&
                                                watch('returnDate')
                                              ? false
                                              : watch('workingDays')?.[
                                                  index - 1
                                                ]?.toDate
                                              ? false
                                              : true
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography sx={{ paddingTop: '5px' }}>
                                  <Controller
                                    control={control}
                                    defaultValue=""
                                    name={`workingDays.${index}.toDate`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                    }) => {
                                      const workMinDate = new Date(
                                        watch('workingDays')?.[index]?.fromDate,
                                      );
                                      const workMaxDate = watch('returnDate');
                                      return (
                                        <DatePicker
                                          placeHolder="Select"
                                          value={value}
                                          name={name}
                                          onChange={(e: any) => {
                                            onChange(e);
                                          }}
                                          error={
                                            formState.errors?.workingDays?.[
                                              index
                                            ]?.toDate?.message
                                          }
                                          minDate={workMinDate}
                                          maxDate={workMaxDate}
                                          disabled={
                                            editOrRemoveRow.status ===
                                            'returned'
                                              ? true
                                              : watch('workingDays')?.[index]
                                                  ?.fromDate
                                              ? false
                                              : true
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignSelf: 'center',
                                  paddingLeft: '10px',
                                  paddingTop: '5px',
                                }}>
                                {index !== 0 && (
                                  <a
                                    href={'javascript:void(0)'}
                                    style={{ paddingRight: 8 }}
                                    onClick={() => {
                                      workingDaysArrayRomove(index);
                                      if (item?.id) {
                                        removableWorkableDates.push(item?.id);
                                        setRemovableWorkableDates([
                                          ...removableWorkableDates,
                                        ]);
                                      }
                                    }}>
                                    <RemoveCircleOutlineIcon
                                      sx={{
                                        color: '#e0e0e4',
                                        fontSize: '35px',
                                      }}
                                    />
                                  </a>
                                )}
                                {index === workingDaysArray.length - 1 && (
                                  <a
                                    href={'javascript:void(0)'}
                                    onClick={() =>
                                      workingDaysArrayAppend({
                                        fromDate: '',
                                        toDate: '',
                                      })
                                    }>
                                    <AddCircleOutlineIcon
                                      sx={{
                                        color: '#e0e0e4',
                                        fontSize: '35px',
                                      }}
                                    />
                                  </a>
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  <Grid
                    container
                    sx={{ alignItems: 'center', paddingTop: '8px' }}>
                    <Grid item xs={5}>
                      <label>Do You Wish to be Paid While on Leave?</label>
                    </Grid>
                    <Grid item xs={7} sx={{ paddingLeft: '20px' }}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="wishToBePaidOnLeave"
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CheckboxCom
                              name={name}
                              checkboxArray={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                                {
                                  label: 'Partially Paid',
                                  value: 'partialyPaid',
                                },
                              ]}
                              onChange={(e) => {
                                onChange(e);
                                if (
                                  (e === 'yes' || e === 'partialyPaid') &&
                                  payableDaysArray.length === 0
                                ) {
                                  payableDaysArrayAppend({
                                    fromDate: '',
                                    toDate: '',
                                  });
                                }
                              }}
                              value={value}
                              error={
                                formState.errors?.wishToBePaidOnLeave?.message
                              }
                              disabled={editOrRemoveRow.status === 'returned'}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  {(watch('wishToBePaidOnLeave') === 'yes' ||
                    watch('wishToBePaidOnLeave') === 'partialyPaid') && (
                    <Grid container sx={{ paddingLeft: '20px' }}>
                      {payableDaysArray.length &&
                        watch('payableDays').map((item: any, index: number) => {
                          return (
                            <Grid container key={index}>
                              <Grid item xs={4} sx={{ paddingRight: '10px' }}>
                                <Typography sx={{ paddingTop: '5px' }}>
                                  <Controller
                                    control={control}
                                    defaultValue=""
                                    name={`payableDays.${index}.fromDate`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                    }) => {
                                      let payableMinDate1 = watch('fromDate');
                                      if (index !== 0) {
                                        payableMinDate1 = new Date(
                                          watch('payableDays')?.[
                                            index - 1
                                          ]?.toDate,
                                        );
                                        payableMinDate1.setDate(
                                          payableMinDate1.getDate() + 1,
                                        );
                                      }
                                      return (
                                        <DatePicker
                                          placeHolder="Select"
                                          value={value}
                                          name={name}
                                          onChange={(e: any) => {
                                            onChange(e);
                                          }}
                                          error={
                                            formState.errors?.payableDays?.[
                                              index
                                            ]?.fromDate?.message
                                          }
                                          minDate={payableMinDate1}
                                          maxDate={watch('returnDate')}
                                          disabled={
                                            editOrRemoveRow.status ===
                                            'returned'
                                              ? true
                                              : index === 0 &&
                                                watch('fromDate') &&
                                                watch('returnDate')
                                              ? false
                                              : watch('payableDays')?.[
                                                  index - 1
                                                ]?.toDate
                                              ? false
                                              : true
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography sx={{ paddingTop: '5px' }}>
                                  <Controller
                                    control={control}
                                    defaultValue=""
                                    name={`payableDays.${index}.toDate`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                    }) => {
                                      const payableMinDate = new Date(
                                        watch('payableDays')?.[index]?.fromDate,
                                      );
                                      const payableMaxDate =
                                        watch('returnDate');
                                      return (
                                        <DatePicker
                                          placeHolder="Select"
                                          value={value}
                                          name={name}
                                          onChange={(e: any) => {
                                            onChange(e);
                                          }}
                                          error={
                                            formState.errors?.payableDays?.[
                                              index
                                            ]?.toDate?.message
                                          }
                                          minDate={payableMinDate}
                                          maxDate={payableMaxDate}
                                          disabled={
                                            editOrRemoveRow.status ===
                                            'returned'
                                              ? true
                                              : watch('payableDays')?.[index]
                                                  ?.fromDate
                                              ? false
                                              : true
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              {editOrRemoveRow.status !== 'returned' && (
                                <Grid
                                  item
                                  xs={3}
                                  sx={{
                                    alignSelf: 'center',
                                    paddingLeft: '10px',
                                    paddingTop: '5px',
                                  }}>
                                  {index !== 0 && (
                                    <a
                                      href={'javascript:void(0)'}
                                      style={{ paddingRight: 8 }}
                                      onClick={() => {
                                        payableDaysArrayRomove(index);
                                        if (item?.id) {
                                          removablePayDates.push(item?.id);
                                          setRemovablePayDates([
                                            ...removablePayDates,
                                          ]);
                                        }
                                      }}>
                                      <RemoveCircleOutlineIcon
                                        sx={{
                                          color: '#e0e0e4',
                                          fontSize: '35px',
                                        }}
                                      />
                                    </a>
                                  )}
                                  {index === payableDaysArray.length - 1 && (
                                    <a
                                      href={'javascript:void(0)'}
                                      onClick={() =>
                                        payableDaysArrayAppend({
                                          fromDate: '',
                                          toDate: '',
                                        })
                                      }>
                                      <AddCircleOutlineIcon
                                        sx={{
                                          color: '#e0e0e4',
                                          fontSize: '35px',
                                        }}
                                      />
                                    </a>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  <Grid
                    container
                    sx={{ alignItems: 'center', paddingTop: '20px' }}>
                    <Grid item xs={12}>
                      <Typography sx={leaveStyle.mediumText}>
                        Reason for Leave?
                        <span className="errorText">{'*'}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="leaveTypes"
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CheckboxCom
                              name={name}
                              checkboxArray={leaveTypes}
                              onChange={(e, removeId) => {
                                if (removeId) {
                                  removableLeaveTypes.push(removeId);
                                  setRemovableLeaveTypes([
                                    ...removableLeaveTypes,
                                  ]);
                                }
                                onChange(e);
                              }}
                              multi={true}
                              value={value}
                              error={formState.errors?.leaveTypes?.message}
                              disabled={editOrRemoveRow.status === 'returned'}
                            />
                          );
                        }}
                      />
                    </Grid>
                    {watch('leaveTypes') &&
                      watch('leaveTypes').map((item: any, index: number) => {
                        if (Number(item.leaveTypeId) === 5) {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              style={{
                                paddingBottom: '5px',
                                paddingTop: '5px',
                              }}>
                              <Controller
                                control={control}
                                defaultValue=""
                                name="reasonComments"
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => {
                                  return (
                                    <CustomInput
                                      placeHolder="please explain"
                                      value={value}
                                      name={name}
                                      className={classes.custominput}
                                      ref={ref}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      error={
                                        formState.errors?.reasonComments
                                          ?.message
                                      }
                                      disabled={
                                        editOrRemoveRow.status === 'returned'
                                      }
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          );
                        }
                        return;
                      })}
                  </Grid>
                  <Grid container>
                    {/* <Grid
                      item
                      md={6}
                      sx={{ paddingRight: '15px', paddingTop: '10px' }}>
                      <Typography>
                        <label>Remote Work</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="remotePaid"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <>
                                <Select
                                  style={{ textTransform: 'capitalize' }}
                                  className="common-select"
                                  value={value}
                                  onChange={(e: any) => {
                                    onChange(e);
                                  }}
                                  name={name}
                                  ref={ref}
                                  displayEmpty
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  disabled={
                                    editOrRemoveRow.status === 'returned'
                                  }>
                                  <MenuItem value={'yes'}>Yes</MenuItem>
                                  <MenuItem value={'no'}>No</MenuItem>
                                </Select>
                                {formState.errors?.remotePaid?.message && (
                                  <FormHelperText className="errorText">
                                    {formState.errors?.remotePaid?.message}
                                  </FormHelperText>
                                )}
                              </>
                            );
                          }}
                        />
                      </Typography>
                    </Grid> */}
                    <Grid
                      item
                      md={6}
                      sx={{ paddingRight: '15px', paddingTop: '10px' }}>
                      <Typography>
                        <label>ADP Updated</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="adpUpdated"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder=""
                                value={value}
                                name={name}
                                className={classes.custominput}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={formState.errors?.adpUpdated?.message}
                                // disabled={editOrRemoveRow.status === 'returned'}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sx={{ paddingTop: '10px' }}>
                      <Typography>
                        <label>Obtain - I94</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="obtainI94"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <>
                                <Select
                                  style={{ textTransform: 'capitalize' }}
                                  className="common-select"
                                  value={value}
                                  onChange={(e: any) => {
                                    onChange(e);
                                  }}
                                  name={name}
                                  ref={ref}
                                  displayEmpty
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}>
                                  <MenuItem value={'yes'}>Yes</MenuItem>
                                  <MenuItem value={'no'}>No</MenuItem>
                                </Select>
                                {formState.errors?.obtainI94?.message && (
                                  <FormHelperText className="errorText">
                                    {formState.errors?.obtainI94?.message}
                                  </FormHelperText>
                                )}
                              </>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={6} sx={{ paddingTop: '10px' }}>
                      <Typography>
                        <label>Country of Leave</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="onLeaveCountry"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder="Country of Leave"
                                value={value}
                                name={name}
                                className={classes.custominput}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={
                                  formState.errors?.onLeaveCountry?.message
                                }
                                disabled={editOrRemoveRow.status === 'returned'}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={12} sx={{ paddingTop: '10px' }}>
                      <Typography>
                        <label>Admin Comments</label>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="notes"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder=""
                                value={value}
                                name={name}
                                className={classes.custominput}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={formState.errors?.notes?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography sx={{ paddingTop: '20px' }} />
                <Divider />
                <Typography sx={{ paddingTop: '20px' }} />
                <Typography className="model-btn" component={'div'}>
                  <Button variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                  <CustomButton
                    variant="contained"
                    type={'submit'}
                    isStyle={true}
                    name={'Update Leave'}
                    form={'leaveApply'}
                    loading={loading}
                  />
                </Typography>
              </Box>
            </form>
          </Modal>
        </Grid>
      </Typography>
    </LoadingOverlay>
  );
};

export default LeaveTracker;
