import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../PrivateStyle';
import LeaveApply from './LeaveApply';
import LeaveHistory from './LeaveHistory';

export const Myprofile: React.FC<any> = () => {
  const classes = useStyles();
  const [leaveHistory, setLeaveHistory] = React.useState<any>([]);
  const [leaveHistoryDenied, setLeaveHistoryDenied] = React.useState<any>([]);
  const [leaveHistoryPlanned, setLeaveHistoryPlanned] = React.useState<any>([]);
  const [leaveReturnedHistory, setLeaveReturnedHistory] = React.useState<any>(
    [],
  );

  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  useEffect(() => {
    getLeaveHistory(userInfo.id);
  }, [userInfo.id]);

  const getLeaveHistory = (userId) => {
    getAllListData(
      MASTER_ENDPOINT.Leaves + '?userId=' + userId + '&status=approved',
    )
      .then((resp: any) => {
        setLeaveHistory(resp?.data);
      })
      .catch((err: any) => {});
    getAllListData(
      MASTER_ENDPOINT.Leaves + '?userId=' + userId + '&status=pending',
    )
      .then((resp: any) => {
        setLeaveHistoryPlanned(resp?.data);
      })
      .catch((err: any) => {});
    getAllListData(
      MASTER_ENDPOINT.Leaves + '?userId=' + userId + '&status=returned',
    )
      .then((resp: any) => {
        setLeaveReturnedHistory(resp?.data);
      })
      .catch((err: any) => {});
    getAllListData(
      MASTER_ENDPOINT.Leaves + '?userId=' + userId + '&status=denied',
    )
      .then((resp: any) => {
        setLeaveHistoryDenied(resp?.data);
      })
      .catch((err: any) => {});
  };

  return (
    <Grid container justifyContent={'center'} sx={{ width: '100%' }}>
      <Typography component={'div'} style={{ marginTop: 50 }}>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              component={'div'}
              className={classes.reasoncard}
              sx={{ paddingTop: '20px' }}>
              <Typography component="h6">
                Please complete a leave application for the following reasons:
              </Typography>
              <Typography component={'div'}>
                <ul className={classes.reasondetails}>
                  <li>
                    &#x2022; If you are travel outside the U.S. whether you are
                    working remotely or not.
                  </li>
                  {/* <li>&#x2022; If you are using any amount of sick time.</li> */}
                  <li>
                    &#x2022; If you are in the U.S. and requesting not to be
                    paid for any reason
                  </li>
                  <li style={{ fontStyle: 'italic', marginLeft: '8px' }}>
                    {'  '}
                    (e.g., medical, marriage, personal).*{' '}
                  </li>
                  <li style={{ display: 'block' }}>
                    &#x2022; If the reason for your leave is MEDICAL for
                    yourself or a family member, please email{'  '}
                    <a
                      className="pointer"
                      href={'mailto:hr@riseits.com'}
                      style={{
                        color: '#005EBF',
                        fontFamily: 'Red Hat Display Medium !important',
                        fontSize: '12px !important',
                        textDecoration: 'underline',
                      }}>
                      hr@riseits.com
                    </a>
                  </li>
                </ul>
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className={classes.profilecard1}
              sx={{ paddingTop: '20px' }}>
              <Typography component="h6">Leave History</Typography>
              <LeaveHistory
                leaveHistory={leaveHistory}
                leaveReturnedHistory={leaveReturnedHistory}
                leaveHistoryPlanned={leaveHistoryPlanned}
                leaveHistoryDenied={leaveHistoryDenied}
              />
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography component={'div'} className={classes.profilecard1}>
              <LeaveApply getLeaveHistory={getLeaveHistory} />
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </Grid>
  );
};

export default Myprofile;
