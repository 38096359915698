import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { AdminPrivateRoutes } from 'config/index';
import moment from 'moment';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink, useParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from './style';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display Regular',
    fontSize: 12,
    padding: 8,
    paddingBottom: 12,
    paddingTop: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fff',
    borderColor: '#f2f2f2',
    borderWidth: 6,
    color: '#241A2E',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 14,
    height: 50,
    padding: 5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const ClientsSub: React.FC<any> = () => {
  const { clientId } = useParams();
  const [list, setList] = React.useState([]);
  const classes = useStyles();

  const [loader, setLoader] = React.useState<boolean>(false);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    setLoader(true);
    getAllListData(MASTER_ENDPOINT.ProjectsConsultant + '?id=' + clientId)
      .then((resp: any) => {
        setList(resp.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container className={classes.header1}>
          <Grid item md={12}>
            <Typography component={'div'}>
              <NavLink
                to={AdminPrivateRoutes.CLIENTS}
                className={classes.backText}>
                Back to Clients
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.tableview1}>
          {list.map((row: any, index: number) => {
            return (
              <>
                <Grid item md={12}>
                  <Typography component={'div'}>
                    <span className={classes.h1Text}>{row.clientName}</span>
                  </Typography>
                </Grid>
                {row.projects.map((row2: any, index2: number) => {
                  return (
                    <>
                      <Grid item md={12} style={{ paddingTop: 20 }}>
                        <Typography component={'div'}>
                          <span className={classes.h2Text}>{row2.project}</span>
                        </Typography>
                      </Grid>

                      <Grid item md={12}>
                        <TableContainer>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Consultants</StyledTableCell>
                                <StyledTableCell>LOB</StyledTableCell>
                                <StyledTableCell>Branch</StyledTableCell>
                                <StyledTableCell>Spec Start</StyledTableCell>
                                <StyledTableCell>Bill to</StyledTableCell>
                                <StyledTableCell>P/E</StyledTableCell>
                                <StyledTableCell>End Client</StyledTableCell>
                                <StyledTableCell>Taxing State</StyledTableCell>
                                <StyledTableCell>Current Rate</StyledTableCell>
                                <StyledTableCell>Discount</StyledTableCell>
                                <StyledTableCell>Adj. Rate</StyledTableCell>
                                <StyledTableCell>Admin Fee</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row2.projectConsultants.map(
                                (row1: any, index1: number) => (
                                  <StyledTableRow key={index1}>
                                    <StyledTableCell
                                      sx={{
                                        fontFamily:
                                          'Red Hat Display Bold !important ',
                                        fontSize: '16px !important',
                                      }}
                                      align="center">
                                      {row1?.user?.lastName},{' '}
                                      {row1?.user?.firstName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row1?.user?.lob}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row1?.user?.branch
                                        ? row1?.user?.branch
                                        : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row?.spc}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row?.billToClient}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row1?.endDate
                                        ? moment(row1?.endDate).format(
                                            'MM/DD/YYYY',
                                          )
                                        : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row?.clientName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row?.state}
                                    </StyledTableCell>
                                    <StyledTableCell>{index1}</StyledTableCell>
                                    <StyledTableCell>{index1}</StyledTableCell>
                                    <StyledTableCell>{index1}</StyledTableCell>
                                    <StyledTableCell>{index1}</StyledTableCell>
                                  </StyledTableRow>
                                ),
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </>
                  );
                })}
              </>
            );
          })}
        </Grid>
      </Typography>
    </LoadingOverlay>
  );
};

export default ClientsSub;
