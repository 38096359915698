import 'react-phone-input-2/lib/style.css';

import { Box, Button, Link, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { USER_LOGOUT } from 'store/types/UserTypes';
import Storage from 'utils/Storage';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

export const LogOut: React.FC<any> = () => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    // e.preventDefault();
    dispatch({
      type: USER_LOGOUT,
    });
    localStorage.clear();
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    Storage.removeItem(Storage.KEYS.MENUS);
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Link href="#" onClick={handleOpen} underline="none" color="#BF0000">
        Logout
      </Link>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="confirmation-widget">
          <Typography id="modal-modal-title" component="h2">
            Are you sure you want to Logout?
          </Typography>
          <Typography className="confirm-btn model-btn" component={'div'}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ textTransform: 'capitalize' }}>
              No, Cancel
            </Button>
            <Button variant="contained" onClick={logoutHandler}>
              YES, Logout
            </Button>
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default LogOut;
