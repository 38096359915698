import {
  Accordion,
  AccordionDetails,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import accordionarrow from 'assets/images/arrow-accordin.svg';
import CheckGreen from 'assets/images/check-alert-green.svg';
import arrowClose from 'assets/images/closeCircle.svg';
import MinusPng from 'assets/images/minus.png';
import TimePng from 'assets/images/time.png';
import { AdminPrivateRoutes } from 'config';
import moment from 'moment';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { alertOptions, roundOfData } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

type PropsData = {
  item: any;
  expand: string;
  index: number;
  setPayPeriodData: (pre: any) => void;
  userIdData?: string;
};

const PayPeriodsAccordion = React.memo((props: PropsData) => {
  console.log('Accordion Loaded');
  const { item, expand, index, setPayPeriodData, userIdData } = props;
  const [userList, setUserList] = React.useState<any>([]);
  const [extraList, setExtraList] = React.useState<any>([]);
  const [list, setList] = React.useState<{
    loading: boolean;
    data: any[];
    showBtn: boolean;
  }>({
    data: [],
    loading: false,
    showBtn: false,
  });
  const { companyStatus }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  useEffect(() => {
    if (expand) {
      getAccordianDetails(expand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, companyStatus]);

  const getAccordianDetails = async (id: string) => {
    setList((pre: any) => {
      return { ...pre, data: [], loading: true, showBtn: false };
    });
    setExtraList([]);
    let url =
      MASTER_ENDPOINT.PayrollQuestions + '?payperiodId=' + id + '&order=asc';
    if (companyStatus !== 'all') {
      url += `&organization=${String(companyStatus)}`;
    }
    if (userIdData) {
      url += `&userId=${String(userIdData)}`;
    }
    await getAllListData(url)
      .then((resp: any) => {
        const btnStatus =
          companyStatus === 'smartworks'
            ? resp.data?.[0]?.payPeriod?.isSmartWorksPayrollQuestionsSubmited
            : resp.data?.[0]?.payPeriod?.isItechPayrollQuestionsSubmited;
        setList((pre: any) => {
          return {
            ...pre,
            data: resp?.data,
            loading: false,
            showBtn: btnStatus,
          };
        });
        if (resp.data.length && !btnStatus) {
          let url1 =
            MASTER_ENDPOINT.PayrollQuestions + '/users?payperiodId=' + id;
          if (companyStatus !== 'all') {
            url1 += `&organization=${String(companyStatus)}`;
          }
          getAllListData(url1).then((resp: any) => {
            setUserList(resp?.data);
          });
        }
      })
      .catch((err: any) => {
        setList((pre: any) => {
          return { ...pre, loading: false };
        });
      });
  };

  const submitQuestion = () => {
    // let allValidation = true;
    const postArray = [];
    list.data.map((item: any) => {
      postArray.push({
        adjustedAmount:
          Math.max(
            item.previousBufferAmount
              ? parseFloat(item.previousBufferAmount)
              : 0,
            item.currentBufferAmount ? parseFloat(item.currentBufferAmount) : 0,
          ) -
          Math.min(
            item.previousBufferAmount
              ? parseFloat(item.previousBufferAmount)
              : 0,
            item.currentBufferAmount ? parseFloat(item.currentBufferAmount) : 0,
          ),
        consultantId: item?.consultantId,
        currentBufferAmount: item?.currentBufferAmount,
        id: item.id,
        note: item?.note,
        payperiodId: expand,
        preApproved: item?.preApproved ? item?.preApproved : false,
        previousBufferAmount: item?.previousBufferAmount,
        status: item.status,
      });
      if (!item.note) {
        // allValidation = false;
      }
    });
    extraList.map((item: any) => {
      if (item?.id) {
        postArray.push({
          adjustedAmount:
            item.jobWcCode === '3P/I'
              ? Math.max(
                  item.previousBufferAmount
                    ? parseFloat(item.previousBufferAmount)
                    : 0,
                  item.currentBufferAmount
                    ? parseFloat(item.currentBufferAmount)
                    : 0,
                ) -
                Math.min(
                  item.previousBufferAmount
                    ? parseFloat(item.previousBufferAmount)
                    : 0,
                  item.currentBufferAmount
                    ? parseFloat(item.currentBufferAmount)
                    : 0,
                )
              : 0,
          consultantId: item?.id,
          // id: item.id,
          currentBufferAmount:
            item.jobWcCode === '3P/I' ? item?.currentBufferAmount : 0,
          note: item?.note,
          payperiodId: expand,
          preApproved: item?.preApproved ? item?.preApproved : false,
          previousBufferAmount:
            item.jobWcCode === '3P/I' ? item?.previousBufferAmount : 0,
          status: 'pending',
        });
      }
      if (!item.note && item?.id) {
        // allValidation = false;
      }
    });
    if (postArray.length) {
      updateData(
        '',
        { questions: postArray },
        MASTER_ENDPOINT.PayrollQuestions,
        true,
      )
        .then((resp: any) => {
          // getAccordianDetails(expand);
        })
        .catch((err: any) => {});
    } else {
      toast.error('Please add notes', alertOptions);
    }
  };

  const saveChanges = () => {
    const extraKey: any = `${expand}/${
      companyStatus === 'smartworks' ? 'SmartWorks' : 'iTech'
    }`;
    updateData(
      extraKey,
      {},
      MASTER_ENDPOINT.PayrollQuestions + '/submit',
      true,
    ).then(() => {
      getAccordianDetails(expand);
    });
  };

  const renderItem = React.useCallback(
    (row: any, index: number, editable?: boolean) => {
      console.log('table Item rendered');
      let pendingNotes = '';
      let adjustNote: any = {};
      const kStatus = row.status;
      if (row.notes && row.notes.length) {
        if (row.status === 'adjusted') {
          adjustNote = row.notes.find((item: any) => item.type === 'adjusted');
        }
        const fIndex = row.notes.filter((item: any) => item.type === 'pending');
        if (fIndex.length) {
          pendingNotes = row.notes[fIndex.length - 1]?.note;
        }
      }
      return (
        <>
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0,
              },
              borderBottom:
                kStatus === 'adjusted'
                  ? '0px !important'
                  : '2px solid #eee !important',
            }}>
            <TableCell component="th" scope="row">
              <NavLink
                to={`${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
                  row?.user?.id,
                )}`}
                state={{ from: 'Payroll' }}
                style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {row?.user?.lastName + ', ' + row?.user?.firstName}
              </NavLink>
            </TableCell>
            <TableCell align="left">
              {row?.user.jobWcCode === '3P/I'
                ? roundOfData(row.currentOweAmount)
                : '-'}
            </TableCell>
            <TableCell align="left">
              {row?.user.jobWcCode === '3P/I'
                ? roundOfData(row.currentBufferAmount)
                : '-'}
            </TableCell>
            {!editable && (
              <TableCell align="left">
                <Checkbox
                  sx={{
                    '& .MuiCheckbox-root': {
                      color: '#1AB23D !important',
                    },
                    color: '#1AB23D !important',
                    padding: '0px !important',
                  }}
                  defaultChecked={row.preApproved}
                  color={'success'}
                  size={'medium'}
                  onChange={(e) => {
                    setList((pre: any) => {
                      row.preApproved = e.target.checked;
                      pre.data[index] = row;
                      return { ...pre };
                    });
                  }}
                />
              </TableCell>
            )}
            {editable && (
              <TableCell align="left">
                {kStatus === 'approved' || row.preApproved ? (
                  <img src={CheckGreen} style={{ height: 20, width: 20 }} />
                ) : kStatus === 'adjusted' ? (
                  <img src={MinusPng} style={{ height: 20, width: 20 }} />
                ) : (
                  <img src={TimePng} style={{ height: 20, width: 20 }} />
                )}
              </TableCell>
            )}
            <TableCell align="left">
              <TextField
                placeholder=""
                multiline
                defaultValue={pendingNotes}
                onChange={(e: any) => {
                  setList((pre: any) => {
                    row.note = e.target.value;
                    pre.data[index] = row;
                    return { ...pre };
                  });
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '10px',
                  },
                  '& textarea': {
                    maxHeight: '65px',
                  },
                  minWidth: '200px',
                }}
              />
            </TableCell>
          </TableRow>
          {kStatus === 'adjusted' && (
            <TableRow
              key={row?.user?.id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}>
              <TableCell align="left" colSpan={3} />
              <TableCell align="left" colSpan={2}>
                <Box className="past-payroll-red-card">
                  <Box className="past-payrollquestions2">
                    Adjusted: <strong>{row?.adjustedAmount}</strong>
                  </Box>
                  <Box className="past-payrolldescription2">
                    {adjustNote?.note}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </>
      );
    },
    [],
  );

  const tableData = React.useMemo(() => {
    console.log('table List rendered');
    return list.data.map((row: any, index: number) => {
      return renderItem(row, index, list.showBtn);
    });
  }, [list.data, list.showBtn, renderItem]);

  const extraListFun = React.useMemo(() => {
    return extraList.map((row: any, index: number) => {
      return (
        <TableRow
          key={row?.id}
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0,
            },
          }}>
          <TableCell component="th" scope="row">
            {row.firstName ? (
              <NavLink
                to={`${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
                  row?.id,
                )}`}
                state={{ from: 'Payroll' }}
                style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {row?.lastName + ', ' + row?.firstName}
              </NavLink>
            ) : (
              <Autocomplete
                selectOnFocus
                clearOnBlur
                freeSolo
                onChange={(e: any, reson: any) => {
                  if (reson) {
                    extraList[index] = reson;
                    const findUSerIndex = userList.findIndex(
                      (item: any) => item.id === reson.id,
                    );
                    userList.splice(findUSerIndex, 1);
                    setUserList([...userList]);
                  } else {
                    extraList[index] = { extra: true };
                  }
                  setExtraList([...extraList]);
                }}
                handleHomeEndKeys
                id="combo-box-demo"
                options={userList}
                getOptionLabel={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                sx={{ width: 250 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select" />
                )}
              />
            )}
          </TableCell>
          <TableCell align="left">
            {row.jobWcCode === '3P/I'
              ? roundOfData(row.previousBufferAmount)
              : '-'}
          </TableCell>
          <TableCell align="left">
            {row.jobWcCode === '3P/I'
              ? roundOfData(row.currentBufferAmount)
              : '-'}
          </TableCell>
          <TableCell align="left">
            <Checkbox
              sx={{
                '& .MuiCheckbox-root': {
                  color: '#1AB23D !important',
                },
                color: '#1AB23D !important',
                padding: '0px !important',
              }}
              color={'success'}
              size={'medium'}
              onChange={(e) => {
                extraList[index].preApproved = e.target.checked;
                setExtraList([...extraList]);
              }}
              checked={row?.preApproved}
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{ background: 'transparent !important', display: 'flex' }}>
            <TextField
              placeholder=""
              multiline
              value={row?.note}
              onChange={(e: any) => {
                extraList[index].note = e.target.value;
                setExtraList([...extraList]);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '10px',
                },
                '& textarea': {
                  maxHeight: '65px',
                },
                minWidth: '200px',
              }}
            />
            <Typography
              component={'div'}
              onClick={() => {
                extraList.splice(index, 1);
                setExtraList([...extraList]);
              }}
              sx={{ alignSelf: 'center', cursor: 'pointer' }}>
              <img src={arrowClose} />
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  }, [extraList, userList]);

  const totalAfterBalance = React.useMemo(() => {
    let totalHours = 0.0;
    list.data.map((item: any) => {
      totalHours =
        Number(totalHours) +
        Number(
          item.currentBufferAmount ? parseFloat(item.currentBufferAmount) : 0,
        );
    });
    extraList.map((item: any) => {
      if (item.jobWcCode === '3P/I') {
        totalHours =
          Number(totalHours) +
          Number(
            item.currentBufferAmount ? parseFloat(item.currentBufferAmount) : 0,
          );
      }
    });
    return roundOfData(Number(totalHours).toFixed(2));
  }, [extraList, list.data]);

  const totalBeforeBalance = React.useMemo(() => {
    let totalHours = 0.0;
    list.data.map((item: any) => {
      totalHours =
        Number(totalHours) +
        Number(item.currentOweAmount ? parseFloat(item.currentOweAmount) : 0);
    });
    extraList.map((item: any) => {
      if (item.jobWcCode === '3P/I') {
        totalHours =
          Number(totalHours) +
          Number(
            item.previousBufferAmount
              ? parseFloat(item.previousBufferAmount)
              : 0,
          );
      }
    });
    return roundOfData(Number(totalHours).toFixed(2));
  }, [extraList, list.data]);

  const paymentUpdate = (type: string) => {
    if (type === 'itech') {
      updateData(
        '',
        {},
        MASTER_ENDPOINT.PayrollQuestions + '/sync/' + expand + '/itech-payment',
        true,
      ).then(() => {});
    } else {
      updateData(
        '',
        {},
        MASTER_ENDPOINT.PayrollQuestions +
          '/sync/' +
          expand +
          '/smartworks-payment',
        true,
      ).then(() => {
        getAccordianDetails(expand);
      });
    }
  };

  return (
    <Accordion expanded={expand === item?.id} key={index}>
      <Typography component={'div'} className="accordin-details1">
        <Typography component={'p'} sx={{ padding: '20px', width: '30%' }}>
          {moment(item?.startDate).format('MMM')}{' '}
          <strong>{moment(item?.startDate).format('DD')}</strong> -{' '}
          {moment(item?.endDate).format('MMM')}{' '}
          <strong>
            {moment(item?.endDate).format('DD')}
            &nbsp;&nbsp;&nbsp;
            {moment(item?.endDate).format('YYYY')}
          </strong>
        </Typography>
        {expand === item?.id && (
          <Typography
            component={'p'}
            sx={{
              textAlign: 'end',
              width: '70%',
            }}>
            Total: <strong>{list.data.length}</strong>
            <span>
              {companyStatus !== 'all' && list.data.length && !list.showBtn ? (
                <Button
                  onClick={(event: any) => {
                    event.stopPropagation();
                    paymentUpdate(companyStatus);
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#BF0000',
                      color: '#FFFFFF',
                    },
                    backgroundColor: '#BF0000',
                    border: '1px solid #E0E0E0',
                    color: '#FFFFFF',
                    fontFamily: 'Red Hat Display Bold',
                    height: '40px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textTransform: 'capitalize',
                  }}>
                  Sync Questions
                </Button>
              ) : (
                ''
              )}
              <Button variant="contained" onClick={submitQuestion}>
                Save Notes
              </Button>
              {companyStatus !== 'all' && list.data.length && !list.showBtn ? (
                <Button variant="contained" onClick={saveChanges}>
                  Submit Questions
                </Button>
              ) : (
                ''
              )}
            </span>
          </Typography>
        )}
        <a
          href={'javascript:void(0);'}
          onClick={() => {
            setPayPeriodData((pre: any) => {
              return { ...pre, id: expand === item.id ? '' : item.id };
            });
          }}
          style={{ margin: 15, marginBottom: 10, marginTop: 10 }}>
          <img
            src={accordionarrow}
            className={expand === item?.id ? 'expand-new' : ''}
          />
        </a>
      </Typography>
      <AccordionDetails>
        <Typography component={'div'} className="common-table">
          {list.loading ? (
            <Typography
              component={'div'}
              sx={{
                justifyContent: 'center',
                paddingBottom: '15px',
                paddingTop: '5px',
                textAlign: 'center',
              }}>
              <CircularProgress sx={{ color: '#340000' }} />
            </Typography>
          ) : (
            <TableContainer
              sx={{
                '& tbody': {
                  '& th': {
                    borderBottom: '2px solid #eee !important',
                  },
                  '& tr, td': {
                    borderBottom: '2px solid #eee !important',
                  },
                },
                '& thead': {
                  '& th': {
                    borderBottom: '2px solid #eee !important',
                  },
                },
                maxHeight: '400px !important',
                overflowY: 'scroll !important',
              }}>
              <Table
                stickyHeader
                sx={{ width: '100%' }}
                aria-label="simple table"
                className="payroll-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Buffer Pending</TableCell>
                    <TableCell align="left">Available To Pay</TableCell>
                    {!list.showBtn && (
                      <TableCell align="left">Pre-Approved</TableCell>
                    )}
                    {list.showBtn && (
                      <TableCell align="left">Approval Status</TableCell>
                    )}
                    <TableCell align="left">Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData} {extraListFun}
                  {list.data && list.data.length ? (
                    <TableRow
                      sx={{
                        border: '0px !important',
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        {!list.showBtn ? (
                          <Typography component={'div'}>
                            <Typography
                              onClick={() => {
                                extraList.push({
                                  extra: true,
                                });
                                setExtraList([...extraList]);
                              }}
                              component={'div'}
                              sx={{
                                color: '#4386F1',
                                cursor: 'pointer',
                                fontFamily: 'Red Hat Display Bold',
                                fontSize: '13px',
                                textDecoration: 'underline',
                              }}>
                              Add User
                            </Typography>
                          </Typography>
                        ) : (
                          <Typography />
                        )}
                        <Typography component={'div'} textAlign={'end'}>
                          <strong style={{ color: 'black' }}>Totals:</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{totalBeforeBalance}</TableCell>
                      <TableCell align="left">{totalAfterBalance}</TableCell>
                      <TableCell align="left" />
                      <TableCell align="left" />
                    </TableRow>
                  ) : (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}>
                      <TableCell
                        component="th"
                        colSpan={5}
                        scope="row"
                        sx={{
                          padding: '5px 0px',
                          textAlign: 'center',
                        }}>
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
});

export default PayPeriodsAccordion;
