import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';

type CheckBoxProps = {
  onChange?: (src: any, removeId?: string) => void;
  value?: any;
  error?: any;
  checkboxArray?: any;
  name?: string;
  multi?: boolean;
  disabled?: boolean;
};

const CheckboxCom = (props: CheckBoxProps): ReactElement => {
  const { checkboxArray, onChange, value, error, name, multi, disabled } =
    props;

  if (multi) {
    return (
      <Typography component={'div'}>
        <FormControl sx={{ display: 'inherit' }}>
          {checkboxArray &&
            checkboxArray.map((row: any, index: number) => {
              const filterIndex = value.findIndex(
                (item: any) =>
                  item.leaveTypeId.toString() === row.value.toString(),
              );
              return (
                <FormControlLabel
                  key={index}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      value.push({ leaveTypeId: e.target.value, reason: '' });
                      onChange(value);
                    } else {
                      const fData = value.find(
                        (item: any) => item.leaveTypeId === e.target.value,
                      );
                      let removeId = '';
                      if (fData) {
                        if (fData.id) {
                          removeId = fData.id;
                        }
                      }
                      const filterArray = value.filter(
                        (item: any) => item.leaveTypeId !== e.target.value,
                      );
                      onChange(filterArray, removeId);
                    }
                  }}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: '#292929',
                      fontFamily: 'Red Hat Display Medium',
                      fontSize: '14px',
                    },
                    '& .MuiSvgIcon-fontSizeMedium': {
                      color: !disabled ? 'green' : 'gray',
                    },
                  }}
                  control={
                    <Checkbox checked={filterIndex === -1 ? false : true} />
                  }
                  label={row.label}
                  value={row.value}
                  name={name}
                  disabled={disabled ? true : false}
                />
              );
            })}
        </FormControl>
        {error && (
          <FormHelperText className="errorText">{error}</FormHelperText>
        )}
      </Typography>
    );
  } else {
    return (
      <Typography component={'div'}>
        <FormControl sx={{ display: 'inherit' }}>
          {checkboxArray &&
            checkboxArray.map((row: any, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      onChange(e.target.value);
                    } else {
                      onChange('');
                    }
                  }}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: '#292929',
                      fontFamily: 'Red Hat Display Medium',
                      fontSize: '14px',
                    },
                    '& .MuiSvgIcon-fontSizeMedium': {
                      color: disabled ? 'gray' : 'green',
                    },
                  }}
                  control={<Checkbox checked={row.value === value} />}
                  label={row.label}
                  value={row.value}
                  name={name}
                  disabled={disabled ? true : false}
                />
              );
            })}
        </FormControl>
        {error && (
          <FormHelperText className="errorText">{error}</FormHelperText>
        )}
      </Typography>
    );
  }
};

export default CheckboxCom;
