import { Typography } from '@mui/material';
// import RadioGroup from '@mui/material/RadioGroup';
import Payroll from 'pages/private/Payroll';
import React from 'react';

export const ConsultantsSub: React.FC<any> = () => {
  return (
    <Typography component={'div'}>
      <Typography component={'div'}>
        <Payroll />
      </Typography>
    </Typography>
  );
};

export default ConsultantsSub;
