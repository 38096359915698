import { yupResolver } from '@hookform/resolvers/yup';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import accordionarrow from 'assets/images/arrow-accordin.svg';
import CheckGreen from 'assets/images/check-alert-green.svg';
import CheckRed from 'assets/images/check-alert-red.svg';
import CloseCircleIcon from 'assets/images/close-circle.svg';
import DollarIcon from 'assets/images/dollar-sign-green.svg';
// import PaidIcon from 'assets/images/status-paid.svg';
import UploadAlert from 'assets/images/upload-alert.svg';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { Controller, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { addNotesForm } from 'types';
import { roundOfData } from 'utils/Config';
import { MASTER_ENDPOINT, TIMESHEET } from 'utils/Constant';
import { addNotesSchema } from 'utils/ValidatorSchema';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  overflowY: 'auto',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 654,
};
const editstyle = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  overflowY: 'auto',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
};
const style1 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  overflowY: 'auto',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};
const style2 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  overflowY: 'auto',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 614,
};
const styleView = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  overflowY: 'auto',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 614,
};
export const TimesheetComponent: React.FC<any> = ({
  consultantId,
  showPaid,
  showApproved,
  isAdminScreen,
  showHours,
  jobWcCode,
}) => {
  const [rows, setRows] = useState<any>([]);
  const [expand, setExpand] = useState<any>();
  const [loading2, setLoading2] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedTimesheetsIndex, setSelectedTimesheetsIndex] = useState(-1);
  const [selectedTimesheetPeriodIndex, setSelectedTimesheetPeriodIndex] =
    useState(-1);
  const [fileUploadLoading, setFileUploadLoading] = useState<any>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<any>(-1);
  const [timeSheetViewSelected, setTimeSheetViewSelected] = useState<any>({});
  const [notesLoading, setNotesLoading] = useState<any>(false);
  const [openViewDocument, setOpenViewDocument] = useState<any>(false);
  const [openAddWeekNotes, setOpenAddWeekNotes] = useState(false);
  const [currentExpandId, setCurrentExpandId] = useState('');
  const [open, setOpen] = useState(false);
  const [editopen, setEditOpen] = React.useState<any>({
    data: {},
    fullVisible: false,
    id: '',
    open: false,
    periodId: '',
  });
  const handleEditOpen = (
    item: any,
    fullVisible: boolean,
    id: any,
    periodId: any,
  ) => {
    setEditOpen({
      data: item,
      fullVisible: fullVisible,
      id: id,
      open: true,
      periodId: periodId,
    });
  };
  const handleEditClose = () => {
    setEditOpen({
      data: {},
      fullVisible: false,
      id: '',
      open: false,
      periodId: '',
    });
  };
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  const submitAdjustHour = () => {
    if (!editopen.fullVisible) {
      const bodyObject = {
        timesheets: [
          {
            adjustedBy: userInfo?.id,
            adjustmentHours: editopen.data.adjustmentHours,
            hourAdjustNotes: editopen.data.hourAdjustNotes,
            id: editopen.data.id,
          },
        ],
      };
      updateData(
        String(editopen.id) + '/' + String(editopen.periodId),
        bodyObject,
        TIMESHEET.TimeSheetsHours,
        true,
      )
        .then((resp: any) => {
          getTimesheetList(currentExpandId, expand);
          handleEditClose();
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const viewFormatSupportedArray = [
    'jpeg',
    'jpg',
    'gif',
    'png',
    'pdf',
    'csv',
    'xslx',
    'docx',
    'xls',
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleAddNotesSubmit,
    control,
    formState,
    reset,
    setValue,
  } = useForm<addNotesForm>({
    defaultValues: {
      note: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(addNotesSchema),
  });

  const toggleAcordion = (index, basePeriodId) => {
    setExpand(index === expand ? -1 : index);
    index === expand ? '' : getTimesheetList(basePeriodId, index);
    setCurrentExpandId(index === expand ? '' : basePeriodId);
  };

  const getTimesheetList = (
    basePeriodId: any,
    index: any,
    rowArr?: any,
    loaderShow?: boolean,
  ) => {
    !loaderShow && setLoading2(true);
    getAllListData(`${TIMESHEET.TimeSheets}/list?id=${String(basePeriodId)}`)
      .then((response: any) => {
        if (response?.data) {
          // response.data.map((row, index) => {
          //   response.data[index].timesheetPeriods = _.sortBy(
          //     row.timesheetPeriods,
          //     function (e) {
          //       return new Date(e?.periodFrom);
          //     },
          //     ['asc'],
          //   );
          // });
          if (rowArr && rowArr.length) {
            const sortedArr = _.sortBy(
              response.data,
              function (e) {
                return new Date(e?.periodFrom);
              },
              ['asc'],
            );
            rowArr[index].timesheetPeriods = sortedArr;
            setRows([...rowArr]);
          } else {
            const sortedArr = _.sortBy(
              response.data,
              function (e) {
                return new Date(e?.periodFrom);
              },
              ['asc'],
            );
            rows[index].timesheetPeriods = sortedArr;
            setRows([...rows]);
          }
        }
        !loaderShow && setLoading2(false);
        setLoader(false);
      })
      .catch((e) => {
        !loaderShow && setLoading2(false);
        setLoader(false);
      });
  };

  const handleOpen = (index, periodIndex) => {
    setSelectedRowIndex(index);
    setSelectedTimesheetPeriodIndex(periodIndex);
    setOpen(true);
  };
  const handleClose1 = () => {
    delete rows[selectedRowIndex]?.files;
    setSelectedTimesheetsIndex(-1);
    setSelectedTimesheetPeriodIndex(-1);
    setSelectedRowIndex(-1);
    setOpen1(false);
  };
  const handleClose = () => {
    setSelectedRowIndex(-1);
    setSelectedTimesheetPeriodIndex(-1);
    setOpen(false);
  };
  const handleCloseAddWeekNotes = () => {
    setSelectedRowIndex(-1);
    setSelectedTimesheetPeriodIndex(-1);
    setOpenAddWeekNotes(false);
  };
  const handleOpen1 = (timesheetsIndex, timesheetPeriodIndex, index) => {
    setSelectedTimesheetsIndex(timesheetsIndex);
    setSelectedTimesheetPeriodIndex(timesheetPeriodIndex);
    setSelectedRowIndex(index);
    setOpen1(true);
  };
  const handleOpenViewDocumentModel = (fileInfoObject) => {
    setTimeSheetViewSelected(fileInfoObject);
    setOpenViewDocument(true);
  };
  const handleRemoveOutSick = (
    sickIndex,
    timesheetPeriodIndex,
    timesheetPeriod,
    index,
  ) => {
    timesheetPeriod[timesheetPeriodIndex].outSicks.splice(sickIndex, 1);
    rows[index].timesheetPeriods = timesheetPeriod;
    setRows([...rows]);
  };
  const handleOpenAddWeekNotes = (timesheetPeriodIndex, index) => {
    if (
      !rows[index].timesheetPeriods[timesheetPeriodIndex]?.notes ||
      rows[index].timesheetPeriods[timesheetPeriodIndex]?.notes.length === 0
    ) {
      rows[index].timesheetPeriods[timesheetPeriodIndex].notes = [
        {
          note: '',
          timesheetPeriodId:
            rows[index].timesheetPeriods[timesheetPeriodIndex].id,
          userId: rows[index].userId,
        },
      ];
    }
    setValue(
      'note',
      rows[index]?.timesheetPeriods[timesheetPeriodIndex]?.notes[0].note,
    );
    setSelectedRowIndex(index);
    setSelectedTimesheetPeriodIndex(timesheetPeriodIndex);
    setRows([...rows]);
    setOpenAddWeekNotes(true);
  };
  const checkPaymentPending = (row) => {
    let isPaymentPending = true;
    row.timesheetPeriods &&
      row.timesheetPeriods.map((data) => {
        if (data.timesheets && data.timesheets.length === 0) {
          return false;
        }
        isPaymentPending = data.timesheets?.find((o) => o?.paid === false);
        if (isPaymentPending != undefined) {
          return false;
        }
      });
    return isPaymentPending === undefined ? false : true;
  };
  const getTotalHours = (row) => {
    let totalHours = 0.0;
    row?.timesheetPeriods.map((data) => {
      data.timesheets.map((timesheet) => {
        totalHours = Number(totalHours) + Number(timesheet.hours);
        totalHours =
          Number(totalHours) +
          (timesheet.adjustmentHours ? Number(timesheet.adjustmentHours) : 0);
      });
      data.outSicks.map((outSick) => {
        totalHours = Number(totalHours) + Number(outSick.hours);
      });
    });
    return Number(totalHours).toFixed(2);
  };
  const capitalizeText = (text) => {
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  };
  const getTotalHoursSingleRow = (timesheetPeriod: any) => {
    if (timesheetPeriod && timesheetPeriod.timesheets) {
      let totalHours = 0.0;
      timesheetPeriod.timesheets.map((timesheet) => {
        totalHours = Number(totalHours) + Number(timesheet.hours);
        totalHours =
          Number(totalHours) +
          (timesheet.adjustmentHours ? Number(timesheet.adjustmentHours) : 0);
      });
      timesheetPeriod.outSicks.map((outSick) => {
        totalHours = Number(totalHours) + Number(outSick.hours);
      });
      return Number(totalHours).toFixed(2);
    }
  };
  const handleSaveChange = (
    index,
    periodIndex,
    fileType = '',
    timesheetFinalized?: boolean,
  ) => {
    // if (checkValidation(rows[index])) {
    const bodyObject = setBodyFormData(
      cloneDeep(rows[index]),
      cloneDeep(rows[index]?.timesheetPeriods[periodIndex]),
      fileType,
      timesheetFinalized,
    );
    setFileUploadLoading(true);
    setLoader(true);
    addNewData(bodyObject, TIMESHEET.TimeSheets, {}, true)
      .then((resp: any) => {
        setTimeout(
          () => getTimesheetList(currentExpandId, expand, [], true),
          3000,
        );
        handleClose1();
        handleClose();
        setFileUploadLoading(false);
        setLoader(false);
      })
      .catch((err: any) => {
        getTimesheetList(currentExpandId, expand, [], true);
        handleClose1();
        handleClose();
        setFileUploadLoading(false);
        setLoader(false);
      });
  };
  const submitaddNotes = (data) => {
    setNotesLoading(true);
    const bodyObject: any = {
      note: data.note,
      // timesheetId:
      //   rows[selectedRowIndex].timesheetPeriods[selectedTimesheetPeriodIndex]
      //     .timesheets[0].id,
      // timesheetPeriodId:
      //   rows[selectedRowIndex].timesheetPeriods[selectedTimesheetPeriodIndex]
      //     .id,
      // userId: rows[selectedRowIndex].userId,
    };
    if (
      rows[selectedRowIndex].timesheetPeriods[selectedTimesheetPeriodIndex]
        .notes &&
      rows[selectedRowIndex].timesheetPeriods[selectedTimesheetPeriodIndex]
        .notes[0].id
    ) {
      updateData(
        String(
          rows[selectedRowIndex].timesheetPeriods[selectedTimesheetPeriodIndex]
            .notes[0].id,
        ),
        bodyObject,
        TIMESHEET.TimeSheetNotesCreate,
        true,
      )
        .then((resp: any) => {
          getTimesheetList(currentExpandId, expand, [], true);
          handleCloseAddWeekNotes();
          reset();
          setNotesLoading(false);
        })
        .catch((err: any) => {
          getTimesheetList(currentExpandId, expand, [], true);
          handleCloseAddWeekNotes();
          reset();
          setNotesLoading(false);
        });
    } else {
      bodyObject.timesheetPeriodId =
        rows[selectedRowIndex].timesheetPeriods[
          selectedTimesheetPeriodIndex
        ].id;
      bodyObject.userId = rows[selectedRowIndex].userId;
      addNewData(bodyObject, TIMESHEET.TimeSheetNotesCreate, {}, true)
        .then((resp: any) => {
          getTimesheetList(currentExpandId, expand, [], true);
          handleCloseAddWeekNotes();
          reset();
          setNotesLoading(false);
        })
        .catch((err: any) => {
          getTimesheetList(currentExpandId, expand, [], true);
          handleCloseAddWeekNotes();
          reset();
          setNotesLoading(false);
        });
    }
    // rows[selectedRowIndex].timesheetPeriods.notes = [bodyObject];
    // setRows([...rows]);
  };
  const handleChangeInput = (
    e,
    timesheetsIndex,
    timesheetPeriodIndex,
    timesheetPeriod,
    index,
    type = 'timesheets',
  ) => {
    const { value } = e.target;
    const tempValue = value.replace(/[^\d.]/g, '');
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (!tempValue.match(rgx)) {
      return false;
    }
    // Allow Only the Two digit
    const checkTwoDigit = tempValue.split('.');
    if (checkTwoDigit[1] && checkTwoDigit[1].length > 2) {
      return false;
    }
    if (type === 'timesheets') {
      timesheetPeriod[timesheetPeriodIndex].timesheets[timesheetsIndex].hours =
        tempValue ? tempValue : null;
      // timesheetPeriod[index].timesheets[timesheetsIndex].errors = {};
      // if (value === '') {
      //   timesheetPeriod[index].timesheets[timesheetsIndex].errors.message =
      //     'Hours is required';
      // }
    } else {
      timesheetPeriod[timesheetPeriodIndex].outSicks[timesheetsIndex].hours =
        tempValue ? tempValue : null;
      // timesheetPeriod[index].outSicks[timesheetsIndex].errors = {};
      // if (value === '') {
      //   timesheetPeriod[index].outSicks[timesheetsIndex].errors.message =
      //     'Hours is required';
      // }
    }
    rows[index].timesheetPeriods = timesheetPeriod;
    setRows([...rows]);
  };

  const [loader, setLoader] = React.useState<boolean>(false);
  const [loader1, setLoader1] = React.useState<boolean>(true);

  const getTimesheets = () => {
    if (loader1) {
      setLoader1(false);
      setLoader(true);
    }
    getAllListData(
      `${TIMESHEET.TimeSheets}/base?userId=${String(consultantId)}`,
    )
      .then(async (response: any) => {
        // response.data[0].isTimesheetFinalized = true;
        // response.data[0].clientId = 'c23b663c-a081-4098-a7d7-d102cd573437';
        // response.data[0].timesheetPeriods[0].clientId =
        //   'c23b663c-a081-4098-a7d7-d102cd573437';
        // response.data[0].timesheetPeriods[0].clientId =
        //   'c23b663c-a081-4098-a7d7-d102cd573437';
        // response.data[0].timesheetPeriods[1] = {
        //   id: 'db566739-f20b-4557-8032-8204e99fd9ae',
        //   notes: [
        //     {
        //       note: 'Test Notes Added',
        //     },
        //   ],
        //   outSicks: [],
        //   periodFrom: '2022-08-10T00:00:00.000Z',
        //   periodTo: '2022-09-10T00:00:00.000Z',
        //   timesheets: [
        //     {
        //       adjustmentAmount: null,
        //       adjustmentHours: '0.00',
        //       adjustmentRate: '0.00',
        //       adminFee: '0.00',
        //       currentRate: '0.00',
        //       discount: '0.00',
        //       hours: '6.00',
        //       id: '4f18354b-ef6a-4e00-8843-4ba723cc0f82',
        //       paid: false,
        //       paidOn: null,
        //       projectId: '1c4ef3d4-6638-4010-888f-38ae8caadf1a',
        //     },
        //   ],
        // };
        // response.data[0].timesheetPeriods[0].timesheets[1] = {
        //   adjustmentAmount: '2.00',
        //   adjustmentHours: '1.00',
        //   adjustmentRate: '2.00',
        //   adminFee: '4.00',
        //   currentRate: '20.00',
        //   discount: '3.00',
        //   hours: '14.00',
        //   id: '14cb0a4d-a70c-42c2-91b1-a8006628652c',
        //   paid: false,
        //   paidOn: '2022-06-19T18:30:00.000Z',
        //   projectId: '1c4ef3d4-6638-4010-888f-38ae8caadf1a',
        // };
        if (response?.data) {
          response.data.map((row, index) => {
            response.data[index].timesheetPeriods = _.sortBy(
              row.timesheetPeriods,
              function (e) {
                return new Date(e?.periodFrom);
              },
              ['asc'],
            );
          });
          // setRows([...response.data]);
          setExpand(0);
          setCurrentExpandId(response?.data[0].id);
          await getTimesheetList(response?.data[0].id, 0, response?.data);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const setBodyFormData = (
    row: any,
    periodRow,
    fileType = '',
    finalizedTimeSheet,
  ) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const tagUrl = `${window.location.origin}/admin/consultants/profile/${row?.userId}`;
    const postFormData = new FormData();
    let isParentTimesheetFinalized = true;
    postFormData.append('userId', row.userId);
    postFormData.append('tsPageURL', tagUrl);
    postFormData.append('timesheetFinalizedBy', userInfo?.id);
    postFormData.append('periodFrom', row.periodFrom);
    postFormData.append('periodTo', row.periodTo);
    postFormData.append('id', row.id);
    postFormData.append(
      'payPeriodId',
      row?.payPeriod ? row?.payPeriod?.id : '',
    );

    const setTimesheetPeriods = [];
    // row.timesheetPeriods.map((timesheetPeriod, index) => {
    const timesheetPeriodPushArray: any = {};
    timesheetPeriodPushArray.periodFrom = periodRow.periodFrom;
    timesheetPeriodPushArray.periodTo = periodRow.periodTo;
    timesheetPeriodPushArray.isTimesheetFinalized =
      finalizedTimeSheet && !_.isUndefined(finalizedTimeSheet)
        ? 'true'
        : periodRow?.isTimesheetFinalized
        ? String(periodRow.isTimesheetFinalized)
        : 'false';
    timesheetPeriodPushArray.timesheetFinalizedBy = userInfo?.id;
    if (periodRow?.id) {
      timesheetPeriodPushArray.id = periodRow.id;
    }

    timesheetPeriodPushArray.timesheetOutSick = periodRow.outSicks;
    periodRow.timesheets.map((timesheet) => {
      delete timesheet['project'];
      timesheet['timesheetFiles'] = [];
      timesheet.hours = parseFloat(Number(timesheet.hours).toFixed(2));
      timesheet.adjustmentHours = parseFloat(
        Number(timesheet.adjustmentHours).toFixed(2),
      );
      timesheet.currentRate = parseFloat(
        Number(timesheet.currentRate).toFixed(2),
      );
      timesheet.adjustmentAmount = parseFloat(
        Number(timesheet.adjustmentAmount).toFixed(2),
      );
      timesheet.discount = parseFloat(Number(timesheet.discount).toFixed(2));
      timesheet.adjustmentRate = parseFloat(
        Number(timesheet.adjustmentRate).toFixed(2),
      );
      timesheet.adminFee = parseFloat(Number(timesheet.adminFee).toFixed(2));
    });
    timesheetPeriodPushArray.timesheets = periodRow.timesheets;
    setTimesheetPeriods.push(timesheetPeriodPushArray);
    // });

    row.timesheetPeriods.filter((timesheetPeriod: any, index) => {
      if (
        'isTimesheetFinalized' in timesheetPeriod &&
        timesheetPeriod.isTimesheetFinalized === false
      ) {
        isParentTimesheetFinalized = false;
        return;
      } else {
        isParentTimesheetFinalized = false;
        return;
      }
    });

    postFormData.append(
      'isTimesheetFinalized',
      String(isParentTimesheetFinalized),
    );

    if (row['files']) {
      row['files'].map((fileItem: any) => {
        const uniqueId = Math.floor(
          Math.random() * Math.floor(Math.random() * Date.now()),
        );
        if (!periodRow.timesheets[selectedTimesheetsIndex].timesheetFiles) {
          periodRow.timesheets[selectedTimesheetsIndex].timesheetFiles = [];
        }
        periodRow.timesheets[selectedTimesheetsIndex].timesheetFiles.push({
          fileType: fileType,
          fileUniqueId: uniqueId.toString(),
        });
        postFormData.append(`${uniqueId}`, fileItem);
      });
    }
    postFormData.append(
      'timesheetPeriods',
      JSON.stringify(setTimesheetPeriods),
    );
    return postFormData;
  };
  const addNewOutSick = (timesheetPeriod, timesheetPeriodIndex, index) => {
    timesheetPeriod[timesheetPeriodIndex].outSicks.push({
      hours: 0.0,
    });
    rows[index].timesheetPeriods = timesheetPeriod;
    setRows([...rows]);
  };
  const onSubmit = (data) => {
    handleUploadDocument(data.fileType);
  };
  const handleCloseOpenViewDocument = () => {
    setTimeSheetViewSelected({});
    setOpenViewDocument(false);
  };
  const handleUploadDocument = (fileType) => {
    handleSaveChange(selectedRowIndex, selectedTimesheetPeriodIndex, fileType);
  };
  const handleFinalizeTimesheet = () => {
    handleSaveChange(selectedRowIndex, selectedTimesheetPeriodIndex, '', true);
  };
  const saveTimesheet = (row: any, periodIndex: any) => {
    handleSaveChange(row, periodIndex);
  };
  useEffect(() => {
    if (consultantId != undefined && consultantId != null) {
      getTimesheets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantId]);

  const cosultantListDownload = () => {
    // console.log({ timeSheetViewSelected });
    // const firstName = timeSheetViewSelected?.firstName
    //   ? timeSheetViewSelected?.firstName.replace(' ', '-')
    //   : '';
    // const lastName = timeSheetViewSelected?.lastName
    //   ? timeSheetViewSelected.lastName.replace(' ', '-')
    //   : '';
    // const ultraStaffDbName = timeSheetViewSelected.ultraStaffDbName
    //   ? timeSheetViewSelected.ultraStaffDbName.replace(' ', '-')
    //   : '';
    // const periodToDate = moment(timeSheetViewSelected.dateTo).format('DD');
    // const periodToMonth = moment(timeSheetViewSelected.dateTo).format('MM');
    // const periodToMonthName = moment(timeSheetViewSelected.dateTo).format(
    //   'MMMM',
    // );
    // const periodToYear = moment(timeSheetViewSelected.dateTo).format('YYYY');
    // const periodFromDate = moment(timeSheetViewSelected.dateFrom).format('DD');
    // const periodFromMonth = moment(timeSheetViewSelected.dateFrom).format('MM');
    // const periodFromYear = moment(timeSheetViewSelected.dateFrom).format(
    //   'YYYY',
    // );
    // let pathName = `${String(lastName)}.${String(firstName)}`;
    // if (timeSheetViewSelected.collectionFrequency) {
    //   if (timeSheetViewSelected.collectionFrequency === 'W') {
    //     // if (timeSheetViewSelected.projectCount === '1') {
    //     //   pathName = `${String(lastName)}.${String(firstName)}.${String(
    //     //     periodToYear,
    //     //   )}.${String(periodToMonth)}.${String(periodToDate)}`;
    //     // } else {
    //     pathName = `${String(lastName)}.${String(firstName)}.${String(
    //       ultraStaffDbName,
    //     )}.${String(periodToYear)}.${String(periodToMonth)}.${String(
    //       periodToDate,
    //     )}`;
    //     // }
    //   } else if (timeSheetViewSelected.collectionFrequency === 'M') {
    //     // if (timeSheetViewSelected.projectCount === '1') {
    //     //   pathName = `${String(lastName)}.${String(firstName)}.${String(
    //     //     periodToYear,
    //     //   )}.${String(periodToMonthName)}`;
    //     // } else {
    //     pathName = `${String(lastName)}.${String(firstName)}.${String(
    //       ultraStaffDbName,
    //     )}.${String(periodToYear)}.${String(periodToMonthName)}`;
    //     // }
    //   } else if (
    //     timeSheetViewSelected.collectionFrequency === 'BM' ||
    //     timeSheetViewSelected.collectionFrequency === 'W/M' ||
    //     timeSheetViewSelected.collectionFrequency === 'BW'
    //   ) {
    //     // if (timeSheetViewSelected.projectCount === '1') {
    //     //   pathName = `${String(lastName)}.${String(firstName)}.${String(
    //     //     periodFromYear,
    //     //   )}.${String(periodFromMonth)}.${String(
    //     //     periodFromDate,
    //     //   )}-${String(periodToMonth)}.${String(periodToDate)}`;
    //     // } else {
    //     pathName = `${String(lastName)}.${String(firstName)}.${String(
    //       ultraStaffDbName,
    //     )}.${String(periodFromYear)}.${String(periodFromMonth)}.${String(
    //       periodFromDate,
    //     )}-${String(periodToMonth)}.${String(periodToDate)}`;
    //     // }
    //   } else {
    //     if (timeSheetViewSelected.projectCount === '1') {
    //       pathName = `${String(lastName)}.${String(firstName)}.${String(
    //         periodFromYear,
    //       )}.${String(periodFromMonth)}.${String(periodFromDate)}-${String(
    //         periodToMonth,
    //       )}.${String(periodToDate)}`;
    //     } else {
    //       pathName = `${String(lastName)}.${String(firstName)}.${String(
    //         ultraStaffDbName,
    //       )}.${String(periodFromYear)}.${String(periodFromMonth)}.${String(
    //         periodFromDate,
    //       )}-${String(periodToMonth)}.${String(periodToDate)}`;
    //     }
    //   }
    // }
    const cYear = moment(timeSheetViewSelected.dateTo).format('YYYY');
    const cAt = timeSheetViewSelected.dateTo;
    const pathName = `${String(timeSheetViewSelected.lastName)}.${String(
      timeSheetViewSelected.firstName,
    )}.${String(
      timeSheetViewSelected.customerName
        ? timeSheetViewSelected.customerName
        : '',
    )}.${String(cYear)}.${String(moment(cAt).format('MM'))}.${String(
      moment(cAt).format('DD'),
    )}`;
    const downloadExtension = timeSheetViewSelected?.extension;
    if (downloadExtension === 'pdf') {
      saveAs(
        `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.Files}/${String(
          timeSheetViewSelected?.id,
        )}`,
        `${pathName}.${String(downloadExtension)}`,
        { autoBom: true },
      );
    } else if (
      timeSheetViewSelected?.extension == 'jpg' ||
      timeSheetViewSelected?.extension == 'png' ||
      timeSheetViewSelected?.extension == 'jpeg' ||
      timeSheetViewSelected?.extension == 'svg'
    ) {
      const unit = 'pt';
      const size = 'A2'; // Use A1, A2, A3 or A4
      const orientation = 'portrait'; // portrait or landscape
      const doc = new jsPDF(orientation, unit, size);
      const img = new Image();
      const src = `${process.env.REACT_APP_APIURL}${
        MASTER_ENDPOINT.Files
      }/${String(timeSheetViewSelected?.id)}`;
      img.src = src;
      doc.addImage(img, timeSheetViewSelected?.extension, 250, 350, 700, 400); // 'left-marigin','top-marigin','width','height')
      doc.save(`${pathName}.${String(downloadExtension)}`);
    } else {
      saveAs(
        `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.Files}/${String(
          timeSheetViewSelected?.id,
        )}`,
        `${pathName}.${String(downloadExtension)}`,
        { autoBom: true },
      );
    }
  };

  const showTimesheetAprrovedData = (
    index: number,
    timesheetPeriodIndex: number,
    timesheetPeriod: any,
    row: any,
    h: any,
  ) => {
    return (
      <>
        <Box
          sx={{
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'space-between',
            m: 0,
            p: 0,
            pb: 1,
          }}>
          <Typography>
            {moment(timesheetPeriod?.periodFrom).format('MMM')}{' '}
            <strong>
              {moment(timesheetPeriod?.periodFrom).format('DD')} -{' '}
              {moment(timesheetPeriod?.periodTo).format('DD')}
            </strong>
          </Typography>
          <Typography component={'div'} display="flex" alignItems="center">
            <Typography
              sx={{
                paddingRight: isAdminScreen ? '5px' : '5px',
              }}>
              Total: <strong>{h}</strong>
            </Typography>
            {isAdminScreen && (
              <Typography
                component={'div'}
                className="finalize-approve"
                sx={{ paddingBottom: 'unset !important' }}>
                <Button
                  onClick={() => {
                    saveTimesheet(index, timesheetPeriodIndex);
                  }}
                  variant="contained">
                  Approve Timesheet
                </Button>
              </Typography>
            )}
          </Typography>
        </Box>
        <TableContainer component={'div'}>
          <Table sx={{ Width: '100%' }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: 'inherit !important',
              }}>
              <TableRow>
                <TableCell
                  sx={{
                    maxWidth: '200px !important',
                    width: '200px !important',
                  }}>
                  Project Worked On:
                </TableCell>
                <TableCell align="left">Paid:</TableCell>
                <TableCell align="left">Hours:</TableCell>
                <TableCell align="left">Adj. Hours:</TableCell>
                <TableCell
                  sx={{
                    maxWidth: '170px !important',
                    width: '170px !important',
                  }}>
                  Timesheet:
                </TableCell>
                <TableCell align="left">Current Rate:</TableCell>
                <TableCell align="left">Adj. Amount:</TableCell>
                <TableCell align="left">Discount:</TableCell>
                <TableCell align="left">Adj. Rate</TableCell>
                <TableCell align="left">Admin Fee:</TableCell>
                {showApproved && <TableCell align="left">Approved:</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {timesheetPeriod?.timesheets.map((timesheet, timesheetIndex) => {
                return (
                  <TableRow className="active-blue" key={timesheetIndex}>
                    <TableCell component="th" scope="row">
                      {timesheet?.project?.project}
                    </TableCell>
                    <TableCell align="left">
                      {timesheet?.paid ? (
                        <img src={CheckGreen} />
                      ) : (
                        <img src={CheckRed} />
                      )}
                    </TableCell>
                    <TableCell align="left">{timesheet?.hours}</TableCell>
                    <TableCell align="left">
                      {isAdminScreen ? (
                        <Button
                          variant="text"
                          onClick={(e) => {
                            handleEditOpen(
                              timesheet,
                              false,
                              row?.id,
                              timesheetPeriod?.id,
                            );
                          }}
                          sx={{
                            color: '#005ebf',
                            fontFamily: 'Red Hat Display Bold !important',
                            fontSize: '14px !important',
                            margin: '0px !important',
                            minWidth: '0px !important',
                            padding: '0px !important',
                            textDecoration: 'underline !important',
                            textTransform: 'capitalize',
                          }}>
                          {timesheet?.adjustmentHours !== '0.00'
                            ? parseFloat(timesheet?.adjustmentHours)
                            : 'Edit'}
                        </Button>
                      ) : (
                        timesheet?.adjustmentHours !== '0.00' &&
                        parseFloat(timesheet?.adjustmentHours)
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {timesheet?.timesheetFiles?.map(
                        (timesheetFile, timesheetFileIndex) => (
                          <Typography
                            component={'a'}
                            sx={{
                              marginRight: '10px !important',
                              textDecoration: 'underline !important',
                            }}
                            className="pointer"
                            onClick={(e) => {
                              if (
                                viewFormatSupportedArray.indexOf(
                                  timesheetFile.extension,
                                ) > -1
                              ) {
                                timesheetFile.customerName =
                                  timesheet?.project?.customerName;
                                timesheetFile.firstName = row?.user?.firstName;
                                timesheetFile.lastName = row?.user?.lastName;
                                timesheetFile.ultraStaffDbName =
                                  row?.user?.ultraStaffDbName;
                                timesheetFile.collectionFrequency =
                                  timesheet?.projectConsultant?.collectionFrequency;
                                timesheetFile.dateFrom =
                                  timesheetPeriod?.periodFrom;
                                timesheetFile.dateTo =
                                  timesheetPeriod?.periodTo;
                                handleOpenViewDocumentModel(timesheetFile);
                              }
                            }}
                            href={
                              viewFormatSupportedArray.indexOf(
                                timesheetFile.extension,
                              ) === -1 &&
                              `${process.env.REACT_APP_APIURL}${
                                MASTER_ENDPOINT.Files
                              }/${String(timesheetFile?.id)}`
                            }
                            download={
                              viewFormatSupportedArray.indexOf(
                                timesheetFile.extension,
                              ) === -1 &&
                              `${process.env.REACT_APP_APIURL}${
                                MASTER_ENDPOINT.Files
                              }/${String(timesheetFile?.id)}`
                            }
                            target="_blank"
                            key={timesheetFileIndex}>
                            {timesheetFile?.timesheetFileType &&
                              capitalizeText(timesheetFile?.timesheetFileType)}
                            {timesheetFileIndex !=
                              timesheet?.timesheetFiles.length - 1 && ','}
                          </Typography>
                        ),
                      )}
                      <Button
                        variant="text"
                        onClick={(e) => {
                          handleOpen1(
                            timesheetIndex,
                            timesheetPeriodIndex,
                            index,
                          );
                        }}
                        sx={{
                          color: 'red',
                          fontFamily: 'Red Hat Display Bold !important',
                          fontSize: '14px !important',
                          margin: '0px !important',
                          minWidth: '0px !important',
                          padding: '0px !important',
                          textDecoration: 'underline !important',
                          textTransform: 'capitalize',
                        }}>
                        Upload
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      {roundOfData(timesheet?.currentRate)}
                    </TableCell>
                    <TableCell align="left">
                      {roundOfData(timesheet?.adjustmentAmount)}
                    </TableCell>
                    <TableCell align="left">
                      {roundOfData(timesheet?.discount)}
                    </TableCell>
                    <TableCell align="left">
                      {roundOfData(timesheet?.adjustmentRate)}
                    </TableCell>
                    <TableCell align="left">
                      {roundOfData(timesheet?.adminFee)}
                    </TableCell>
                    {showApproved && (
                      <TableCell align="left">
                        <Checkbox
                          {...label}
                          disabled={!timesheetPeriod?.isTimesheetFinalized}
                          checked={timesheet?.approved}
                          onChange={(e) => {
                            timesheet.approved = e.target.checked;
                            timesheet.approvedBy = userInfo?.id;
                            rows[index].timesheetPeriods[
                              timesheetPeriodIndex
                            ].timesheets[timesheetIndex] = timesheet;
                            setRows([...rows]);
                          }}
                          sx={{
                            '& .Mui-checked MuiCheckbox-root': {
                              padding: '0px !important',
                            },
                            '&.Mui-checked': {
                              color: '#1D9900',
                            },
                            color: '#c9c6c6',
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {timesheetPeriod?.outSicks.map((outSick, outSickIndex) => {
                return (
                  <TableRow className="active-blue" key={outSickIndex}>
                    <TableCell component="th" scope="row">
                      Out Sick
                    </TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">{outSick?.hours}</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="left">-</TableCell>
                  </TableRow>
                );
              })}
              {row?.timesheetPeriods[timesheetPeriodIndex]?.notes[0]?.id &&
                timesheetPeriod?.notes.map((note, noteIndex) => (
                  <TableRow key={noteIndex}>
                    <TableCell
                      component="th"
                      colSpan={11}
                      scope="row"
                      sx={{ padding: '5px 0px' }}>
                      <Typography component={'div'} className="notices-details">
                        <Typography>{note.note}</Typography>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component="div" className="container-block Adjustment">
        <Typography sx={{ p: 2 }} component={'div'} className="common-accordin">
          {rows.length
            ? rows.map((row: any, index: number) => {
                let currentPeriod = moment(
                  moment().format('YYYY-MM-DD'),
                ).isBetween(
                  moment(row.periodFrom),
                  moment(row.periodTo),
                  undefined,
                  '[]',
                );
                const afterPeriod = moment(moment(row.periodFrom)).isAfter(
                  moment().format('YYYY-MM-DD'),
                );
                if (!row?.isTimesheetFinalized) {
                  currentPeriod = true;
                }
                if (currentPeriod || afterPeriod) {
                  return (
                    <Accordion
                      className="timeline"
                      expanded={expand == index ? true : false}
                      key={index}>
                      <Typography
                        component={'div'}
                        className={
                          checkPaymentPending(row)
                            ? 'accordin-details-pending'
                            : 'accordin-details1'
                        }>
                        <Typography component={'p'} sx={{ padding: '20px' }}>
                          {moment(row?.periodFrom).format('MMMM')}{' '}
                          <strong>
                            {moment(row?.periodFrom).format('DD')} -{' '}
                            {moment(row?.periodTo).format('DD')}
                          </strong>
                        </Typography>

                        {expand === index && (
                          <Typography
                            component={'p'}
                            className="accord-active"
                            sx={{
                              fontFamily: 'Red Hat Display Medium',
                              textAlign: 'end',
                              width: '60%',
                            }}>
                            Total Hours:{' '}
                            <span
                              style={{ fontFamily: 'Red Hat Display Bold' }}>
                              {getTotalHours(row)}
                            </span>
                          </Typography>
                        )}
                        <a
                          href={'javascript:void(0);'}
                          onClick={(e) => toggleAcordion(index, row?.id)}
                          style={{
                            margin: 15,
                            marginBottom: 10,
                            marginTop: 10,
                          }}>
                          <img
                            src={accordionarrow}
                            className={expand == index ? 'expand-new' : ''}
                          />
                        </a>
                      </Typography>
                      <AccordionDetails>
                        {expand === index && !loading2 ? (
                          row?.timesheetPeriods &&
                          row?.timesheetPeriods.map(
                            (timesheetPeriod, timesheetPeriodIndex) => {
                              let h = 0.0;
                              timesheetPeriod?.timesheets &&
                                timesheetPeriod?.timesheets.map((timesheet) => {
                                  h = h + parseFloat(timesheet.hours);
                                  h =
                                    h +
                                    (timesheet.adjustmentHours
                                      ? parseFloat(timesheet.adjustmentHours)
                                      : 0);
                                });
                              timesheetPeriod?.outSicks &&
                                timesheetPeriod?.outSicks.map((outSick) => {
                                  h = h + parseFloat(outSick.hours);
                                });
                              return timesheetPeriod.isTimesheetFinalized ? (
                                <Typography
                                  component={'div'}
                                  className="timesheet-table"
                                  sx={{
                                    marginBottom: '15px !important',
                                  }}
                                  key={timesheetPeriodIndex}>
                                  {showTimesheetAprrovedData(
                                    index,
                                    timesheetPeriodIndex,
                                    timesheetPeriod,
                                    row,
                                    h,
                                  )}
                                </Typography>
                              ) : (
                                <Typography
                                  component={'div'}
                                  className="status-details"
                                  key={timesheetPeriodIndex}>
                                  <Typography
                                    className={
                                      isAdminScreen
                                        ? 'timesheet-details timesheet-details-admin'
                                        : 'timesheet-details'
                                    }>
                                    <Typography
                                      component={'div'}
                                      className="finalize-approve">
                                      {isAdminScreen ? (
                                        <Typography
                                          component={'a'}
                                          className="pointer"
                                          onClick={(e) =>
                                            handleSaveChange(
                                              index,
                                              timesheetPeriodIndex,
                                            )
                                          }>
                                          Save Changes
                                        </Typography>
                                      ) : (
                                        <Typography
                                          component={'a'}
                                          className="pointer"
                                          onClick={(e) =>
                                            handleSaveChange(
                                              index,
                                              timesheetPeriodIndex,
                                            )
                                          }>
                                          Save Changes
                                        </Typography>
                                      )}
                                      {isAdminScreen ? (
                                        <Button
                                          onClick={() => {
                                            saveTimesheet(
                                              index,
                                              timesheetPeriodIndex,
                                            );
                                          }}
                                          variant="contained">
                                          Approve Timesheet
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={(e) => {
                                            handleOpen(
                                              index,
                                              timesheetPeriodIndex,
                                            );
                                          }}
                                          variant="contained">
                                          Finalize Timesheet
                                        </Button>
                                      )}
                                    </Typography>
                                    <Typography component={'div'}>
                                      <Box
                                        sx={{
                                          bgcolor: 'background.paper',
                                          borderRadius: 1,
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          m: 0,
                                          p: 0,
                                        }}>
                                        <Typography
                                          sx={{
                                            margin: '0 10px 0',
                                            width: '10%',
                                          }}>
                                          <em>
                                            {moment(
                                              timesheetPeriod?.periodFrom,
                                            ).format('MMM')}
                                          </em>{' '}
                                          {moment(
                                            timesheetPeriod?.periodFrom,
                                          ).format('DD')}{' '}
                                          -{' '}
                                          {moment(
                                            timesheetPeriod?.periodTo,
                                          ).format('DD')}
                                          {/* {index % 2 === 0 ? ' 1 - 15 ' : ' 15 - 31 '} */}
                                        </Typography>
                                        <Typography sx={{ width: '90%' }}>
                                          <TableContainer component={'div'}>
                                            <Table
                                              sx={{ Width: '100%' }}
                                              aria-label="simple table">
                                              <TableHead
                                                sx={{
                                                  backgroundColor:
                                                    'inherit !important',
                                                }}>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{ width: '35%' }}>
                                                    Project:
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{ width: '15%' }}
                                                    align="center">
                                                    Hours:
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{ width: '30%' }}
                                                    align="left">
                                                    Timesheet:
                                                  </TableCell>
                                                  {showApproved && (
                                                    <TableCell align="left">
                                                      Approved:
                                                    </TableCell>
                                                  )}
                                                  <TableCell
                                                    sx={{ width: '18%' }}
                                                    align="right"
                                                    className="hours-timesheet">
                                                    {showHours && (
                                                      <>
                                                        Hours:{' '}
                                                        <span>
                                                          {getTotalHoursSingleRow(
                                                            timesheetPeriod,
                                                          )}
                                                        </span>
                                                      </>
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {timesheetPeriod?.timesheets &&
                                                  timesheetPeriod?.timesheets.map(
                                                    (
                                                      timesheet,
                                                      timesheetIndex,
                                                    ) => (
                                                      <TableRow
                                                        className={
                                                          (!timesheet?.timesheetFiles ||
                                                            timesheet
                                                              ?.timesheetFiles
                                                              .length === 0) &&
                                                          parseInt(
                                                            timesheet.hours,
                                                          ) === 0
                                                            ? 'active-gray'
                                                            : timesheet.timesheetFiles
                                                            ? 'active-blue'
                                                            : 'active-yellow'
                                                        }
                                                        key={timesheetIndex}>
                                                        <TableCell
                                                          component="th"
                                                          scope="row">
                                                          {/* {timesheet.projectId} */}
                                                          <Stack
                                                            alignItems="center"
                                                            direction="row"
                                                            display="flex"
                                                            spacing={1}>
                                                            <Typography
                                                              sx={{
                                                                width: '70%',
                                                              }}
                                                              component={'p'}>
                                                              {
                                                                timesheet
                                                                  ?.project
                                                                  ?.project
                                                              }
                                                            </Typography>
                                                            {/* {timesheet.paid && ( */}
                                                            {showPaid &&
                                                              timesheet.paid && (
                                                                <Typography
                                                                  alignItems="center"
                                                                  display="flex"
                                                                  justifyContent="flex-end"
                                                                  sx={{
                                                                    width:
                                                                      '30%',
                                                                  }}
                                                                  component={
                                                                    'p'
                                                                  }>
                                                                  <Chip
                                                                    className="chip-status-green"
                                                                    label={
                                                                      <Typography
                                                                        component={
                                                                          'div'
                                                                        }
                                                                        className="whiteText">
                                                                        <img
                                                                          src={
                                                                            DollarIcon
                                                                          }
                                                                          alt="Paid"
                                                                          width={
                                                                            20
                                                                          }
                                                                        />{' '}
                                                                        <span>
                                                                          Paid
                                                                        </span>
                                                                      </Typography>
                                                                    }
                                                                    color="success"
                                                                  />
                                                                </Typography>
                                                              )}
                                                            {/* )} */}
                                                          </Stack>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          <CustomInput
                                                            placeHolder=""
                                                            value={
                                                              timesheet.hours
                                                            }
                                                            name={
                                                              'time-sheet-hours'
                                                            }
                                                            type={'text'}
                                                            disabled={
                                                              row?.isTimesheetFinalized
                                                            }
                                                            onChange={(
                                                              e: any,
                                                            ) => {
                                                              handleChangeInput(
                                                                e,
                                                                timesheetIndex,
                                                                timesheetPeriodIndex,
                                                                row.timesheetPeriods,
                                                                index,
                                                                'timesheets',
                                                              );
                                                            }}
                                                            error={
                                                              timesheet.errors
                                                                ?.message
                                                            }
                                                            // disabled={
                                                            //   row.isTimesheetFinalized
                                                            // }
                                                            inputProps={{
                                                              inputProps: {
                                                                min: 0,
                                                              },
                                                            }}
                                                          />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                          {timesheet?.timesheetFiles?.map(
                                                            (
                                                              timesheetFile,
                                                              timesheetFileIndex,
                                                            ) => (
                                                              <Typography
                                                                component={'a'}
                                                                className="pointer"
                                                                onClick={(
                                                                  e,
                                                                ) => {
                                                                  if (
                                                                    viewFormatSupportedArray.indexOf(
                                                                      timesheetFile.extension,
                                                                    ) > -1
                                                                  ) {
                                                                    timesheetFile.customerName =
                                                                      timesheet?.project?.customerName;
                                                                    timesheetFile.firstName =
                                                                      row?.user?.firstName;
                                                                    timesheetFile.lastName =
                                                                      row?.user?.lastName;
                                                                    timesheetFile.ultraStaffDbName =
                                                                      row?.user?.ultraStaffDbName;
                                                                    timesheetFile.collectionFrequency =
                                                                      timesheet?.projectConsultant?.collectionFrequency;
                                                                    timesheetFile.dateFrom =
                                                                      timesheetPeriod?.periodFrom;
                                                                    timesheetFile.dateTo =
                                                                      timesheetPeriod?.periodTo;
                                                                    handleOpenViewDocumentModel(
                                                                      timesheetFile,
                                                                    );
                                                                  }
                                                                }}
                                                                href={
                                                                  viewFormatSupportedArray.indexOf(
                                                                    timesheetFile.extension,
                                                                  ) === -1 &&
                                                                  `${
                                                                    process.env
                                                                      .REACT_APP_APIURL
                                                                  }${
                                                                    MASTER_ENDPOINT.Files
                                                                  }/${String(
                                                                    timesheetFile?.id,
                                                                  )}`
                                                                }
                                                                download={
                                                                  viewFormatSupportedArray.indexOf(
                                                                    timesheetFile.extension,
                                                                  ) === -1 &&
                                                                  `${
                                                                    process.env
                                                                      .REACT_APP_APIURL
                                                                  }${
                                                                    MASTER_ENDPOINT.Files
                                                                  }/${String(
                                                                    timesheetFile?.id,
                                                                  )}`
                                                                }
                                                                target="_blank"
                                                                key={
                                                                  timesheetFileIndex
                                                                }>
                                                                {timesheetFile?.timesheetFileType &&
                                                                  capitalizeText(
                                                                    timesheetFile?.timesheetFileType,
                                                                  )}
                                                                {timesheetFileIndex !=
                                                                  timesheet
                                                                    ?.timesheetFiles
                                                                    .length -
                                                                    1 && ','}
                                                              </Typography>
                                                            ),
                                                          )}
                                                        </TableCell>
                                                        {showApproved && (
                                                          <TableCell align="left">
                                                            <Checkbox
                                                              {...label}
                                                              disabled={
                                                                !timesheetPeriod?.isTimesheetFinalized
                                                              }
                                                              checked={
                                                                timesheet?.approved
                                                              }
                                                              onChange={(e) => {
                                                                timesheet.approved =
                                                                  e.target.checked;
                                                                timesheet.approvedBy =
                                                                  userInfo?.id;
                                                                rows[
                                                                  index
                                                                ].timesheetPeriods[
                                                                  timesheetPeriodIndex
                                                                ].timesheets[
                                                                  timesheetIndex
                                                                ] = timesheet;
                                                                setRows([
                                                                  ...rows,
                                                                ]);
                                                              }}
                                                              sx={{
                                                                '&.Mui-checked':
                                                                  {
                                                                    color:
                                                                      '#1D9900',
                                                                  },
                                                                color:
                                                                  '#c9c6c6',
                                                              }}
                                                            />
                                                          </TableCell>
                                                        )}
                                                        <TableCell align="right">
                                                          {!timesheet.approved &&
                                                            (!timesheet?.timesheetFiles ||
                                                              timesheet
                                                                ?.timesheetFiles
                                                                .length ===
                                                                0) && (
                                                              <img
                                                                src={
                                                                  UploadAlert
                                                                }
                                                              />
                                                            )}
                                                          {/* {!row.isTimesheetFinalized && ( */}
                                                          {!timesheet.approved ? (
                                                            isAdminScreen ? (
                                                              <a
                                                                className="pointer"
                                                                onClick={(e) =>
                                                                  handleOpen1(
                                                                    timesheetIndex,
                                                                    timesheetPeriodIndex,
                                                                    index,
                                                                  )
                                                                }>
                                                                Upload
                                                              </a>
                                                            ) : (
                                                              <a
                                                                className="pointer"
                                                                onClick={(e) =>
                                                                  handleOpen1(
                                                                    timesheetIndex,
                                                                    timesheetPeriodIndex,
                                                                    index,
                                                                  )
                                                                }>
                                                                Upload
                                                              </a>
                                                            )
                                                          ) : (
                                                            <Typography />
                                                          )}
                                                          {/* )} */}
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )}
                                                {timesheetPeriod?.outSicks &&
                                                  timesheetPeriod?.outSicks.map(
                                                    (outSick, outSickIndex) => (
                                                      <TableRow
                                                        className="active-yellow"
                                                        key={outSickIndex}>
                                                        <TableCell
                                                          component="th"
                                                          scope="row">
                                                          Out Sick
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          <CustomInput
                                                            placeHolder=""
                                                            value={
                                                              outSick.hours
                                                            }
                                                            name={
                                                              'outSick-hours'
                                                            }
                                                            type={'text'}
                                                            onChange={(
                                                              e: any,
                                                            ) => {
                                                              handleChangeInput(
                                                                e,
                                                                outSickIndex,
                                                                timesheetPeriodIndex,
                                                                row.timesheetPeriods,
                                                                index,
                                                                'outSicks',
                                                              );
                                                            }}
                                                            error={
                                                              outSick.errors
                                                                ?.message
                                                            }
                                                            disabled={
                                                              row?.isTimesheetFinalized
                                                            }
                                                            inputProps={{
                                                              inputProps: {
                                                                min: 0,
                                                              },
                                                            }}
                                                          />
                                                        </TableCell>
                                                        <TableCell align="left" />
                                                        <TableCell align="left" />
                                                        {/* {showApproved && (
                                              <TableCell align="left">
                                                <Checkbox
                                                  {...label}
                                                  defaultChecked
                                                  sx={{
                                                    '&.Mui-checked': {
                                                      color: '#1D9900',
                                                    },
                                                    color: '#c9c6c6',
                                                  }}
                                                />
                                              </TableCell>
                                            )} */}
                                                        <TableCell align="right">
                                                          {/* {!row.isTimesheetFinalized && ( */}
                                                          <Typography
                                                            component={'a'}
                                                            className="pointer"
                                                            onClick={(e) =>
                                                              handleRemoveOutSick(
                                                                outSickIndex,
                                                                timesheetPeriodIndex,
                                                                row.timesheetPeriods,
                                                                index,
                                                              )
                                                            }>
                                                            <img
                                                              src={
                                                                CloseCircleIcon
                                                              }
                                                            />
                                                          </Typography>
                                                          {/* )} */}
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )}
                                                {row?.timesheetPeriods[
                                                  timesheetPeriodIndex
                                                ]?.notes[0]?.id &&
                                                  timesheetPeriod?.notes.map(
                                                    (note, noteIndex) => (
                                                      <TableRow key={noteIndex}>
                                                        <TableCell
                                                          component="th"
                                                          colSpan={5}
                                                          scope="row"
                                                          sx={{
                                                            padding: '5px 0px',
                                                          }}>
                                                          <Typography
                                                            component={'div'}
                                                            className="notices-details">
                                                            <Grid item md={11}>
                                                              <Typography>
                                                                {note.note}
                                                              </Typography>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md={1}
                                                              alignContent="right">
                                                              <Typography
                                                                align="right"
                                                                className="pointer"
                                                                onClick={(e) =>
                                                                  handleOpenAddWeekNotes(
                                                                    timesheetPeriodIndex,
                                                                    index,
                                                                  )
                                                                }>
                                                                Edit
                                                              </Typography>
                                                            </Grid>
                                                          </Typography>
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                          {/* {!row.isTimesheetFinalized && ( */}
                                          <Typography
                                            component={'div'}
                                            className="notices">
                                            <Typography
                                              component={'a'}
                                              className="pointer"
                                              onClick={(e) =>
                                                addNewOutSick(
                                                  row.timesheetPeriods,
                                                  timesheetPeriodIndex,
                                                  index,
                                                )
                                              }>
                                              Were you out sick this week?
                                            </Typography>
                                            {!row?.timesheetPeriods[
                                              timesheetPeriodIndex
                                            ]?.notes[0]?.id && (
                                              <Typography>
                                                <a
                                                  className="pointer"
                                                  onClick={(e) =>
                                                    handleOpenAddWeekNotes(
                                                      timesheetPeriodIndex,
                                                      index,
                                                    )
                                                  }>
                                                  Add Week Notes
                                                </a>
                                              </Typography>
                                            )}
                                          </Typography>
                                          {/* )} */}
                                        </Typography>
                                      </Box>
                                    </Typography>
                                  </Typography>
                                </Typography>
                              );
                            },
                          )
                        ) : (
                          <Typography
                            component={'div'}
                            sx={{
                              justifyContent: 'center',
                              paddingBottom: '15px',
                              paddingTop: '5px',
                              textAlign: 'center',
                            }}>
                            <CircularProgress sx={{ color: '#340000' }} />
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                } else {
                  return (
                    <Accordion
                      key={index}
                      expanded={expand == index ? true : false}>
                      <Typography
                        component={'div'}
                        className={
                          checkPaymentPending(row)
                            ? 'accordin-details-pending'
                            : 'accordin-details1'
                        }>
                        <Typography component={'p'} sx={{ padding: '20px' }}>
                          {moment(row?.periodFrom).format('MMMM')}{' '}
                          <strong>
                            {moment(row?.periodFrom).format('DD')} -{' '}
                            {moment(row?.periodTo).format('DD')}
                          </strong>
                        </Typography>
                        <a
                          href={'javascript:void(0);'}
                          onClick={(e) => toggleAcordion(index, row?.id)}
                          style={{
                            margin: 15,
                            marginBottom: 10,
                            marginTop: 10,
                          }}>
                          <img
                            src={accordionarrow}
                            className={expand == index ? 'expand-new' : ''}
                          />
                        </a>
                      </Typography>
                      <AccordionDetails>
                        {row?.timesheetPeriods &&
                          row?.timesheetPeriods.map(
                            (timesheetPeriod, timesheetPeriodIndex) => {
                              let h = 0.0;
                              timesheetPeriod?.timesheets.map((timesheet) => {
                                h = h + parseFloat(timesheet.hours);
                                h =
                                  h +
                                  (timesheet.adjustmentHours
                                    ? parseFloat(timesheet.adjustmentHours)
                                    : 0);
                              });
                              timesheetPeriod?.outSicks.map((outSick) => {
                                h = h + parseFloat(outSick.hours);
                              });
                              return (
                                <Typography
                                  component={'div'}
                                  className="timesheet-table"
                                  key={timesheetPeriodIndex}>
                                  {showTimesheetAprrovedData(
                                    index,
                                    timesheetPeriodIndex,
                                    timesheetPeriod,
                                    row,
                                    h,
                                  )}
                                </Typography>
                              );
                            },
                          )}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })
            : !loader && (
                <Typography component={'p'}>No Timesheet Found</Typography>
              )}
        </Typography>
      </Typography>
      {/* <!------------------Confirm Timesheet Markup--------------> */}
      <Modal
        open={editopen.open}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={editstyle} className="editmodal">
          <Typography className="title">EDIT PROJECT HOURS</Typography>
          <Grid container columnSpacing={1.5} className="c-bottom">
            <Grid item md={6}>
              <Typography className="p-text">Project</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className="p-text">Hours</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography className="p-text">Adjust Hours</Typography>
            </Grid>
            {!editopen.fullVisible && (
              <Grid
                container
                columnSpacing={1.5}
                sx={{ paddingLeft: '10px !important' }}>
                <Grid item md={6}>
                  <Typography className="pro-name">
                    {editopen?.data?.project?.project}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography className="pro-name">
                    {editopen?.data?.hours}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography className="p-text">
                    <CustomInput
                      placeHolder=""
                      value={editopen?.data?.adjustmentHours}
                      name={'time-sheet-hours'}
                      type={'text'}
                      onChange={(e: any) => {
                        editopen.data.adjustmentHours = e.target.value;
                        setEditOpen({
                          ...editopen,
                          data: editopen?.data,
                        });
                      }}
                      inputProps={{
                        inputProps: { min: 0 },
                      }}
                      extraInputStyle={{
                        backgroundColor: 'white !important',
                        height: '34px !important',
                        textAlign: 'center !important',
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography className={'notesTitle'} component="h2">
                    Notes
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <CustomInput
                    className="common-textarea"
                    id="outlined-multiline-static"
                    isTextArea={true}
                    minRows={3}
                    type={'text'}
                    placeHolder={'enter reason for change'}
                    value={editopen.data.hourAdjustNotes}
                    onChange={(e: any) => {
                      editopen.data.hourAdjustNotes = e.target.value;
                      setEditOpen({
                        ...editopen,
                        data: editopen?.data,
                      });
                    }}
                    extraStyle={{
                      width: '100%',
                    }}
                    extraInputBaseStyle={{
                      margin: '0px !important',
                      padding: '0px !important',
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* <Typography className="note">
            Note: Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industrys standard
            dummy text ever since the 1500s, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book.
          </Typography> */}
          <Typography className="model-btn" component={'div'}>
            <Button variant="text" onClick={handleEditClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={submitAdjustHour}>
              Submit Changes
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="model-widget">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm Timesheet
          </Typography>
          <Typography
            component="h4"
            id="modal-modal-description"
            sx={{ mt: 2 }}>
            Are you sure your <span>HOURS</span> are correct?
          </Typography>
          <Typography component="h6">
            You will not be able to edit once submitted
          </Typography>

          <Typography component={'div'} className="model-timesheet">
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                p: 0,
              }}>
              <Typography component={'div'}>
                <Typography sx={{ pr: 5 }}>
                  {moment(
                    rows[selectedRowIndex]?.timesheetPeriods[
                      selectedTimesheetPeriodIndex
                    ]?.periodFrom,
                  ).format('MMMM')}{' '}
                  <strong />
                </Typography>
                <Typography>
                  <span>
                    Pay Period:{' '}
                    <strong>
                      {moment(
                        rows[selectedRowIndex]?.timesheetPeriods[
                          selectedTimesheetPeriodIndex
                        ]?.periodFrom,
                      ).format('DD')}
                    </strong>
                  </span>
                </Typography>
              </Typography>
              <Typography component={'div'}>
                <Typography style={{ color: '#DB2426' }}>
                  Total Hours:{' '}
                  {/* <strong>{getTotalHours(rows[selectedRowIndex])}</strong> */}
                  <strong>
                    {getTotalHoursSingleRow(
                      rows[selectedRowIndex]?.timesheetPeriods[
                        selectedTimesheetPeriodIndex
                      ],
                    )}
                  </strong>
                </Typography>
              </Typography>
            </Box>

            <Typography
              component={'div'}
              className="timesheet-table"
              sx={{
                borderBottom: 'unset',
              }}>
              <Box
                sx={{
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 0,
                  p: 0,
                }}>
                <Typography style={{ fontSize: '14px' }}>
                  {moment(
                    rows[selectedRowIndex]?.timesheetPeriods[
                      selectedTimesheetPeriodIndex
                    ]?.periodFrom,
                  ).format('MMMM')}{' '}
                  <strong>
                    {moment(
                      rows[selectedRowIndex]?.timesheetPeriods[
                        selectedTimesheetPeriodIndex
                      ]?.periodFrom,
                    ).format('DD')}{' '}
                    -{' '}
                    {moment(
                      rows[selectedRowIndex]?.timesheetPeriods[
                        selectedTimesheetPeriodIndex
                      ]?.periodTo,
                    ).format('DD')}
                  </strong>
                </Typography>
                <Typography style={{ fontSize: '14px' }}>
                  Total:{' '}
                  <strong>
                    {getTotalHoursSingleRow(
                      rows[selectedRowIndex]?.timesheetPeriods[
                        selectedTimesheetPeriodIndex
                      ],
                    )}
                  </strong>
                </Typography>
              </Box>
              <TableContainer component={'div'}>
                <Table sx={{ maxWidth: 315 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Project Worked On:</TableCell>
                      <TableCell align="left">Hours:</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows[selectedRowIndex]?.timesheetPeriods[
                      selectedTimesheetPeriodIndex
                    ]?.timesheets.map((timesheet, timesheetIndex) => (
                      <TableRow className="active-blue" key={timesheetIndex}>
                        <TableCell component="th" scope="row">
                          {timesheet?.project?.project}
                        </TableCell>
                        <TableCell align="left">{timesheet.hours}</TableCell>
                      </TableRow>
                    ))}
                    {rows[selectedRowIndex]?.timesheetPeriods[
                      selectedTimesheetPeriodIndex
                    ]?.outSicks.map((outSick, outSickIndex) => (
                      <TableRow className="active-blue" key={outSickIndex}>
                        <TableCell component="th" scope="row">
                          Out Sick
                        </TableCell>
                        <TableCell align="left">{outSick.hours}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>

            {/* {rows[selectedRowIndex]?.timesheetPeriods.map(
              (timesheetPeriod, timesheetPeriodIndex) => (
                <Typography
                  component={'div'}
                  className="timesheet-table"
                  key={timesheetPeriodIndex}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      mb: 0,
                      p: 0,
                    }}>
                    <Typography style={{ fontSize: '14px' }}>
                      {moment(timesheetPeriod?.periodFrom).format('MMMM')}{' '}
                      <strong>
                        {moment(timesheetPeriod?.periodFrom).format('DD')} -{' '}
                        {moment(timesheetPeriod?.periodTo).format('DD')}
                      </strong>
                    </Typography>
                    <Typography style={{ fontSize: '14px' }}>
                      Total:{' '}
                      <strong>{getTotalHoursSingleRow(timesheetPeriod)}</strong>
                    </Typography>
                  </Box>
                  <TableContainer component={'div'}>
                    <Table sx={{ maxWidth: 315 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Project Worked On:</TableCell>
                          <TableCell align="left">Hours:</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timesheetPeriod?.timesheets.map(
                          (timesheet, timesheetIndex) => (
                            <TableRow
                              className="active-blue"
                              key={timesheetIndex}>
                              <TableCell component="th" scope="row">
                                {timesheet?.project?.project}
                              </TableCell>
                              <TableCell align="left">
                                {timesheet.hours}
                              </TableCell>
                            </TableRow>
                          ),
                        )}
                        {timesheetPeriod?.outSicks.map(
                          (outSick, outSickIndex) => (
                            <TableRow
                              className="active-blue"
                              key={outSickIndex}>
                              <TableCell component="th" scope="row">
                                Out Sick
                              </TableCell>
                              <TableCell align="left">
                                {outSick.hours}
                              </TableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Typography>
              ),
            )} */}
          </Typography>

          <Typography className="model-btn" component={'div'}>
            <Button variant="text" onClick={handleClose}>
              NO, Cancel
            </Button>
            <Button variant="contained" onClick={handleFinalizeTimesheet}>
              Yes, Finalize Timesheet
            </Button>
          </Typography>
        </Box>
      </Modal>
      {/* <!-------------------------------Upload documents---------------------> */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1} className="model-widget">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Upload Document
            </Typography>
            <Typography>
              <label>File</label>
              <Typography>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    color: '#fff',
                    fontFamily: 'Red Hat Display SemiBold',
                  }}>
                  Upload
                  <input
                    hidden
                    // accept="image/*"
                    // multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        rows[selectedRowIndex].files = [];
                        // if (!rows[selectedRowIndex].files) {
                        //   rows[selectedRowIndex].files = [];
                        // }
                        rows[selectedRowIndex].files.push(e.target.files[0]);
                        setRows([...rows]);
                      }
                      // rows[selectedRowIndex].timesheetPeriods[
                      //   selectedTimesheetPeriodIndex
                      // ].timesheets[
                      //   selectedTimesheetsIndex
                      // ].uniqueId = e.target.files[0];
                    }}
                  />
                </Button>
                {rows[selectedRowIndex]?.files?.map((imageName, index) => {
                  return (
                    <Typography
                      className="upload-btn"
                      style={{ paddingTop: 8 }}
                      key={index}>
                      <label>{imageName.name}</label>
                      <Typography
                        component={'a'}
                        className="pointer"
                        align="right"
                        onClick={(e) => {
                          rows[selectedRowIndex].files.splice(index, 1);
                          setRows([...rows]);
                        }}>
                        <img src={CloseCircleIcon} />
                      </Typography>
                    </Typography>
                  );
                })}
              </Typography>
            </Typography>
            <Typography>
              <label>Select File Type</label>
              <CustomSelect
                labelKey={'label'}
                valueKey={'value'}
                options={[
                  {
                    label: 'Timesheet',
                    value: 'Timesheet',
                  },
                  {
                    label: 'Approval',
                    value: 'Approval',
                  },
                  {
                    label: 'Approved Timesheet',
                    value: 'Approved Timesheet',
                  },
                ]}
                name={'fileType'}
                // value={''}
                placeHolder={'Select'}
                className={'common-select'}
                error={errors.fileType?.message}
                {...register('fileType', {
                  required: 'File type is required',
                })}
              />
              {/* <Select
                  className="common-select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  {...register("fileType", { required: true })}>
                  <MenuItem value="" />
                  <MenuItem value={'Timesheet'}>Timesheet</MenuItem>
                  <MenuItem value={'Approval'}>Approval</MenuItem>
                  <MenuItem value={'Approved Timesheet'}>
                    Approved Timesheet
                  </MenuItem>
                </Select> */}
            </Typography>
            <Typography
              className="notes"
              component={'div'}
              sx={{
                marginBottom: '0px !important',
              }}
            />
            <Typography className="model-btn" component={'div'}>
              <Button variant="text" onClick={handleClose1}>
                Cancel
              </Button>
              {rows[selectedRowIndex]?.files?.length ? (
                <CustomButton
                  variant="contained"
                  type="submit"
                  name={'Upload Document'}
                  loading={fileUploadLoading}
                  extraStyle={{ textTransform: 'capitalize' }}
                />
              ) : (
                <Button variant="contained" disabled>
                  Upload Document
                </Button>
              )}
            </Typography>
          </form>
        </Box>
      </Modal>
      {/* <!-------------------------------Add notes---------------------> */}
      <Modal
        open={openAddWeekNotes}
        onClose={handleCloseAddWeekNotes}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style2} className="model-widget">
          <form onSubmit={handleAddNotesSubmit(submitaddNotes)}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Notes
            </Typography>
            <Typography
              component="h4"
              id="modal-modal-description"
              style={{ marginTop: 0 }}>
              If you had less than 40 hours a week, why.
            </Typography>
            <Typography>
              <Controller
                control={control}
                defaultValue={
                  rows[selectedRowIndex]?.timesheetPeriods[
                    selectedTimesheetPeriodIndex
                  ]?.notes[0]?.note
                }
                name="note"
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <CustomInput
                      // sx={{ p: 0 }}
                      className="common-textarea"
                      id="outlined-multiline-static"
                      isTextArea={true}
                      minRows={3}
                      type={'text'}
                      name={name}
                      placeHolder={'Add Notes'}
                      value={value}
                      ref={ref}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      // error={formState.errors?.note?.message}
                    />
                  );
                }}
              />
              {formState.errors?.note?.message && (
                <Typography className="errorText">
                  {formState.errors?.note?.message}
                </Typography>
              )}
            </Typography>
            <Typography className="model-btn" component={'div'}>
              <Button variant="text" onClick={handleCloseAddWeekNotes}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                type="submit"
                name={'Submit'}
                loading={notesLoading}
                extraStyle={{ textTransform: 'capitalize' }}
              />
            </Typography>
          </form>
        </Box>
      </Modal>
      {/* <!-------------------------------View Timesheet Files---------------------> */}
      <Modal
        open={openViewDocument}
        onClose={handleCloseOpenViewDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll' }}>
        <Box sx={styleView} className="model-widget">
          <Grid container justifyContent="flex-end">
            {/* <IconButton
                aria-label="close"
                onClick={handleCloseOpenViewDocument}>
                <span>Close</span>
              </IconButton> */}
          </Grid>
          <Grid
            container
            sx={{
              alignItems: 'center',
              display: 'flex',
              // justifyContent: 'space-between',
            }}>
            <Grid container sm={8}>
              <Typography component="h2">
                {' '}
                {capitalizeText(timeSheetViewSelected?.timesheetFileType)}{' '}
              </Typography>
            </Grid>
            <Grid
              container
              sm={4}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <IconButton
                aria-label="download"
                sx={{
                  border: '1px solid #E0E0E0 !important',
                  borderRadius: '10px !important',
                }}
                onClick={() => cosultantListDownload()}>
                <DownloadForOfflineIcon style={{ color: '#241A2E' }} />
              </IconButton>
              &nbsp;&nbsp;&nbsp;
              <IconButton
                aria-label="close"
                onClick={handleCloseOpenViewDocument}>
                <span>Close</span>
              </IconButton>
            </Grid>
          </Grid>
          {/* {JSON.stringify(timeSheetViewSelected)} */}
          {/* {timeSheetViewSelected?.extension} */}
          {String(timeSheetViewSelected?.id) && (
            <FileViewer
              fileType={timeSheetViewSelected?.extension}
              filePath={`${process.env.REACT_APP_APIURL}${
                MASTER_ENDPOINT.Files
              }/${String(timeSheetViewSelected?.id)}`}
              key={String(timeSheetViewSelected?.id)}
            />
          )}
        </Box>
      </Modal>
    </LoadingOverlay>
  );
};
export default TimesheetComponent;
