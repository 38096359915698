import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backText: {
    color: '#005EBF',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 12,
  },
  h1Text: {
    color: '#292929',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 25,
  },
  h2Text: {
    color: '#DB2426',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 20,
  },
  header: {
    alignItems: 'center',
    background: '#fff',
    marginTop: 5,
    padding: 15,
  },
  header1: {
    alignItems: 'center',
    background: '#fff',
    height: 64,
    marginTop: 0,
    padding: 15,
  },
  tableview: {
    marginTop: 0,
  },
  tableview1: {
    padding: 50,
  },
  totalText: {
    color: '#979598',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 14,
  },
}));

export default useStyles;
