import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Modal,
  Popover,
  Radio,
  // RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { grey } from '@mui/material/colors';
// import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import arrowcolumns from 'assets/images/arrowcolumns.svg';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import { AdminPrivateRoutes } from 'config';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { UPDATE_PROJECT_FILTER } from 'store/types/UserTypes';
import { addNotesForm } from 'types';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addNotesSchema } from 'utils/ValidatorSchema';

import useStyles from './style';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

const notesStyle = {
  createDate: {
    color: '#8F9CA1',
    fontFamily: 'Red Hat Display Medium',
    fontSize: '14px',
  },
  notesText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Medium',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '17px',
    paddingTop: '4px',
  },
  userName: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    fontSize: '14px',
    paddingRight: '5px',
  },
};
const staticParam = {
  approved: '',
  branch: '',
  collectionFrequency: '',
  hours: '',
  lob: '',
  order: 'asc',
  searchWord: '',
  skip: 0,
  sortby: 'project',
  subPeriodFrom: '',
  subPeriodTo: '',
  take: 100,
  timesheets: '',
};

export const ConsultantProjects: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zip: any = new JSZip();

  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [list, setList] = React.useState<any>([]);
  const [payPeriodList, setPayPeriodList] = React.useState<any>([]);
  // const [periodIndex, setPeriodIndex] = React.useState(0);
  const [requestList, setRequestList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [hourTotal, setHourTotal] = React.useState(0);
  const [timesheetTotal, setTimesheetTotal] = React.useState(0);
  const [radio, setRadio] = React.useState<string>('both');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>(
    'You have not submitted your timesheets/hours for a month. This is a first written warning that if you do not submit your timesheet/hours by X day, your employment may be terminated for violation of company policy.',
  );
  const [loader, setLoader] = React.useState<boolean>(false);
  const { companyStatus, searchText, projectFilter, userInfo }: any =
    useAppSelector((store: any) => store.userLogin);

  useEffect(() => {
    if (payPeriodList.length && projectFilter.periodIndex !== -1) {
      getPeriodData(projectFilter, payPeriodList[projectFilter.periodIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStatus, payPeriodList, projectFilter]);

  const [searchLoad, setSearchLoad] = React.useState(false);

  useEffect(() => {
    if (payPeriodList.length) {
      if (searchLoad && projectFilter.searchWord !== searchText) {
        dispatch({
          payload: {
            ...projectFilter,
            searchWord: searchText,
          },
          type: UPDATE_PROJECT_FILTER,
        });
      }
    }
    setSearchLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchLoad]);

  const getData = () => {
    getAllListData(MASTER_ENDPOINT.Payperiods)
      .then((resp: any) => {
        const filterData = resp?.data;
        // const filterData = resp?.data.find(
        //   (item: any) => item.year === parseInt(moment().format('YYYY')),
        // );
        if (filterData) {
          const dateArray = [];
          filterData.map((row: any) => {
            row.payPeriodQuarters.map((item: any) => {
              item.payPeriods.map((item1: any) => {
                dateArray.push(item1);
              });
            });
          });
          if (dateArray.length) {
            const dateAObject = dateArray.sort(function (a, b) {
              const c: any = new Date(a.startDate);
              const d: any = new Date(b.startDate);
              return c - d;
            });
            setPayPeriodList(dateAObject);
            if (dateAObject.length) {
              const fIndx = dateAObject.findIndex((item: any) =>
                moment(moment().format('YYYY-MM-DD')).isBetween(
                  moment(item.startDate),
                  moment(item.endDate),
                  undefined,
                  '[]',
                ),
              );
              if (fIndx !== -1) {
                if (!projectFilter.periodIndex) {
                  dispatch({
                    payload: {
                      ...projectFilter,
                      periodIndex: fIndx,
                    },
                    type: UPDATE_PROJECT_FILTER,
                  });
                }
                // setPeriodIndex(fIndx);
              } else {
                const findLastIndex = (arr, fn) =>
                  (arr
                    .map((val, i) => [i, val])
                    .filter(([i, val]) => fn(val, i, arr))
                    .pop() || [-1])[0];
                const fIndx1 = findLastIndex(dateAObject, (item: any) =>
                  moment(item.endDate).isBefore(
                    moment(moment().format('YYYY-MM-DD')),
                  ),
                );
                if (fIndx1 !== -1) {
                  // setPeriodIndex(fIndx1);
                  if (!projectFilter.periodIndex) {
                    dispatch({
                      payload: {
                        ...projectFilter,
                        periodIndex: fIndx1,
                      },
                      type: UPDATE_PROJECT_FILTER,
                    });
                  }
                } else {
                  // setPeriodIndex(0);
                  if (!projectFilter.periodIndex) {
                    dispatch({
                      payload: {
                        ...projectFilter,
                        periodIndex: 0,
                      },
                      type: UPDATE_PROJECT_FILTER,
                    });
                  }
                }
              }
            }
          }
        }
      })
      .catch((err: any) => {});
  };

  const [branchList, setBranchList] = React.useState<any>([]);
  const [branchDownload, setBranchDownload] = React.useState<any>('');
  // const [branch, setBranch] = React.useState<any>('');
  const getBrach = () => {
    getAllListData(MASTER_ENDPOINT.Consultants + '/branches')
      .then((resp: any) => {
        setBranchList(resp?.data);
      })
      .catch((err: any) => {});
  };

  const [lobList, setLobList] = React.useState<any>([]);
  const getLob = () => {
    getAllListData(MASTER_ENDPOINT.Consultants + '/lob')
      .then((resp: any) => {
        setLobList(resp?.data);
      })
      .catch((err: any) => {});
  };

  const filterChange = (type: string, val: any) => {
    if (type === 'hours') {
      dispatch({
        payload: {
          ...projectFilter,
          hours: val,
        },
        type: UPDATE_PROJECT_FILTER,
      });
    } else if (type === 'timesheets') {
      dispatch({
        payload: {
          ...projectFilter,
          timesheets: val,
        },
        type: UPDATE_PROJECT_FILTER,
      });
    } else if (type === 'Period') {
      dispatch({
        payload: {
          ...projectFilter,
          subPeriodFrom: val && val?.from ? val?.from : '',
          subPeriodTo: val && val?.to ? val?.to : '',
        },
        type: UPDATE_PROJECT_FILTER,
      });
    } else if (type === 'approved') {
      dispatch({
        payload: {
          ...projectFilter,
          approved: val,
        },
        type: UPDATE_PROJECT_FILTER,
      });
    } else if (type === 'branch') {
      dispatch({
        payload: {
          ...projectFilter,
          branch: val,
        },
        type: UPDATE_PROJECT_FILTER,
      });
    } else if (type === 'lob') {
      dispatch({
        payload: {
          ...projectFilter,
          lob: val,
        },
        type: UPDATE_PROJECT_FILTER,
      });
    } else if (type === 'collectionFrequency') {
      dispatch({
        payload: {
          ...projectFilter,
          collectionFrequency: val,
        },
        type: UPDATE_PROJECT_FILTER,
      });
    }
    setOpen3(false);
  };

  const getPeriodData = (paramObject: any, dateObjec?: any) => {
    setLoader(true);
    setList([]);
    setTotal(0);
    setHourTotal(0);
    setTimesheetTotal(0);
    let url =
      'projects/' +
      MASTER_ENDPOINT.Consultants +
      '?order=' +
      paramObject.order +
      '&sortby=' +
      paramObject.sortby +
      '&skip=' +
      paramObject.skip +
      '&take=' +
      paramObject.take +
      '&periodFrom=' +
      dateObjec.startDate +
      '&periodTo=' +
      dateObjec.endDate +
      '&search=' +
      paramObject.searchWord;
    if (companyStatus !== 'all') {
      url += `&organization=${String(companyStatus)}`;
    }
    if (paramObject.approved) {
      url += '&approved=' + paramObject.approved;
    }
    if (paramObject.hours) {
      url += '&hours=' + paramObject.hours;
    }
    if (paramObject.branch) {
      url += '&branch=' + paramObject.branch;
    }
    if (paramObject.lob) {
      url += '&lob=' + paramObject.lob;
    }
    if (paramObject.collectionFrequency) {
      url += '&collectionFrequency=' + paramObject.collectionFrequency;
    }
    if (paramObject.timesheets) {
      url += '&timesheets=' + paramObject.timesheets;
    }
    if (paramObject.subPeriodFrom) {
      url += '&subPeriodFrom=' + paramObject.subPeriodFrom;
    }
    if (paramObject.subPeriodTo) {
      url += '&subPeriodTo=' + paramObject.subPeriodTo;
    }

    getAllListData(url)
      .then((resp: any) => {
        setList(resp?.data);
        setTotal(resp?.total?.total ? parseInt(resp?.total?.total) : 0);
        setHourTotal(resp?.headerTotal[0]?.hours);
        setTimesheetTotal(resp?.headerTotal[0]?.timesheet);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
    getBrach();
    getLob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAllEvent = (checked: boolean) => {
    if (checked) {
      const userList = [];
      list.filter((row: any) => {
        if (row.userId) {
          userList.push(row.userId);
        }
      });
      setRequestList(userList);
    } else {
      setRequestList([]);
    }
  };

  const checkEvent = (check, userDataId) => {
    if (userDataId) {
      if (check) {
        requestList.push(userDataId);
        setRequestList([...requestList]);
      } else {
        const filterList = requestList.filter(
          (item: any) => item !== userDataId,
        );
        setRequestList(filterList);
      }
    }
  };

  const setParamData = (str: string) => {
    if (payPeriodList.length) {
      if (projectFilter.sortby === str) {
        dispatch({
          payload: {
            ...projectFilter,
            order: projectFilter.order === 'asc' ? 'desc' : 'asc',
            skip: 0,
            sortby: projectFilter.sortby,
            take: 100,
          },
          type: UPDATE_PROJECT_FILTER,
        });
      } else {
        dispatch({
          payload: {
            ...projectFilter,
            order: 'asc',
            skip: 0,
            sortby: str,
            take: 100,
          },
          type: UPDATE_PROJECT_FILTER,
        });
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    // setPage(newPage);
    dispatch({
      payload: {
        ...projectFilter,
        skip: newPage * 100,
      },
      type: UPDATE_PROJECT_FILTER,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 11));
    // setPage(0);
    dispatch({
      payload: staticParam,
      type: UPDATE_PROJECT_FILTER,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setRadio('both');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open3, setOpen3] = React.useState(false);
  const [placement3, setPlacement3] = React.useState<PopperPlacementType>();
  const [filterType, setFilterType] = React.useState('');

  const handleClick3 =
    (newPlacement: PopperPlacementType, type: string) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl3(event.currentTarget);
      setOpen3((prev) => placement3 !== newPlacement || !prev);
      setPlacement3(newPlacement);
      setFilterType(type);
    };

  const sendRequest = () => {
    if (!requestList.length) {
      toast.error('Please select atleast one project...', alertOptions);
      return;
    }
    setModalOpen(true);
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const submitRequest = () => {
    setLoading(true);
    const bodyObject = {
      bodyContent: message,
      // branches: radio === 'timesheets' ? [branch] : [],
      branches: [],
      type: radio,
      // userId: radio !== 'timesheets' ? requestList : [],
      userId: requestList,
    };
    addNewData(
      bodyObject,
      MASTER_ENDPOINT.Consultants + '/send-request',
      {},
      true,
    )
      .then((resp: any) => {
        setLoading(false);
        setModalOpen(false);
        setRequestList([]);
        if (payPeriodList.length && projectFilter.periodIndex) {
          getPeriodData(
            projectFilter,
            payPeriodList[projectFilter.periodIndex],
          );
        }
        // setBranch('');
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const downloadFiles = async () => {
    setLoader(true);
    await getAllListData(
      'projects/' +
        MASTER_ENDPOINT.Consultants +
        '?periodFrom=' +
        payPeriodList[projectFilter.periodIndex].startDate +
        '&periodTo=' +
        payPeriodList[projectFilter.periodIndex].endDate +
        '&organization=' +
        companyStatus +
        '&branch=' +
        branchDownload,
    )
      .then(async (resp: any) => {
        const downLoadListIds: any = [];
        const downLoadList = resp?.data;
        for (const [i, relativePath] of downLoadList.entries()) {
          if (
            downLoadList.length - 1 === i &&
            relativePath.timesheetFiles === null
          ) {
            zip.generateAsync({ type: 'blob' }).then(function (content) {
              saveAs(content, 'riseIT.zip');
            });
          }
          if (
            relativePath.timesheetFiles &&
            relativePath.timesheetFiles.length
          ) {
            const firstName = relativePath.firstName.replace(' ', '-');
            const lastName = relativePath.lastName.replace(' ', '-');
            const projectFirm = relativePath.projectFirm
              ? relativePath.projectFirm.replace(' ', '-')
              : '';
            const periodToDate = moment(relativePath.periodTo).format('DD');
            const periodToMonth = moment(relativePath.periodTo).format('MM');
            const periodToMonthName = moment(relativePath.periodTo).format(
              'MMMM',
            );
            const periodToYear = moment(relativePath.periodTo).format('YYYY');
            const periodFromDate = moment(relativePath.periodFrom).format('DD');
            const periodFromMonth = moment(relativePath.periodFrom).format(
              'MM',
            );
            const periodFromYear = moment(relativePath.periodFrom).format(
              'YYYY',
            );
            let pathName = `${String(lastName)}.${String(firstName)}`;
            if (relativePath.collectionFrequency) {
              if (relativePath.collectionFrequency === 'W') {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodToYear,
                  )}.${String(periodToMonth)}.${String(periodToDate)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    projectFirm,
                  )}.${String(periodToYear)}.${String(periodToMonth)}.${String(
                    periodToDate,
                  )}`;
                }
              } else if (relativePath.collectionFrequency === 'M') {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodToYear,
                  )}.${String(periodToMonthName)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    projectFirm,
                  )}.${String(periodToYear)}.${String(periodToMonthName)}`;
                }
              } else if (
                relativePath.collectionFrequency === 'BM' ||
                relativePath.collectionFrequency === 'W/M' ||
                relativePath.collectionFrequency === 'BW'
              ) {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodFromYear,
                  )}.${String(periodFromMonth)}.${String(
                    periodFromDate,
                  )}-${String(periodToMonth)}.${String(periodToDate)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    projectFirm,
                  )}.${String(periodFromYear)}.${String(
                    periodFromMonth,
                  )}.${String(periodFromDate)}-${String(
                    periodToMonth,
                  )}.${String(periodToDate)}`;
                }
              } else {
                if (relativePath.projectCount === '1') {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    periodFromYear,
                  )}.${String(periodFromMonth)}.${String(
                    periodFromDate,
                  )}-${String(periodToMonth)}.${String(periodToDate)}`;
                } else {
                  pathName = `${String(lastName)}.${String(firstName)}.${String(
                    projectFirm,
                  )}.${String(periodFromYear)}.${String(
                    periodFromMonth,
                  )}.${String(periodFromDate)}-${String(
                    periodToMonth,
                  )}.${String(periodToDate)}`;
                }
              }
            }
            for (const [j, filePath] of relativePath.timesheetFiles.entries()) {
              if (!filePath.downloadedAt) {
                const data = await new Promise((resolve) => {
                  JSZipUtils.getBinaryContent(
                    `${process.env.REACT_APP_APIURL}${
                      MASTER_ENDPOINT.Files
                    }/${String(filePath?.id)}`,
                    (err, data) => {
                      if (err) {
                        resolve('error');
                      } else {
                        resolve(data);
                      }
                    },
                  );
                });
                if (data !== 'error') {
                  downLoadListIds.push(filePath?.id);
                  zip.file(
                    `${pathName}.${String(j)}.${String(i)}.${String(
                      filePath.extension,
                    )}`,
                    data,
                    {
                      binary: true,
                    },
                  );
                  // zip.file(`${pathName}.${String(filePath.extension)}`, data, {
                  //   binary: true,
                  // });
                }
              }
              if (
                downLoadList.length - 1 === i &&
                relativePath.timesheetFiles.length - 1 === j
              ) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                  saveAs(content, 'riseIT.zip');
                });
              }
            }
          }
        }
        if (downLoadListIds.length) {
          const postJson = {
            fileIds: downLoadListIds,
          };
          updateData('', postJson, MASTER_ENDPOINT.Files + '/downloads', false)
            .then((resp: any) => {})
            .catch((e) => {});
        }
      })
      .catch((err: any) => {});
    setBranchDownload('');
    setLoader(false);
  };

  const {
    handleSubmit: handleAddNotesSubmit,
    control,
    formState,
    reset,
  } = useForm<addNotesForm>({
    defaultValues: {
      note: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(addNotesSchema),
  });

  const [notesOpen, setNotesOpen] = React.useState<any>({
    notesData: {},
    open: false,
    projectData: {},
    showViews: false,
  });
  const handleNotesOpen = (item: any, isOpen: boolean, showViews: boolean) => {
    if (!showViews) {
      setNotesOpen({
        notesData: {},
        open: isOpen,
        projectData: item,
        showViews: showViews,
      });
    } else {
      getAllListData(
        `${MASTER_ENDPOINT.ProjectNotes}?projectId=${String(item.id)}`,
      )
        .then((resp: any) => {
          setNotesOpen({
            notesData: resp.data,
            open: isOpen,
            projectData: item,
            showViews: showViews,
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };
  const handleNotesClose = () => {
    reset();
    setNotesOpen({
      notesData: {},
      open: false,
      projectData: {},
      showViews: false,
    });
  };

  const submitaddNotes = (data) => {
    setLoading(true);
    const bodyObject = {
      note: data.note,
      projectId: notesOpen.projectData.id,
      userId: userInfo?.id,
    };
    addNewData(bodyObject, MASTER_ENDPOINT.ProjectNotes, {}, true)
      .then((resp: any) => {
        setLoading(false);
        handleNotesClose();
        if (payPeriodList.length && projectFilter.periodIndex) {
          getPeriodData(
            projectFilter,
            payPeriodList[projectFilter.periodIndex],
          );
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Typography component={'div'} className={classes.subheader}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item md={6} sx={{ alignItems: 'center', display: 'flex' }}>
              {payPeriodList.length ? (
                <>
                  <Typography style={{ paddingBottom: '10px' }}>
                    Current Pay Period:
                    <span>
                      {projectFilter.periodIndex === 0 ? (
                        <a
                          style={{
                            color: '#e6e2e8',
                          }}>
                          <ChevronLeftIcon />
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // setPeriodIndex(periodIndex - 1);
                            dispatch({
                              payload: {
                                ...staticParam,
                                periodIndex: projectFilter.periodIndex - 1,
                              },
                              type: UPDATE_PROJECT_FILTER,
                            });
                          }}
                          href="javascript:void(0);"
                          style={{
                            color: '#9d9b9e',
                          }}>
                          <ChevronLeftIcon />
                        </a>
                      )}
                      {`${moment(
                        payPeriodList[projectFilter.periodIndex]?.startDate,
                      ).format('MMM DD')} - ${moment(
                        payPeriodList[projectFilter.periodIndex]?.endDate,
                      ).format('MMM DD YYYY')}`}
                      {projectFilter.periodIndex ===
                      parseInt(payPeriodList.length) - 1 ? (
                        <a
                          style={{
                            color: '#e6e2e8',
                          }}>
                          <ChevronRightIcon />
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            // setPeriodIndex(periodIndex + 1);
                            dispatch({
                              payload: {
                                ...staticParam,
                                periodIndex: projectFilter.periodIndex + 1,
                              },
                              type: UPDATE_PROJECT_FILTER,
                            });
                          }}
                          href="javascript:void(0);"
                          style={{
                            color: '#9d9b9e',
                          }}>
                          <ChevronRightIcon />
                        </a>
                      )}
                    </span>
                  </Typography>
                  <Typography>
                    Consultants: <span>{total}</span>
                  </Typography>
                </>
              ) : (
                <Typography />
              )}
            </Grid>
            <Grid item md={6} justifyContent="end">
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <Tooltip title="Clear Filters">
                    <Button
                      onClick={() => {
                        dispatch({
                          payload: {
                            ...projectFilter,
                            approved: '',
                            branch: '',
                            collectionFrequency: '',
                            hours: '',
                            lob: '',
                            order: 'asc',
                            searchWord: '',
                            skip: 0,
                            sortby: 'project',
                            subPeriodFrom: '',
                            subPeriodTo: '',
                            take: 100,
                            timesheets: '',
                          },
                          type: UPDATE_PROJECT_FILTER,
                        });
                      }}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        // padding: '0px',
                        minWidth: '0px !important',
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important',
                        textTransform: 'capitalize',
                      }}>
                      <AutorenewIcon
                        sx={{ color: '#a59999', fontSize: '20px' }}
                      />
                    </Button>
                  </Tooltip>
                </Typography>
                <Typography>
                  <TablePagination
                    className={classes.defaultpagination}
                    component="div"
                    count={total}
                    page={
                      parseInt(projectFilter.skip) /
                      parseInt(projectFilter.take)
                    }
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Typography>
                <Typography>
                  <ButtonGroup
                    variant="contained"
                    aria-label="split button"
                    sx={{
                      boxShadow: 'inherit !important',
                    }}>
                    <IconButton
                      className={classes.commondownload}
                      size="small"
                      aria-controls={open2 ? 'split-button-menu' : undefined}
                      aria-expanded={open2 ? 'true' : undefined}
                      aria-label="select merge strategy"
                      onClick={handleClick2}
                      aria-haspopup="menu">
                      <DownloadForOfflineIcon style={{ color: '#241A2E' }} />
                    </IconButton>
                    <Popover
                      className="commonpopover"
                      id={id2}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={handleClose2}
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}>
                      <Typography sx={{ p: 2 }}>
                        <Typography
                          sx={{
                            color: '#fff',
                            fontFamily: 'Red Hat Display Bold',
                            fontSize: '15px',
                            lineHeight: '20px',
                            paddingBottom: '5px',
                          }}>
                          Export TimeSheets
                        </Typography>
                        <Select
                          style={{ textTransform: 'capitalize' }}
                          className="common-select"
                          value={branchDownload}
                          onChange={(e: any) => {
                            setBranchDownload(e?.target?.value);
                          }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem
                            value={''}
                            style={{ textTransform: 'capitalize' }}>
                            {'select branch'}
                          </MenuItem>
                          {branchList.map((row: any, index: number) => {
                            return (
                              <MenuItem
                                value={row.branch.toString()}
                                key={index}
                                style={{ textTransform: 'capitalize' }}>
                                {row.branch}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Button variant="contained" onClick={downloadFiles}>
                          Export PDF
                        </Button>
                      </Typography>
                    </Popover>
                  </ButtonGroup>
                </Typography>
                <Typography>
                  <ButtonGroup variant="contained" aria-label="split button">
                    <Button onClick={handleClick}>Send Request</Button>
                    <Button
                      size="small"
                      aria-controls={open ? 'split-button-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select merge strategy"
                      onClick={handleClick}
                      aria-haspopup="menu">
                      <KeyboardArrowDownSharpIcon />
                    </Button>
                    <Popover
                      className={'commonpopover1'}
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}>
                      <Typography sx={{ p: 2 }}>
                        <RadioGroup name="use-radio-group" defaultValue="first">
                          <FormControlLabel
                            value="both"
                            label="Both"
                            onChange={(e: any) => setRadio(e?.target?.value)}
                            control={
                              <Radio
                                value="both"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#005EBF',
                                  },
                                  color: '#3e3d3d',
                                }}
                                checked={radio === 'both'}
                              />
                            }
                          />
                          <FormControlLabel
                            value="hours"
                            label="Hours"
                            onChange={(e: any) => setRadio(e?.target?.value)}
                            control={
                              <Radio
                                value="hours"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#005EBF',
                                  },
                                  color: '#3e3d3d',
                                }}
                                checked={radio === 'hours'}
                              />
                            }
                          />
                          <FormControlLabel
                            value="timesheets"
                            label="TimeSheet"
                            onChange={(e: any) => setRadio(e?.target?.value)}
                            control={
                              <Radio
                                value="timesheets"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#005EBF',
                                  },
                                  color: '#3e3d3d',
                                }}
                                checked={radio === 'timesheets'}
                              />
                            }
                          />
                        </RadioGroup>
                        {/* {radio === 'timesheets' && (
                          <Select
                            style={{ textTransform: 'capitalize' }}
                            className="common-select"
                            value={branch}
                            onChange={(e: any) => {
                              setBranch(e?.target?.value);
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem
                              value={''}
                              style={{ textTransform: 'capitalize' }}>
                              {'select branch'}
                            </MenuItem>
                            {branchList.map((row: any, index: number) => {
                              return (
                                <MenuItem
                                  value={row.branch.toString()}
                                  key={index}
                                  style={{ textTransform: 'capitalize' }}>
                                  {row.branch}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )} */}
                        <Button variant="contained" onClick={sendRequest}>
                          Send Request
                        </Button>
                      </Typography>
                    </Popover>
                  </ButtonGroup>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Typography>
        <Typography component={'div'} className={classes.defaulttable}>
          <TableContainer
            component={Paper}
            sx={{ height: 'calc(100vh - 130px)' }}>
            <Table sx={{ minWidth: '1535px' }} aria-label="simple table">
              <TableHead
                style={{
                  position: 'sticky',
                  top: '0px',
                  zIndex: 3,
                }}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 27 },
                        '&.Mui-checked': {
                          color: grey[800],
                        },
                        '&:first-child span': {
                          margin: 0,
                        },
                        color: grey[300],
                        marginLeft: '0px !important',
                        marginRight: '0px !important',
                      }}
                      onChange={(e) => setAllEvent(e.target.checked)}
                    />
                    Project
                    <Link href={'#'} onClick={() => setParamData('project')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    Consultant
                    <Link href={'#'} onClick={() => setParamData('lastName')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'Period')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Period
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                  </TableCell>
                  {/* <TableCell align="left">
                    Pay Type
                    <Link href={'#'} onClick={() => setParamData('payType')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell> */}
                  <TableCell align="left" style={{ minWidth: '60px' }}>
                    Notes
                    {/* <Link href={'#'} onClick={() => setParamData('notes')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link> */}
                  </TableCell>
                  <TableCell align="left" width={'7%'}>
                    End Date
                    <Link href={'#'} onClick={() => setParamData('periodTo')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link>
                  </TableCell>
                  <TableCell align="left" width={'12%'}>
                    <Button
                      onClick={handleClick3(
                        'bottom-end',
                        'collectionFrequency',
                      )}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        // paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Collection Frequency{' '}
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <Tooltip title="Clear Filter">
                      <Button
                        onClick={() => filterChange('collectionFrequency', '')}
                        sx={{
                          backgroundColor: 'transparent',
                          color: '#979598',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          // padding: '0px',
                          minWidth: '0px !important',
                          paddingLeft: '0px !important',
                          paddingRight: '0px !important',
                          textTransform: 'capitalize',
                        }}>
                        <AutorenewIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'branch')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        // paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Branch{' '}
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <Tooltip title="Clear Filter">
                      <Button
                        onClick={() => filterChange('branch', '')}
                        sx={{
                          backgroundColor: 'transparent',
                          color: '#979598',
                          fontFamily: 'Red Hat Display Regular',
                          fontSize: '12px',
                          // padding: '0px',
                          minWidth: '0px !important',
                          paddingLeft: '0px !important',
                          paddingRight: '0px !important',
                          textTransform: 'capitalize',
                        }}>
                        <AutorenewIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'hours')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Hours
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <em>{hourTotal}</em>
                  </TableCell>
                  <TableCell align="left">
                    {/* Timesheets
                    <Link
                      href={'#'}
                      onClick={() => setParamData('isTimesheetFinalized')}>
                      <span>
                        <img src={arrowcolumns} />
                      </span>
                    </Link> */}
                    <Button
                      onClick={handleClick3('bottom-end', 'timesheets')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Timesheets
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                    <em>{timesheetTotal}</em>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={handleClick3('bottom-end', 'approved')}
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#979598',
                        fontFamily: 'Red Hat Display Regular',
                        fontSize: '12px',
                        padding: '0px',
                        paddingRight: '20px !important',
                        textTransform: 'capitalize',
                      }}>
                      Approved
                      {open3 ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ color: '#a59999', fontSize: '20px' }}
                        />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length ? (
                  list.map((row: any, index: number) => {
                    const fIndex = requestList.findIndex(
                      (item: any) => item === row.userId,
                    );
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: '2px solid #eee !important',
                        }}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            maxWidth: '300px',
                            minWidth: '250px',
                            padding: '3px 0',
                          }}>
                          <Checkbox
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 27 },
                              '& span': {
                                margin: 0,
                              },
                              '&.Mui-checked': {
                                color: grey[800],
                              },
                              color: grey[300],
                            }}
                            checked={fIndex === -1 ? false : true}
                            onChange={(e) =>
                              checkEvent(e.target.checked, row.userId)
                            }
                          />
                          {row?.project ? row?.project : '-'}
                        </TableCell>
                        <TableCell align="left" width={'10%'}>
                          <NavLink
                            to={`${String(
                              AdminPrivateRoutes.CONSULTANTS,
                            )}/profile/${String(row?.userId)}`}
                            state={{ from: 'projects' }}
                            style={{
                              color: 'inherit',
                              textDecoration: 'inherit',
                            }}>
                            {row?.lastName + ', ' + row?.firstName}
                          </NavLink>
                        </TableCell>
                        <TableCell align="left" width={'10%'}>
                          {moment(row?.subPeriodFrom).format('MMM')}{' '}
                          {moment(row?.subPeriodFrom).format('DD')} -{' '}
                          {moment(row?.subPeriodTo).format('DD')}
                          {', '}
                          {moment(row?.subPeriodFrom).format('YYYY')}
                        </TableCell>
                        {/* <TableCell align="left" width={'6%'}>
                          {row?.payType}
                        </TableCell> */}
                        <TableCell align="left">
                          {Number(row?.projectNotesCount) > 0 ? (
                            <Button
                              variant="text"
                              onClick={(e) => {
                                handleNotesOpen(row, true, true);
                              }}
                              sx={{
                                color: '#005ebf',
                                fontFamily: 'Red Hat Display Bold !important',
                                fontSize: '14px !important',
                                margin: '0px !important',
                                minWidth: '0px !important',
                                padding: '0px !important',
                                textDecoration: 'underline !important',
                                textTransform: 'capitalize',
                              }}>
                              View
                            </Button>
                          ) : (
                            <AddIcon
                              className="pointer"
                              onClick={(e) => {
                                handleNotesOpen(row, true, false);
                              }}
                              sx={{
                                color: '#005ebf',
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left" width={'5%'}>
                          {moment(row?.endDate).format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell align="left" width={'10%'}>
                          {row?.projectCollectionFrequency
                            ? row?.projectCollectionFrequency
                            : '-'}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: '100px' }}>
                          {row?.branch ? row?.branch : '-'}
                        </TableCell>
                        <TableCell align="left" width={'10%'}>
                          <Typography
                            component={'div'}
                            sx={{ alignItems: 'center', display: 'flex' }}>
                            {row?.hours && row?.hours != '0.00' ? (
                              <CheckCircleIcon
                                style={{ color: '#1D9900', width: '18px' }}
                              />
                            ) : (
                              // <Typography
                              //   component={'div'}
                              //   sx={{ alignItems: 'center', display: 'flex' }}>
                              <CancelIcon
                                style={{ color: '#BF0000', width: '18px' }}
                              />
                              // </Typography>
                            )}
                            {row?.hoursRequestedOn && (
                              <Typography
                                component={'span'}
                                sx={{
                                  color: 'grey',
                                  fontFamily: 'Red Hat Display Italic',
                                  fontSize: '12px',
                                  paddingLeft: '8px',
                                }}>
                                {moment(row?.hoursRequestedOn).format(
                                  'MM/DD/YYYY',
                                )}
                              </Typography>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" width={'10%'}>
                          <Typography
                            component={'div'}
                            sx={{ alignItems: 'center', display: 'flex' }}>
                            {row?.timesheetFiles &&
                            row?.timesheetFiles?.length ? (
                              <CheckCircleIcon
                                style={{ color: '#1D9900', width: '18px' }}
                              />
                            ) : (
                              <CancelIcon
                                style={{ color: '#BF0000', width: '18px' }}
                              />
                            )}
                            {row?.timesheetRequestedOn && (
                              <Typography
                                component={'span'}
                                sx={{
                                  color: 'grey',
                                  fontFamily: 'Red Hat Display Italic',
                                  fontSize: '12px',
                                  paddingLeft: '8px',
                                }}>
                                {moment(row?.timesheetRequestedOn).format(
                                  'MM/DD/YYYY',
                                )}
                              </Typography>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" width={'5%'}>
                          {row?.approved ? (
                            <CheckCircleIcon
                              style={{ color: '#1D9900', width: '18px' }}
                            />
                          ) : (
                            <CancelIcon
                              style={{ color: '#BF0000', width: '18px' }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableCell
                    colSpan={8}
                    sx={{
                      color: '#241a2e',
                      fontFamily: 'Red Hat Display SemiBold',
                      fontSize: '14px',
                      padding: '10px !important',
                      textAlign: 'center',
                    }}>
                    No Records Found
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        {open3 && (
          <ClickAwayListener onClickAway={() => setOpen3(false)}>
            <Popper
              className="popwidget"
              open={open3}
              anchorEl={anchorEl3}
              placement={placement3}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    {['hours', 'timesheets'].includes(filterType) ? (
                      <Typography sx={{ p: 2 }}>
                        <Typography
                          onClick={() => filterChange(filterType, '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography>
                        <Typography
                          onClick={() => filterChange(filterType, 'true')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Submitted{' '}
                          {filterType === 'hours' ? 'Hours' : 'Timesheets'}
                        </Typography>
                        <Typography
                          onClick={() => filterChange(filterType, 'false')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Non Submitted{' '}
                          {filterType === 'hours' ? 'Hours' : 'Timesheets'}
                        </Typography>
                      </Typography>
                    ) : filterType === 'approved' ? (
                      <Typography sx={{ p: 2 }}>
                        <Typography
                          onClick={() => filterChange(filterType, '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography>
                        <Typography
                          onClick={() => filterChange(filterType, 'true')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Approved
                        </Typography>
                        <Typography
                          onClick={() => filterChange(filterType, 'false')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Not Approved
                        </Typography>
                      </Typography>
                    ) : filterType === 'Period' ? (
                      <Typography sx={{ p: 2 }}>
                        <Typography
                          onClick={() => filterChange(filterType, '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography>
                        {payPeriodList.length ? (
                          <>
                            <Typography
                              onClick={() =>
                                filterChange(filterType, {
                                  from: moment(
                                    payPeriodList[projectFilter.periodIndex]
                                      ?.startDate,
                                  ).format('YYYY-MM-DD'),
                                  to: moment(
                                    payPeriodList[projectFilter.periodIndex]
                                      ?.startDate,
                                  )
                                    .add(6, 'days')
                                    .format('YYYY-MM-DD'),
                                })
                              }
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {moment(
                                payPeriodList[projectFilter.periodIndex]
                                  ?.startDate,
                              ).format('MMM DD')}
                              {' - '}
                              {moment(
                                payPeriodList[projectFilter.periodIndex]
                                  ?.startDate,
                              )
                                .add(6, 'days')
                                .format('MMM DD YYYY')}
                            </Typography>
                            <Typography
                              onClick={() =>
                                filterChange(filterType, {
                                  from: moment(
                                    payPeriodList[projectFilter.periodIndex]
                                      ?.startDate,
                                  )
                                    .add(7, 'days')
                                    .format('YYYY-MM-DD'),
                                  to: moment(
                                    payPeriodList[projectFilter.periodIndex]
                                      ?.endDate,
                                  ).format('YYYY-MM-DD'),
                                })
                              }
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {moment(
                                payPeriodList[projectFilter.periodIndex]
                                  ?.startDate,
                              )
                                .add(7, 'days')
                                .format('MMM DD')}
                              {' - '}
                              {moment(
                                payPeriodList[projectFilter.periodIndex]
                                  ?.endDate,
                              ).format('MMM DD YYYY')}
                            </Typography>
                          </>
                        ) : (
                          <Typography />
                        )}
                      </Typography>
                    ) : filterType === 'branch' ? (
                      <Typography sx={{ p: 2 }}>
                        {/* <Typography
                          onClick={() => filterChange('branch', '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography> */}
                        {branchList.map((item: any) => {
                          return (
                            <Typography
                              key={item?.branch}
                              onClick={() =>
                                filterChange('branch', item?.branch)
                              }
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {item?.branch}
                            </Typography>
                          );
                        })}
                        <Typography
                          key={'Blank'}
                          onClick={() => filterChange('branch', 'null')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          {'Blank'}
                        </Typography>
                      </Typography>
                    ) : filterType === 'lob' ? (
                      <Typography sx={{ p: 2 }}>
                        {/* <Typography
                          onClick={() => filterChange('lob', '')}
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography> */}
                        {lobList.map((item: any) => {
                          return (
                            <Typography
                              key={item?.branch}
                              onClick={() => filterChange('lob', item?.lob)}
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {item?.lob}
                            </Typography>
                          );
                        })}
                      </Typography>
                    ) : filterType === 'collectionFrequency' ? (
                      <Typography sx={{ p: 2 }}>
                        {/* <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', '')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Clear
                        </Typography> */}
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'W')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          W
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'M')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          M
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'W/M')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          W/M
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'BM')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          BM
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'BW')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          BW
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'M-t/s')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          M-t/s
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'W/BW')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          W/BW
                        </Typography>
                        <Typography
                          onClick={() =>
                            filterChange('collectionFrequency', 'Th-Alt. W')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          Th-Alt. W
                        </Typography>
                        <Typography
                          key={'Blank'}
                          onClick={() =>
                            filterChange('collectionFrequency', 'null')
                          }
                          component={'p'}
                          sx={{
                            cursor: 'pointer',
                            margin: '10px',
                            marginLeft: '3px',
                          }}>
                          {'Blank'}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography />
                    )}
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )}
        <Modal
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              Message for employee
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography component={'div'}>
                <label>Message</label>
                <TextField
                  sx={{ p: 0 }}
                  className="common-textarea-timereq"
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  inputProps={{
                    maxLength: 250,
                  }}
                  value={message}
                  onChange={(e: any) => setMessage(e?.target?.value)}
                />
              </Typography>
            </Typography>
            <Typography sx={{ paddingTop: '15px' }} />
            <Divider />
            <Typography sx={{ paddingTop: '15px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button onClick={() => setModalOpen(false)} variant="text">
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Send Request'}
                form={'sendReq'}
                onPress={submitRequest}
                loading={loading}
              />
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={notesOpen.open}
          onClose={handleNotesClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              PROJECT NOTES
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {notesOpen.showViews && (
                <Typography
                  component={'div'}
                  sx={{
                    maxHeight: '40vh',
                    overflowY:
                      notesOpen.notesData.length >= 3 ? 'scroll' : 'hidden',
                    width: '100%',
                  }}>
                  {!_.isEmpty(notesOpen.notesData) &&
                  notesOpen.notesData.length > 0
                    ? notesOpen.notesData.map((note, index) => (
                        <React.Fragment key={index}>
                          <Typography sx={{ paddingTop: '20px' }} />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex' }}>
                            <Typography
                              component={'div'}
                              sx={notesStyle.userName}>
                              {note.user?.firstName} {note.user?.lastName}
                            </Typography>
                            <Typography
                              component={'div'}
                              sx={notesStyle.createDate}>
                              {moment(note?.createdAt).format('MM/DD/YYYY')}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'div'}
                            sx={notesStyle.notesText}>
                            {note?.note}
                          </Typography>
                          <Typography sx={{ paddingTop: '20px' }} />
                          <Divider />
                        </React.Fragment>
                      ))
                    : ''}
                </Typography>
              )}

              <form
                onSubmit={handleAddNotesSubmit(submitaddNotes)}
                id={'AddNotes'}>
                <Typography component={'div'}>
                  <label>Enter notes</label>
                  <Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="note"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            className="common-textarea"
                            id="outlined-multiline-static"
                            isTextArea={true}
                            minRows={3}
                            type={'text'}
                            name={name}
                            placeHolder={'Add Notes'}
                            value={value}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                    {formState.errors?.note?.message && (
                      <Typography className="errorText">
                        {formState.errors?.note?.message}
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </form>
            </Typography>
            <Typography sx={{ paddingTop: '15px' }} />
            <Divider />
            <Typography sx={{ paddingTop: '15px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button onClick={handleNotesClose} variant="text">
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Save Notes'}
                type="submit"
                form="AddNotes"
                loading={loading}
              />
            </Typography>
          </Box>
        </Modal>
      </Typography>
    </LoadingOverlay>
  );
};

export default ConsultantProjects;
