export const PublicRoutes = {
  CREATEPASSWORD: '/password',
  FORGOTPASSWORD: '/forgot-password',
  LOGIN: '/login',
  RESETPASSWORD: '/reset-password',
  VERSIONHISTORY: '/version-history',
  VERSIONHISTORYADD: '/version-history/add',
};
export const AdminPrivateRoutes = {
  ADMINS: '/admin/admins',
  CLIENTS: '/admin/clients',
  CLIENTSSUB: '/admin/clients/sub/:clientId',
  CONSULTANTS: '/admin/consultants',
  CONSULTANTSPROFILE: '/admin/consultants/profile/:consultantId',
  CONSULTANTSSUB: '/admin/consultants/sub',
  DASHBOARD: '/admin/dashboard',
  LEAVETRACKER: '/admin/leavetracker',
  MYPROFILE: '/admin/profile',
  PAYPERIOD: '/admin/payperiod',
  PAYROLLSUMMARY: '/admin/payrollsummary',
  PROJECTS: '/admin/projects',
  QUEUE: '/admin/queue',
  USERS: '/admin/users',
  VERSIONHISTORYADD: '/version-history/add',
};
export const ConsultantRoutes = {
  LEAVE: '/consultant/leave',
  PAYSHEET: '/consultant/paysheet',
  PROFILE: '/consultant/profile',
  TIMESHEET: '/consultant/timesheet',
};
