import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import HideColumns from 'components/PayrollSummary/hide-columns';
import moment from 'moment';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation } from 'react-router-dom';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT, PAYSHEET } from 'utils/Constant';

import PayPeriodAccordian from './PayPeriodAccordian';

const PayrollSummary: React.FC<any> = () => {
  const location: any = useLocation();
  const childRef: any = React.useRef();
  const [expanded, setExpanded] = React.useState('');
  const [userList, setUserList] = React.useState<any>([]);
  const [editObject, setEditObject] = React.useState<any>([]);
  const [hiddenColumns, setHiddenColumns] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [payPeriodData, setPayPeriodData] = React.useState<{
    loader: boolean;
    currentData: any;
    pastData: any;
  }>({
    currentData: [],
    loader: true,
    pastData: [],
  });
  const { companyStatus }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const getUsers = React.useCallback(() => {
    let url =
      MASTER_ENDPOINT.Admins +
      '?excludeRoles=7' +
      '&consultantStatus=active,terminated,offproject&jobWcCode=1SALARY,2HOURLY,3P/I&status=true';
    if (companyStatus !== 'all') {
      url += `&organization=${String(companyStatus)}`;
    }
    getAllListData(url)
      .then((resp: any) => {
        setUserList(resp.data);
      })
      .catch((err: any) => {});
  }, [companyStatus]);

  React.useEffect(() => {
    getUsers();
  }, [companyStatus, getUsers]);

  React.useEffect(() => {
    getPayPeriodData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayPeriodData = () => {
    getAllListData(MASTER_ENDPOINT.Payperiods)
      .then((resp: any) => {
        if (resp?.data.length) {
          const filterData = resp?.data;
          if (filterData) {
            const dateArray = [];
            filterData.map((row: any) => {
              row.payPeriodQuarters.map((item: any) => {
                item.payPeriods.map((item1: any) => {
                  dateArray.push(item1);
                });
              });
            });
            if (dateArray.length) {
              const dateArrayFilter = dateArray.filter((date: any) =>
                moment(date.startDate).isBefore(),
              );
              const pastDates = dateArrayFilter.filter((date: any) =>
                moment(date.payDate).isBefore(),
              );
              const currentDates = dateArrayFilter.filter((date: any) =>
                moment(date.payDate).isAfter(),
              );
              if (location?.state && location?.state?.payrollSummaryId) {
                setExpanded(location?.state?.payrollSummaryId);
              } else {
                if (currentDates.length) {
                  setExpanded(currentDates[0]?.id);
                }
              }
              const datePastAObject = pastDates.sort(function (a, b) {
                const c: any = new Date(a.startDate);
                const d: any = new Date(b.startDate);
                return d - c;
              });
              setPayPeriodData((pre: any) => {
                return {
                  ...pre,
                  currentData: currentDates,
                  loader: false,
                  pastData: datePastAObject,
                };
              });
            }
          }
        }
      })
      .finally(() => {
        setPayPeriodData((pre: any) => {
          return {
            ...pre,
            loader: false,
          };
        });
      });
  };

  const cancelEdit = () => {
    setEditObject([]);
  };

  const saveEdit = async () => {
    const postData = [];
    await editObject.map((item: any) => {
      postData.push({
        additionalPay: item.summary_additionalPay,
        adjustDedAmount1: item.summary_adjustDedAmount1,
        adjustDedAmount2: item.summary_adjustDedAmount2,
        adjustDedAmount3: item.summary_adjustDedAmount3,
        adjustDedAmount4: item.summary_adjustDedAmount4,
        adjustDedAmount5: item.summary_adjustDedAmount5,
        adjustDedAmount6: item.summary_adjustDedAmount6,
        adjustDedAmount7: item.summary_adjustDedAmount7,
        adjustDedAmount8: item.summary_adjustDedAmount8,
        adjustDedCode1: item.summary_adjustDedCode1,
        adjustDedCode2: item.summary_adjustDedCode2,
        adjustDedCode3: item.summary_adjustDedCode3,
        adjustDedCode4: item.summary_adjustDedCode4,
        adjustDedCode5: item.summary_adjustDedCode5,
        adjustDedCode6: item.summary_adjustDedCode6,
        adjustDedCode7: item.summary_adjustDedCode7,
        adjustDedCode8: item.summary_adjustDedCode8,
        basePay: item.summary_basePay,
        baseWage: item.summary_baseWage,
        batchId: item.summary_batchId,
        currentAdjustDedAmount1: item.summary_currentAdjustDedAmount1,
        currentAdjustDedAmount2: item.summary_currentAdjustDedAmount2,
        currentAdjustDedAmount3: item.summary_currentAdjustDedAmount3,
        currentAdjustDedAmount4: item.summary_currentAdjustDedAmount4,
        currentAdjustDedAmount5: item.summary_currentAdjustDedAmount5,
        currentAdjustDedAmount6: item.summary_currentAdjustDedAmount6,
        currentAdjustDedAmount7: item.summary_currentAdjustDedAmount7,
        currentAdjustDedAmount8: item.summary_currentAdjustDedAmount8,
        currentAdjustDedCode1: item.summary_currentAdjustDedCode1,
        currentAdjustDedCode2: item.summary_currentAdjustDedCode2,
        currentAdjustDedCode3: item.summary_currentAdjustDedCode3,
        currentAdjustDedCode4: item.summary_currentAdjustDedCode4,
        currentAdjustDedCode5: item.summary_currentAdjustDedCode5,
        currentAdjustDedCode6: item.summary_currentAdjustDedCode6,
        currentAdjustDedCode7: item.summary_currentAdjustDedCode7,
        currentAdjustDedCode8: item.summary_currentAdjustDedCode8,
        currentEarnings3Amount1: item.summary_currentEarnings3Amount1,
        currentEarnings3Amount2: item.summary_currentEarnings3Amount2,
        currentEarnings3Amount3: item.summary_currentEarnings3Amount3,
        currentEarnings3Amount4: item.summary_currentEarnings3Amount4,
        currentEarnings3Amount5: item.summary_currentEarnings3Amount5,
        currentEarnings3Amount6: item.summary_currentEarnings3Amount6,
        currentEarnings3Amount7: item.summary_currentEarnings3Amount7,
        currentEarnings3Amount8: item.summary_currentEarnings3Amount8,
        currentEarnings3Amount9: item.summary_currentEarnings3Amount9,
        currentEarnings3Code1: item.summary_currentEarnings3Code1,
        currentEarnings3Code2: item.summary_currentEarnings3Code2,
        currentEarnings3Code3: item.summary_currentEarnings3Code3,
        currentEarnings3Code4: item.summary_currentEarnings3Code4,
        currentEarnings3Code5: item.summary_currentEarnings3Code5,
        currentEarnings3Code6: item.summary_currentEarnings3Code6,
        currentEarnings3Code7: item.summary_currentEarnings3Code7,
        currentEarnings3Code8: item.summary_currentEarnings3Code8,
        currentEarnings3Code9: item.summary_currentEarnings3Code9,
        currentHours3Amount1: item.summary_currentHours3Amount1,
        currentHours3Amount2: item.summary_currentHours3Amount2,
        currentHours3Amount3: item.summary_currentHours3Amount3,
        currentHours3Code1: item.summary_currentHours3Code1,
        currentHours3Code2: item.summary_currentHours3Code2,
        currentHours3Code3: item.summary_currentHours3Code3,
        doNotPay: item.summary_doNotPay,
        earnings3Amount1: item.summary_earnings3Amount1,
        earnings3Amount2: item.summary_earnings3Amount2,
        earnings3Amount3: item.summary_earnings3Amount3,
        earnings3Amount4: item.summary_earnings3Amount4,
        earnings3Amount5: item.summary_earnings3Amount5,
        earnings3Amount6: item.summary_earnings3Amount6,
        earnings3Amount7: item.summary_earnings3Amount7,
        earnings3Amount8: item.summary_earnings3Amount8,
        earnings3Amount9: item.summary_earnings3Amount9,
        earnings3Code1: item.summary_earnings3Code1,
        earnings3Code2: item.summary_earnings3Code2,
        earnings3Code3: item.summary_earnings3Code3,
        earnings3Code4: item.summary_earnings3Code4,
        earnings3Code5: item.summary_earnings3Code5,
        earnings3Code6: item.summary_earnings3Code6,
        earnings3Code7: item.summary_earnings3Code7,
        earnings3Code8: item.summary_earnings3Code8,
        earnings3Code9: item.summary_earnings3Code9,
        grossEarnings: item.summary_grossEarnings,
        hours3Amount1: item.summary_hours3Amount1,
        hours3Amount2: item.summary_hours3Amount2,
        hours3Amount3: item.summary_hours3Amount3,
        hours3Code1: item.summary_hours3Code1,
        hours3Code2: item.summary_hours3Code2,
        hours3Code3: item.summary_hours3Code3,
        id: item.summary_id,
        isVerified: item.summary_isVerified,
        notes: item.summary_notes,
        payPeriodId: item.summary_payPeriodId,
        payPeriodYear: item.summary_payPeriodYear,
        project: item.project_customerName,
        psAdjustedAmount: item.summary_psAdjustedAmount,
        regEarnings: item.summary_regEarnings,
        regHours: item?.summary_regHours_orginal
          ? item?.summary_regHours_orginal
          : item.summary_regHours,
      });
    });
    updateData(
      '',
      { payrollSummary: postData },
      PAYSHEET.Payroll + '/summary',
      true,
    )
      .then((resp: any) => {
        cancelEdit();
        childRef.current.reloadData();
      })
      .catch((e) => {
        // cancelEdit();
      });
  };

  // const renderData = React.useCallback(
  //   (item, index) => {
  //     return (
  //       <PayPeriodAccordian
  //         key={index}
  //         expanded={expanded === item.id ? expanded : ''}
  //         item={item}
  //         index={index}
  //         setExpanded={setExpanded}
  //         setEditObject={setEditObject}
  //         userList={expanded === item.id ? userList : []}
  //         tableData={expanded === item.id ? [] : []}
  //       />
  //     );
  //   },
  //   [expanded, userList],
  // );

  const renderContent = React.useMemo(() => {
    return (
      <>
        {payPeriodData.currentData.map((item: any, index: number) => {
          return (
            <PayPeriodAccordian
              ref={childRef}
              key={index}
              expanded={expanded === item.id ? expanded : ''}
              item={item}
              index={index}
              setExpanded={setExpanded}
              setEditObject={setEditObject}
              userList={expanded === item.id ? userList : []}
              tableData={expanded === item.id ? [] : []}
              hiddenColumns={hiddenColumns}
            />
          );
        })}
        <Typography component="h2">Past Payroll Summaries</Typography>
        {payPeriodData.pastData.map((item: any, index: number) => {
          return (
            <PayPeriodAccordian
              ref={childRef}
              key={index}
              expanded={expanded === item.id ? expanded : ''}
              item={item}
              index={index}
              setExpanded={setExpanded}
              setEditObject={setEditObject}
              userList={expanded === item.id ? userList : []}
              tableData={expanded === item.id ? [] : []}
              hiddenColumns={hiddenColumns}
            />
          );
        })}
      </>
    );
  }, [
    expanded,
    payPeriodData.currentData,
    payPeriodData.pastData,
    userList,
    hiddenColumns,
  ]);

  return (
    <LoadingOverlay active={payPeriodData.loader} spinner text="loading...">
      <HideColumns setHiddenColumns={setHiddenColumns} />
      <Typography
        sx={{ p: 2 }}
        component={'div'}
        className="common-accordin payroll-box">
        <Grid container>
          <Grid item md={10}>
            <Typography component="h2">Current Payroll Summary</Typography>
          </Grid>
          {editObject.length ? (
            <Grid
              item
              md={2}
              sx={{
                alignSelf: 'center !important',
                textAlign: 'end !important',
              }}>
              <Button
                onClick={cancelEdit}
                sx={{
                  border: '1px solid #E0E0E0',
                  color: '#292929',
                  fontFamily: 'Red Hat Display Bold',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  textTransform: 'capitalize',
                }}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                onClick={saveEdit}
                sx={{
                  '&:hover': {
                    backgroundColor: '#BF0000',
                    color: '#FFFFFF',
                  },
                  backgroundColor: '#BF0000',
                  border: '1px solid #E0E0E0',
                  color: '#FFFFFF',
                  fontFamily: 'Red Hat Display Bold',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  textTransform: 'capitalize',
                }}>
                Save
              </Button>
            </Grid>
          ) : (
            <Grid item md={2} />
          )}
        </Grid>
        {renderContent}
        {/* {payPeriodData.currentData.map((item: any, index: number) => {
          return renderData(item, index);
        })}
        <Typography component="h2">Past Payroll Summaries</Typography>
        {payPeriodData.pastData.map((item: any, index: number) => {
          return renderData(item, index);
        })} */}
      </Typography>
    </LoadingOverlay>
  );
};

export default PayrollSummary;
