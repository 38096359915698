import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  colheadersticky: {
    background: '#DCE6EC',
    zIndex: '1',
  },
  colsticky: {
    background: '#EDF3F7',
    zIndex: '1',
  },
  header: {
    alignItems: 'center',
    background: '#fff',
    padding: 25,
  },
  // header: {
  //   alignItems: 'center',
  //   background: '#fff',
  //   marginTop: 5,
  //   padding: 15,
  // },
  summarytable: {
    '& tbody': {
      '& th': {
        borderBottom: '0px solid red',
        padding: '10px',
      },
      '& tr, td': {
        border: '1px solid #eee',
        padding: '10px',
      },
    },
    '& thead': {
      '& th': {
        padding: '5px 10px',
      },
      background: '#DCE6EC',
    },
  },
}));

export default useStyles;
