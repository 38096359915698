import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backText: {
    color: '#005EBF',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 12,
  },
  commondownload: {
    border: '1px solid #E0E0E0 !important',
    borderRadius: '10px !important',
  },
  commondrop: {
    '& li': {
      color: '#fff',
      fontFamily: 'Red Hat Display Bold !important',
      fontSize: '15px !important',
      lineHeight: '36px',
    },
  },
  custominput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px 12.5px 20px !important',
    },
    width: '100%',
  },
  defaultpagination: {
    '& .MuiTablePagination-actions': {
      '& .MuiButtonBase-root': {
        '&:last-child': {
          borderRadius: '0px 10px 10px 0px',
        },
        border: '1px solid #E0E0E0',
        borderRadius: '10px 0px 0px 10px',
      },
    },
  },
  defaulttable: {
    '& tbody': {
      '& th, td': {
        color: '#292929',
        fontFamily: 'Red Hat Display Medium',
        fontSize: '13px',
        fontStyle: 'normal',
        padding: '0px',
      },
      '& tr': {
        borderBottom: '1px solid #eee',
      },
    },

    '& thead': {
      '& th': {
        '& em': {
          color: '#292929',
          fontFamily: 'Red Hat Display Bold',
          fontSize: '11px',
          fontStyle: 'normal',
        },
        '& span': {
          margin: '2px 10px',
        },
        color: '#979598',
        fontFamily: 'Red Hat Display Regular',
        fontSize: '12px',
        padding: '0',
      },
      background: '#F8F8FA',
    },
  },
  head2: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 20,
  },
  header1: {
    alignItems: 'center',
    background: '#fff',
    marginTop: 0,
  },
  header2: {
    alignItems: 'center',
    background: '#fff',
    height: '60px',
    marginTop: 0,
    position: 'fixed',
    zIndex: 1000,
  },
  label1: {
    color: '#979598',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 14,
  },

  label2: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 14,
  },
  label21: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    fontSize: 14,
    width: 3,
  },
  payinfo: {
    '& li': {
      display: 'inline-block',
      margin: '8px 10px',
      paddingRight: '5px',
    },
    background: '#fff',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 20%)',
    listStyle: 'none',
    padding: '0px',
    width: '100%',
  },
  profilebox: {
    '& img': {
      background: '#eee',
      borderRadius: '50%',
      height: '40px',
      padding: '5px',
      width: '40px',
    },
    '& span': {
      color: '#292929',
      fontFamily: 'Red Hat Display medium',
      fontSize: '24px',
      padding: '0 10px',
    },
    alignItems: 'center',
    display: 'flex',
  },
  profileinfo: {
    display: 'flex',
    margin: '10px 0',
  },
  profilewidget: {
    width: '20%',
  },
  profilewidgetbox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    paddingTop: '80px',
    width: '100%',
  },

  subheader: {
    '& .MuiButtonGroup-grouped': {
      '&:hover': {
        background: '#005EBF',
      },
      background: '#005EBF',
      borderColor: '#003F80 !important',
      fontFamily: 'Red Hat Display Bold',
      height: '40px',
      paddingLeft: '15px',
      textTransform: 'capitalize',
    },
    '& p': {
      '& span': {
        '& svg': {
          Height: '25px',
          Width: '35px',
          position: 'relative',
          top: '6px',
        },
        fontFamily: 'Red Hat Display Bold',
      },
      color: '#979598',
      fontFamily: 'Red Hat Display Medium',
      fontSize: '14px',
      padding: '0 20px 0 0',
    },
    alignItems: 'center',
    background: '#fff',
    marginTop: 5,
    padding: '8px 20px',
  },
  subheader1: {
    '& .MuiButtonGroup-grouped': {
      '&:hover': {
        background: '#005EBF',
      },
      background: '#005EBF',
      borderColor: '#003F80 !important',
      fontFamily: 'Red Hat Display Bold',
      height: '40px',
      paddingLeft: '15px',
      textTransform: 'capitalize',
    },
    '& p': {
      '& span': {
        '& svg': {
          Height: '25px',
          Width: '35px',
          position: 'relative',
          top: '6px',
        },
        fontFamily: 'Red Hat Display Bold',
      },
      color: '#979598',
      fontFamily: 'Red Hat Display Medium',
      fontSize: '14px',
      padding: '0 20px 0 0',
    },
    alignItems: 'center',
    background: '#fff',
    marginTop: 5,
    padding: '8px 20px',
    position: 'fixed',
    top: 60,
    width: '100%',
    zIndex: 1000,
  },
}));

export default useStyles;
