export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const EMAIL_CHECKED = 'EMAIL_CHECKED';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
export const USER_SIDEMENU_SUCCESS = 'USER_SIDEMENU_SUCCESS';
export const USER_SIDEMENU_FAIL = 'USER_SIDEMENU_FAIL';
export const USER_IMAGE_ID_CHANGE = 'USER_IMAGE_ID_CHANGE';
export const USER_FILTER_STATUS = 'USER_FILTER_STATUS';
export const USER_FILTER_COMPANY_STATUS = 'USER_FILTER_COMPANY_STATUS';
export const USER_FILTER_COMPANY_STATUS_QUEUE =
  'USER_FILTER_COMPANY_STATUS_QUEUE';
export const USER_SEARCH = 'USER_SEARCH';
export const UPDATE_CONSULTANT_FILTER = 'UPDATE_CONSULTANT_FILTER';
export const UPDATE_PROJECT_FILTER = 'UPDATE_PROJECT_FILTER';
export const USER_PAYROLL_SUMMARY_BACK_ACTION =
  'USER_PAYROLL_SUMMARY_BACK_ACTION';
