/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Popover,
} from '@mui/material';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import OnLeaveImg from 'assets/images/OnLeave.svg';
import CheckboxCom from 'components/CheckBox';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import SignaturePad from 'react-signature-canvas';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ApplyLeaveForm1 } from 'types';
import { drawSignatureInfo } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { ApplyLeaveSchema1 } from 'utils/ValidatorSchema';

import useStyles from '../PrivateStyle';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  height: '90vh',
  left: '50%',
  maxHeight: 750,
  overflow: 'scroll',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
};

const leaveStyle = {
  closeText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    textDecoration: 'underLine',
  },
  employeeText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Medium !important',
    fontSize: '12px  !important',
    paddingLeft: '6px',
  },
  mediumText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '15px',
  },
  normalText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '12px',
  },
  normalTextWithIcon: {
    alignItems: 'center',
    color: '#292929',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Red Hat Display Regular',
    fontSize: '12px',
  },
};

export const LeaveApply: React.FC<any> = (props) => {
  const classes = useStyles();
  const sigPad = useRef<any>({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [leaveTypes, setLeaveTypes] = React.useState<any>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  const [dayLeave, setDayLeave] = React.useState('');
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    str: string,
  ) => {
    setDayLeave(str);
    setAnchorEl2(event.currentTarget);
  };
  const handleInfoClose = () => {
    setDayLeave('');
    setAnchorEl2(null);
  };
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover2' : undefined;

  useEffect(() => {
    getLeaveTypes();
  }, []);

  const getLeaveTypes = () => {
    getAllListData(MASTER_ENDPOINT.LeaveTypes)
      .then((resp: any) => {
        if (resp?.data) {
          const leaveList = [];
          resp?.data.map((row: any) => {
            if (![2, 6, 7, 8].includes(row.id)) {
              leaveList.push({
                label: row.id === 5 ? 'Other (Non-Medical)' : row.type,
                value: row.id.toString(),
              });
            }
          });
          setLeaveTypes(leaveList);
        }
      })
      .catch((err: any) => {});

    // addNewData({}, MASTER_ENDPOINT.CreateMaster, {}, true)
    //   .then((resp: any) => {})
    //   .catch((err: any) => {});
  };

  const { handleSubmit, control, formState, reset, watch, setValue, setError } =
    useForm<ApplyLeaveForm1>({
      defaultValues: {
        comments: '',
        communicateVia: '',
        eSignature: '',
        fromDate: null,
        leaveTypes: [],
        modeOfCommunication: '',
        paidOnLeaveComments: 'paid',
        payableDays: [
          {
            fromDate: '',
            toDate: '',
          },
        ],
        reasonComments: '',
        returnDate: null,
        returnToUsOn: null,
        toDate: null,
        travelingOutsideUS: '',
        wishToBePaidOnLeave: '',
        workingDays: [
          {
            fromDate: '',
            toDate: '',
          },
        ],
        workingWhileOnLeave: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(ApplyLeaveSchema1),
    });

  const {
    fields: workingDaysArray,
    append: workingDaysArrayAppend,
    remove: workingDaysArrayRomove,
  } = useFieldArray({ control, name: 'workingDays' });

  const {
    fields: payableDaysArray,
    append: payableDaysArrayAppend,
    remove: payableDaysArrayRomove,
  } = useFieldArray({ control, name: 'payableDays' });

  const submitForm = (data: any) => {
    setLoading(true);
    if (data['modeOfCommunication']) {
      if (data['modeOfCommunication'] === 'Phone') {
        if (!data['communicateVia']) {
          setError('communicateVia', {
            message: 'Phone Number is required',
            type: 'custom',
          });
          setLoading(false);
          return;
        }
      } else {
        if (!data['communicateVia']) {
          setError('communicateVia', {
            message: 'Skype is required',
            type: 'custom',
          });
          setLoading(false);
          return;
        }
      }
    }
    data['workingWhileOnLeave'] =
      data['workingWhileOnLeave'] === 'yes' ? true : false;
    data['travelingOutsideUS'] =
      data['travelingOutsideUS'] === 'yes' ? true : false;
    data['fromDate'] = moment(data['fromDate']).format('YYYY-MM-DD');
    data['toDate'] = moment(data['toDate']).format('YYYY-MM-DD');
    data['returnDate'] = moment(data['returnDate']).format('YYYY-MM-DD');
    data['returnToUsOn'] = data['returnToUsOn']
      ? moment(data['returnToUsOn']).format('YYYY-MM-DD')
      : null;
    data['status'] = 'pending';
    data['userId'] = userInfo.id;
    if (data['leaveTypes'].length) {
      data['leaveTypes'] = data['leaveTypes'].map((item: any) => {
        item['leaveTypeId'] = parseInt(item['leaveTypeId']);
        if (item['leaveTypeId'] === 5) {
          item['reason'] = data['reasonComments'];
        }
        return item;
      });
    }
    if (data['workingWhileOnLeave']) {
      if (data['workingDays'].length) {
        data['workingDays'] = data['workingDays'].map((item: any) => {
          item['fromDate'] = moment(item['fromDate']).format('YYYY-MM-DD');
          item['toDate'] = moment(item['toDate']).format('YYYY-MM-DD');
          return item;
        });
      }
    } else {
      data['workingDays'] = [];
    }
    if (data['wishToBePaidOnLeave'] === 'yes') {
      if (data['payableDays'].length) {
        data['payableDays'] = data['payableDays'].map((item: any) => {
          item['fromDate'] = moment(item['fromDate']).format('YYYY-MM-DD');
          item['toDate'] = moment(item['toDate']).format('YYYY-MM-DD');
          return item;
        });
      }
    } else {
      data['payableDays'] = [];
    }
    addNewData(data, MASTER_ENDPOINT.Leaves, {}, true)
      .then((resp: any) => {
        setLoading(false);
        resetForm();
        props.getLeaveHistory(userInfo.id);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const resetForm = () => {
    reset();
    handleClose();
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <React.Fragment>
      <Card
        sx={{
          background: 'linear-gradient(180deg, #1D8AEA 0%, #5A5EC1 100%)',
          boxShadow: '0 6px 14px 0 rgb(60 67 156 / 40%)',
          height: '260px',
          marginTop: '35px',
          width: '400px',
        }}>
        <CardContent>
          <Typography
            component={'div'}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Red Hat Display Bold',
              fontSize: '19px',
              lineHeight: '25px',
              paddingTop: '10px',
              textAlign: 'center',
            }}>
            Are You Planning To Go On Leave?
          </Typography>
          <Typography
            component={'div'}
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Red Hat Display SemiBold',
              fontSize: '14px',
              lineHeight: '18px',
              paddingTop: '5px',
              textAlign: 'center',
            }}>
            Fill out the Leave Request Form for Approval
          </Typography>
          <Typography sx={{ height: '10px' }} />
          <Typography sx={{ height: '115px', textAlign: 'center !important' }}>
            <img src={OnLeaveImg} style={{ height: '115px' }} />
          </Typography>
          <Typography sx={{ height: '10px' }} />
          <Typography
            component={'div'}
            sx={{
              textAlign: 'center',
            }}>
            <Button
              color="inherit"
              onClick={handleOpen}
              sx={{
                backgroundColor: '#409AFB',
                borderRadius: '20px',
                borderWidth: '0px',
                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
                color: '#FFFFFF',
                fontFamily: 'Red Hat Display Bold',
                fontSize: '14px',
                marginRight: '10px',
                textTransform: 'capitalize',
                width: '85%',
              }}>
              Leave Request Form
            </Button>
          </Typography>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form id={'leaveApply'} onSubmit={handleSubmit(submitForm, onError)}>
          <Box sx={style} className="model-widget">
            <Grid container>
              <Grid item xs={10}>
                <Typography id="modal-modal-title" component="h2">
                  Leave Request Form
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component={'div'}
                  sx={{
                    textAlign: 'right',
                  }}>
                  <a
                    href={'#'}
                    onClick={resetForm}
                    style={leaveStyle.closeText}>
                    Close
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: '10px' }}>
              <Grid item xs={3} sx={{ padding: '10px', paddingLeft: '0px' }}>
                <Typography sx={leaveStyle.normalTextWithIcon}>
                  First Day of Leave<span className="errorText">{'*'}</span>
                  <InfoIcon
                    sx={{
                      color: '#e0e0e4',
                      cursor: 'pointer',
                      fontSize: '24px',
                    }}
                    aria-describedby={id2}
                    onClick={(e: any) => handleInfoClick(e, 'first')}
                  />
                </Typography>
                <Typography component={'div'} sx={{ paddingTop: '8px' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="fromDate"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <DatePicker
                          placeHolder="Select"
                          value={value}
                          name={name}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.fromDate?.message}
                          // minDate={moment()}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ padding: '10px', paddingLeft: '0px' }}>
                <Typography sx={leaveStyle.normalTextWithIcon}>
                  Last Day of Leave<span className="errorText">{'*'}</span>
                  <InfoIcon
                    sx={{
                      color: '#e0e0e4',
                      cursor: 'pointer',
                      fontSize: '24px',
                    }}
                    aria-describedby={id2}
                    onClick={(e: any) => handleInfoClick(e, 'last')}
                  />
                </Typography>
                <Typography component={'div'} sx={{ paddingTop: '8px' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="toDate"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <DatePicker
                          placeHolder="Select"
                          value={value}
                          name={name}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.toDate?.message}
                          disabled={watch('fromDate') ? false : true}
                          minDate={watch('fromDate')}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ padding: '10px', paddingLeft: '0px' }}>
                <Typography sx={leaveStyle.normalText}>
                  Exact Return to Work Date
                  <span className="errorText">{'*'}</span>
                </Typography>
                <Typography component={'div'} sx={{ paddingTop: '16px' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="returnDate"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <DatePicker
                          placeHolder="Select"
                          value={value}
                          name={name}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.returnDate?.message}
                          disabled={watch('fromDate') ? false : true}
                          minDate={watch('fromDate')}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ alignItems: 'center', paddingTop: '8px' }}>
              <Grid item xs={5}>
                <Typography sx={leaveStyle.mediumText}>
                  Will you be traveling outside the U.S.?
                  <span className="errorText">{'*'}</span>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="travelingOutsideUS"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CheckboxCom
                        name={name}
                        checkboxArray={[
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ]}
                        onChange={(e) => {
                          if (e === 'no') {
                            setValue('returnToUsOn', null);
                          }
                          onChange(e);
                        }}
                        value={value}
                        error={formState.errors?.travelingOutsideUS?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {watch('travelingOutsideUS') === 'yes' && (
              <Grid container sx={{ paddingLeft: '20px' }}>
                <Grid item xs={12}>
                  <Typography sx={leaveStyle.normalText}>
                    Enter date of return/re-entry to the U.S.
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                  <Typography sx={{ paddingTop: '5px' }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="returnToUsOn"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder="Select"
                            value={value}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.returnToUsOn?.message}
                            disabled={
                              watch('fromDate') && watch('returnDate')
                                ? false
                                : true
                            }
                            minDate={watch('fromDate')}
                            maxDate={watch('returnDate')}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container sx={{ alignItems: 'center', paddingTop: '8px' }}>
              <Grid item xs={5}>
                <Typography sx={leaveStyle.mediumText}>
                  Will you be Working While on Leave?
                  <span className="errorText">{'*'}</span>
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="workingWhileOnLeave"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CheckboxCom
                        name={name}
                        checkboxArray={[
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ]}
                        onChange={(e) => {
                          // if (e === 'no') {
                          //   setValue('workingFromDate', null);
                          //   setValue('workingToDate', null);
                          // }
                          onChange(e);
                        }}
                        value={value}
                        error={formState.errors?.workingWhileOnLeave?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {watch('workingWhileOnLeave') === 'yes' && (
              <Grid container sx={{ paddingLeft: '20px' }}>
                <Grid item xs={12}>
                  <Typography sx={leaveStyle.normalText}>
                    If yes, please indicate the dates you will be working
                  </Typography>
                </Grid>
                {/* <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                  <Typography sx={{ paddingTop: '5px' }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="workingFromDate"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder="Select"
                            value={value}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.workingFromDate?.message}
                            minDate={watch('fromDate')}
                            maxDate={watch('toDate')}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ paddingTop: '5px' }}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="workingToDate"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder="Select"
                            value={value}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.workingToDate?.message}
                            minDate={watch('workingFromDate')}
                            maxDate={watch('returnDate')}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid> */}
                {workingDaysArray.length &&
                  workingDaysArray.map((item: any, index: number) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                          <Typography sx={{ paddingTop: '5px' }}>
                            <Controller
                              control={control}
                              defaultValue=""
                              name={`workingDays.${index}.fromDate`}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                let workMinDate1 = watch('fromDate');
                                if (index !== 0) {
                                  workMinDate1 = new Date(
                                    watch('workingDays')?.[index - 1]?.toDate,
                                  );
                                  workMinDate1.setDate(
                                    workMinDate1.getDate() + 1,
                                  );
                                }
                                return (
                                  <DatePicker
                                    placeHolder="Select"
                                    value={value}
                                    name={name}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                    error={
                                      formState.errors?.workingDays?.[index]
                                        ?.fromDate?.message
                                    }
                                    minDate={workMinDate1}
                                    maxDate={watch('returnDate')}
                                    disabled={
                                      index === 0 &&
                                      watch('fromDate') &&
                                      watch('returnDate')
                                        ? false
                                        : watch('workingDays')?.[index - 1]
                                            ?.toDate
                                        ? false
                                        : true
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography sx={{ paddingTop: '5px' }}>
                            <Controller
                              control={control}
                              defaultValue=""
                              name={`workingDays.${index}.toDate`}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                const workMinDate = new Date(
                                  watch('workingDays')?.[index]?.fromDate,
                                );
                                const workMaxDate = watch('returnDate');
                                return (
                                  <DatePicker
                                    placeHolder="Select"
                                    value={value}
                                    name={name}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                    error={
                                      formState.errors?.workingDays?.[index]
                                        ?.toDate?.message
                                    }
                                    minDate={workMinDate}
                                    maxDate={workMaxDate}
                                    disabled={
                                      watch('workingDays')?.[index]?.fromDate
                                        ? false
                                        : true
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            alignSelf: 'center',
                            paddingLeft: '10px',
                            paddingTop: '5px',
                          }}>
                          {index !== 0 && (
                            <a
                              href={'javascript:void(0)'}
                              style={{ paddingRight: 8 }}
                              onClick={() => workingDaysArrayRomove(index)}>
                              <RemoveCircleOutlineIcon
                                sx={{
                                  color: '#e0e0e4',
                                  fontSize: '35px',
                                }}
                              />
                            </a>
                          )}
                          {index === workingDaysArray.length - 1 && (
                            <a
                              href={'javascript:void(0)'}
                              onClick={() =>
                                workingDaysArrayAppend({
                                  fromDate: '',
                                  toDate: '',
                                })
                              }>
                              <AddCircleOutlineIcon
                                sx={{
                                  color: '#e0e0e4',
                                  fontSize: '35px',
                                }}
                              />
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
            <Grid container sx={{ alignItems: 'center', paddingTop: '8px' }}>
              <Grid item xs={5}>
                <Typography sx={leaveStyle.mediumText}>
                  Do You Wish to be Paid While on Leave?
                  <span className="errorText">{'*'}</span>
                </Typography>
                <Typography
                  sx={{
                    color: '#BF0000',
                    fontFamily: 'Red Hat Display Regular',
                    fontSize: '12px',
                    paddingTop: '5px',
                  }}>
                  (must have approval from your supervisor and Payroll)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="wishToBePaidOnLeave"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CheckboxCom
                        name={name}
                        checkboxArray={[
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ]}
                        onChange={(e) => onChange(e)}
                        value={value}
                        error={formState.errors?.wishToBePaidOnLeave?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {watch('wishToBePaidOnLeave') === 'yes' && (
              <Grid container sx={{ paddingLeft: '20px' }}>
                {payableDaysArray.length &&
                  payableDaysArray.map((item: any, index: number) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={3} sx={{ paddingRight: '10px' }}>
                          <Typography sx={{ paddingTop: '5px' }}>
                            <Controller
                              control={control}
                              defaultValue=""
                              name={`payableDays.${index}.fromDate`}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                let payableMinDate1 = watch('fromDate');
                                if (index !== 0) {
                                  payableMinDate1 = new Date(
                                    watch('payableDays')?.[index - 1]?.toDate,
                                  );
                                  payableMinDate1.setDate(
                                    payableMinDate1.getDate() + 1,
                                  );
                                }
                                return (
                                  <DatePicker
                                    placeHolder="Select"
                                    value={value}
                                    name={name}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                    error={
                                      formState.errors?.payableDays?.[index]
                                        ?.fromDate?.message
                                    }
                                    minDate={payableMinDate1}
                                    maxDate={watch('returnDate')}
                                    disabled={
                                      index === 0 &&
                                      watch('fromDate') &&
                                      watch('returnDate')
                                        ? false
                                        : watch('payableDays')?.[index - 1]
                                            ?.toDate
                                        ? false
                                        : true
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography sx={{ paddingTop: '5px' }}>
                            <Controller
                              control={control}
                              defaultValue=""
                              name={`payableDays.${index}.toDate`}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                const payableMinDate = new Date(
                                  watch('payableDays')?.[index]?.fromDate,
                                );
                                const payableMaxDate = watch('returnDate');
                                return (
                                  <DatePicker
                                    placeHolder="Select"
                                    value={value}
                                    name={name}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                    error={
                                      formState.errors?.payableDays?.[index]
                                        ?.toDate?.message
                                    }
                                    minDate={payableMinDate}
                                    maxDate={payableMaxDate}
                                    disabled={
                                      watch('payableDays')?.[index]?.fromDate
                                        ? false
                                        : true
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            alignSelf: 'center',
                            paddingLeft: '10px',
                            paddingTop: '5px',
                          }}>
                          {index !== 0 && (
                            <a
                              href={'javascript:void(0)'}
                              style={{ paddingRight: 8 }}
                              onClick={() => payableDaysArrayRomove(index)}>
                              <RemoveCircleOutlineIcon
                                sx={{
                                  color: '#e0e0e4',
                                  fontSize: '35px',
                                }}
                              />
                            </a>
                          )}
                          {index === payableDaysArray.length - 1 && (
                            <a
                              href={'javascript:void(0)'}
                              onClick={() =>
                                payableDaysArrayAppend({
                                  fromDate: '',
                                  toDate: '',
                                })
                              }>
                              <AddCircleOutlineIcon
                                sx={{
                                  color: '#e0e0e4',
                                  fontSize: '35px',
                                }}
                              />
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
            <Grid container sx={{ alignItems: 'center', paddingTop: '20px' }}>
              <Grid item xs={12}>
                <Typography sx={leaveStyle.mediumText}>
                  Reason for Leave?<span className="errorText">{'*'}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="leaveTypes"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CheckboxCom
                        name={name}
                        checkboxArray={leaveTypes}
                        onChange={(e) => onChange(e)}
                        multi={true}
                        value={value}
                        error={formState.errors?.leaveTypes?.message}
                      />
                    );
                  }}
                />
              </Grid>
              {watch('leaveTypes') &&
                watch('leaveTypes').map((item: any, index: number) => {
                  if (Number(item.leaveTypeId) === 5) {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                        <Controller
                          control={control}
                          defaultValue=""
                          name="reasonComments"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder="please explain"
                                value={value}
                                name={name}
                                className={classes.custominput}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={
                                  formState.errors?.reasonComments?.message
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                    );
                  }
                  return;
                })}
              <Grid container>
                <Grid
                  item
                  md={9}
                  style={{
                    alignItems: 'center',
                    backgroundColor: 'rgb(248 229 229)',
                    borderRadius: '8px',
                    display: 'flex',
                    minHeight: '36px',
                    padding: '5px 8px',
                  }}>
                  <Typography component="h6" sx={leaveStyle.employeeText}>
                    iTech employees:{' '}
                  </Typography>
                  <Typography component={'div'}>
                    <Typography component={'p'} sx={leaveStyle.employeeText}>
                      Contact Marci for international travel/stamping at{' '}
                      <Typography
                        component={'a'}
                        className="pointer"
                        sx={{
                          color: '#005EBF',
                          cursor: 'pointer',
                          fontFamily: 'Red Hat Display Medium !important',
                          fontSize: '12px !important',
                          textDecoration: 'underline',
                        }}>
                        compliance@itechus.net
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Divider /> */}
            <Grid container sx={{ paddingTop: '20px' }}>
              <Grid item xs={6} sx={{ paddingRight: '10px' }}>
                <Typography sx={leaveStyle.normalText}>
                  Country of Leave
                </Typography>
                <Typography
                  component={'div'}
                  sx={{
                    padding: '10px',
                    paddingLeft: '0px',
                  }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="onLeaveCountry"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder="Country of Leave"
                          value={value}
                          name={name}
                          className={classes.custominput}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.onLeaveCountry?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography sx={leaveStyle.normalText}>
                  Preferred Method of Communication While on Leave
                </Typography>
                <Typography
                  component={'div'}
                  sx={{
                    padding: '10px',
                    paddingLeft: '0px',
                  }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="modeOfCommunication"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <>
                          <Select
                            style={{ textTransform: 'capitalize' }}
                            className="common-select"
                            value={value}
                            onChange={(e: any) => {
                              onChange(e);
                              setValue('communicateVia', '');
                              setError('communicateVia', {
                                message: '',
                                type: 'custom',
                              });
                            }}
                            name={name}
                            ref={ref}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem value={'Phone'}>Phone</MenuItem>
                            <MenuItem value={'Email'}>Email</MenuItem>
                            <MenuItem value={'Skype'}>Skype</MenuItem>
                          </Select>
                          {formState.errors?.modeOfCommunication?.message && (
                            <FormHelperText className="errorText">
                              {formState.errors?.modeOfCommunication?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Typography>
                {watch('modeOfCommunication') && (
                  <>
                    <Typography sx={leaveStyle.normalText}>
                      Enter{' '}
                      {watch('modeOfCommunication') === 'Phone'
                        ? 'Phone Number'
                        : watch('modeOfCommunication') === 'Email'
                        ? 'Email'
                        : 'Skype Id'}
                    </Typography>
                    <Typography
                      component={'div'}
                      sx={{
                        padding: '10px',
                        paddingLeft: '0px',
                      }}>
                      {watch('modeOfCommunication') === 'Phone' ? (
                        <Controller
                          control={control}
                          defaultValue=""
                          name="communicateVia"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <>
                                <PhoneInput
                                  country={'us'}
                                  value={value}
                                  onChange={(
                                    value,
                                    data: any,
                                    event,
                                    formattedValue,
                                  ) => {
                                    onChange(formattedValue);
                                  }}
                                />
                                {formState.errors?.communicateVia?.message && (
                                  <FormHelperText className="errorText">
                                    {formState.errors?.communicateVia?.message}
                                  </FormHelperText>
                                )}
                              </>
                            );
                          }}
                        />
                      ) : (
                        <Controller
                          control={control}
                          defaultValue=""
                          name="communicateVia"
                          render={({
                            field: { onChange, value, name, ref },
                          }) => {
                            return (
                              <CustomInput
                                placeHolder=""
                                value={value}
                                name={name}
                                className={classes.custominput}
                                ref={ref}
                                onChange={(e: any) => {
                                  onChange(e);
                                }}
                                error={
                                  formState.errors?.communicateVia?.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: '#292929',
                    fontFamily: 'Red Hat Display Regular',
                    fontSize: '12px',
                  }}>
                  Add Comment
                </Typography>
                <Typography
                  component={'div'}
                  sx={{
                    padding: '0px !important',
                    paddingLeft: '0px',
                  }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="comments"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder="optional"
                          value={value}
                          name={name}
                          className={classes.custominput}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          from={'leave'}
                          isTextArea={true}
                          error={formState.errors?.comments?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ paddingTop: '20px' }}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    background: '#F8F8FA',
                    border: '1px solid #E0E0E0',
                    cursor: 'pointer',
                    padding: '20px 20px 10px',
                    position: 'relative',
                    width: '100%',
                  }}
                  component={'div'}>
                  <Typography component={'div'}>
                    <Typography
                      component={'em'}
                      sx={{
                        color: '#b3afaf',
                        fontFamily: 'Red Hat Display Medium',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        position: 'absolute',
                        right: '15px',
                        top: '15px',
                      }}
                      onClick={(e) => {
                        setValue('eSignature', null);
                        sigPad?.current?.clear();
                      }}>
                      Clear
                    </Typography>
                    <SignaturePad
                      id="sign"
                      canvasProps={{
                        className: 'sigCanvas',
                        height: drawSignatureInfo.height,
                        width: drawSignatureInfo.width,
                      }}
                      ref={sigPad}
                      penColor="black"
                      onEnd={() => {
                        setValue(
                          'eSignature',
                          sigPad?.current
                            ?.getTrimmedCanvas()
                            .toDataURL('image/png'),
                        );
                      }}
                    />
                    <Typography
                      component={'i'}
                      sx={{
                        color: '#b9b7b7',
                        display: 'table',
                        fontFamily: 'Red Hat Display Medium',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        margin: '8px auto 0px',
                      }}>
                      e-signature
                    </Typography>
                  </Typography>
                </Typography>

                {/* <TextField
                  placeholder="e-signature"
                  fullWidth
                  sx={{
                    '& input': {
                      alignItems: 'center',
                      alignSelf: 'center',
                      height: '100px !important',
                      justifyContent: 'center',
                      textAlign: 'center',
                    },
                  }}
                /> */}
                {formState.errors?.eSignature &&
                  formState.errors?.eSignature?.message &&
                  !watch('eSignature') && (
                    <Typography className="errorText" component={'div'}>
                      {`${formState?.errors?.eSignature?.message}`}
                    </Typography>
                  )}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  alignSelf: 'end',
                  paddingBottom: '10px',
                  textAlign: 'end',
                }}>
                <Typography
                  className="model-btn flex-end-content"
                  component={'div'}>
                  <CustomButton
                    variant="contained"
                    type={'submit'}
                    isStyle={true}
                    name={'Submit for Approval'}
                    form={'leaveApply'}
                    loading={loading}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
      <Popover
        className="shadow-popover-wa"
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleInfoClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}>
        <Typography component={'div'} className="inn-text">
          <Typography className="arrow-top-left" />
          {dayLeave === 'first' ? (
            <>
              <Typography>
                Enter the date you are departing the U.S.,
              </Typography>
              <Typography>whether working or not.</Typography>
            </>
          ) : (
            <Typography>
              Enter the date you are returning to the U.S.
            </Typography>
          )}
        </Typography>
      </Popover>
    </React.Fragment>
  );
};

export default LeaveApply;
