import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

export const addAdminSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  empCode: Yup.string().required('Employee Code is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  roleId: Yup.string().required('Role is required'),
});

export const ApplyLeaveSchema1 = Yup.object().shape({
  comments: Yup.string().notRequired(),
  communicateVia: Yup.string()
    .nullable()
    .when('modeOfCommunication', {
      is: (val: string) => val === 'Email',
      then: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    }),
  // communicateVia: Yup.string().notRequired(),
  eSignature: Yup.string().nullable().required('Signature is required'),
  fromDate: Yup.string().nullable().required('First Day of Leave is required'),
  leaveTypes: Yup.array().required().min(1, 'Reason for Leave is required'),
  modeOfCommunication: Yup.string().notRequired(),
  onLeaveCountry: Yup.string().notRequired(),
  paidOnLeaveComments: Yup.string().notRequired(),
  payableDays: Yup.array().when('wishToBePaidOnLeave', {
    is: (val: string) => val === 'yes',
    then: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.string().nullable().required('From Date is required'),
        toDate: Yup.string().nullable().required('To Date is required'),
      }),
    ),
  }),
  reasonComments: Yup.string()
    .nullable()
    .when('leaveTypes', {
      is: (val: any) => {
        const finIndex = val.findIndex((item) => item.leaveTypeId === '5');
        return finIndex === -1 ? false : true;
      },
      then: Yup.string().nullable().required('Reason for Leave is required'),
    }),
  returnDate: Yup.string()
    .nullable()
    .required('Return to Work Date is required'),
  returnToUsOn: Yup.string()
    .nullable()
    .when('travelingOutsideUS', {
      is: (val: string) => val === 'yes',
      then: Yup.string().nullable().required('Return date is required'),
    }),
  toDate: Yup.string().nullable().required('Last Day of Leave is required'),
  travelingOutsideUS: Yup.string()
    .nullable()
    .required('Will you be traveling outside the U.S is required'),
  wishToBePaidOnLeave: Yup.string()
    .nullable()
    .required('Do You Wish to be Paid While on Leave is required'),
  workingDays: Yup.array().when('workingWhileOnLeave', {
    is: (val: string) => val === 'yes',
    then: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.string().nullable().required('From Date is required'),
        toDate: Yup.string().nullable().required('To Date is required'),
      }),
    ),
  }),
  workingWhileOnLeave: Yup.string()
    .nullable()
    .required('Will you be Working While on Leave is required'),
});

export const EditLeaveSchema = Yup.object().shape({
  adpUpdated: Yup.string().nullable().notRequired(),
  fromDate: Yup.string().nullable().required('First Day of Leave is required'),
  leaveTypes: Yup.array().required().min(1, 'Reason for Leave is required'),
  notes: Yup.string().nullable().notRequired(),
  obtainI94: Yup.string().nullable().notRequired(),
  onLeaveCountry: Yup.string().notRequired(),
  payableDays: Yup.array().when('wishToBePaidOnLeave', {
    is: (val: string) => val === 'yes' || val === 'partialyPaid',
    then: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.string().nullable().required('From Date is required'),
        toDate: Yup.string().nullable().required('To Date is required'),
      }),
    ),
  }),
  reasonComments: Yup.string()
    .nullable()
    .when('leaveTypes', {
      is: (val: any) => {
        const finIndex = val.findIndex((item) => item.leaveTypeId === '5');
        return finIndex === -1 ? false : true;
      },
      then: Yup.string().nullable().required('Reason for Leave is required'),
    }),
  // remotePaid: Yup.string().nullable().notRequired(),
  returnDate: Yup.string()
    .nullable()
    .required('Return to Work Date is required'),
  toDate: Yup.string().nullable().required('Last Day of Leave is required'),
  wishToBePaidOnLeave: Yup.string()
    .nullable()
    .required('Do You Wish to be Paid While on Leave is required'),
  workingDays: Yup.array().when('workingWhileOnLeave', {
    is: (val: string) => val === 'yes',
    then: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.string().nullable().required('From Date is required'),
        toDate: Yup.string().nullable().required('To Date is required'),
      }),
    ),
  }),
  workingWhileOnLeave: Yup.string()
    .nullable()
    .required('Will you be Working While on Leave is required'),
});

export const ApplyLeaveSchema = Yup.object().shape({
  comments: Yup.string().when('leaveTypeId', {
    is: (val: string) => val == '5',
    then: Yup.string().required('Reason is required'),
  }),
  communicateVia: Yup.string()
    .nullable()
    .required('Communicate via is required'),
  currentAddress1: Yup.string().notRequired(),
  currentAddress2: Yup.string().notRequired(),
  currentCity: Yup.string().notRequired(),
  currentPhoneNumber: Yup.string().notRequired(),
  currentState: Yup.string().notRequired(),
  currentZip: Yup.string().nullable().notRequired(),
  // eSignature: Yup.string().nullable().required('Signature is required'),
  eSignature: Yup.string().nullable().required('Signature is required'),
  fromDate: Yup.string().nullable().required('First Day of Leave is required'),
  leaveTypeId: Yup.string().nullable().required('Reason for Leave is required'),
  // leaveTypes: Yup.array().required('Leave Type is required'),
  leaveTypes: Yup.array().required('Required'),
  modeOfCommunication: Yup.string()
    .nullable()
    .required('Preffered Method of communication is required'),
  onLeaveAddress1: Yup.string().notRequired(),
  onLeaveAddress2: Yup.string().notRequired(),
  onLeaveCity: Yup.string().notRequired(),
  onLeaveCountry: Yup.string()
    .nullable()
    .required('Contry of leave is required'),
  onLeavePhoneNumber: Yup.string().notRequired(),
  onLeaveState: Yup.string().notRequired(),
  onLeaveZip: Yup.string().nullable().notRequired(),
  paidOnLeaveComments: Yup.string().notRequired(),
  payableDays: Yup.array().when('wishToBePaidOnLeave', {
    is: (val: string) => val === 'yes',
    then: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.string().nullable().required('From Date is required'),
        toDate: Yup.string().nullable().required('To Date is required'),
      }),
    ),
  }),
  returnDate: Yup.string()
    .nullable()
    .required('Return to Work Date is required'),
  returnToUsOn: Yup.string()
    .nullable()
    .when('travelingOutsideUS', {
      is: (val: string) => val === 'yes',
      then: Yup.string().nullable().required('Return date is required'),
    }),
  toDate: Yup.string().nullable().required('Last Day of Leave is required'),
  travelingOutsideUS: Yup.string()
    .nullable()
    .required('Will you be traveling outside the U.S is required'),
  wishToBePaidOnLeave: Yup.string()
    .nullable()
    .required('Do You Wish to be Paid While on Leave is required'),
  workingDays: Yup.array().when('workingWhileOnLeave', {
    is: (val: string) => val === 'yes',
    then: Yup.array().of(
      Yup.object().shape({
        fromDate: Yup.string().nullable().required('From Date is required'),
        toDate: Yup.string().nullable().required('To Date is required'),
      }),
    ),
  }),
  workingFromDate: Yup.string()
    .nullable()
    .when('workingWhileOnLeave', {
      is: (val: string) => val === 'yes',
      then: Yup.string().nullable().required('Start date is required'),
    }),
  workingToDate: Yup.string()
    .nullable()
    .when('workingWhileOnLeave', {
      is: (val: string) => val === 'yes',
      then: Yup.string().nullable().required('End date is required'),
    }),
  workingWhileOnLeave: Yup.string()
    .nullable()
    .required('Will you be Working While on Leave is required'),
});

// export const ApplyLeaveSchema = Yup.object().shape({
//   comments: Yup.string().notRequired(),
//   currentAddress1: Yup.string().notRequired(),
//   currentAddress2: Yup.string().notRequired(),
//   currentCity: Yup.string().notRequired(),
//   currentPhoneNumber: Yup.string().notRequired(),
//   currentState: Yup.string().notRequired(),
//   currentZip: Yup.string().nullable().notRequired(),
//   fromDate: Yup.string().nullable().required('First Day of Leave is required'),
//   leaveTypeId: Yup.string().nullable().required('Reason for Leave is required'),
//   onLeaveAddress1: Yup.string().notRequired(),
//   onLeaveAddress2: Yup.string().notRequired(),
//   onLeaveCity: Yup.string().notRequired(),
//   onLeavePhoneNumber: Yup.string().notRequired(),
//   onLeaveState: Yup.string().notRequired(),
//   onLeaveZip: Yup.string().nullable().notRequired(),
//   paidOnLeaveComments: Yup.string().notRequired(),
//   returnDate: Yup.string()
//     .nullable()
//     .required('Return to Work Date is required'),
//   returnToUsOn: Yup.string().nullable().notRequired(),
//   toDate: Yup.string().nullable().required('Last Day of Leave is required'),
//   travelingOutsideUS: Yup.string()
//     .nullable()
//     .required('Will you be traveling outside the U.S is required'),
//   wishToBePaidOnLeave: Yup.string()
//     .nullable()
//     .required('Do You Wish to be Paid While on Leave is required'),
//   workingFromDate: Yup.string().nullable().notRequired(),
//   workingToDate: Yup.string().nullable().notRequired(),
//   workingWhileOnLeave: Yup.string()
//     .nullable()
//     .required('Will you be Working While on Leave is required'),
// });

export const editProfileSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  // phoneNumber: Yup.string().optional(),
});

export const addNotesSchema = Yup.object().shape({
  note: Yup.string().required('Note is required'),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password Must Contain One Uppercase, One Lowercase, One Number and one special case Character',
    ),
});

export const createPasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match"),
    }),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Enter valid password',
    ),
});

export const changePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .when('newPassword', {
      is: (newpassword) =>
        newpassword && newpassword.length > 0 ? true : false,
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "password doesn't match",
      ),
    }),
  currentPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password Must Contain One Uppercase, One Lowercase, One Number and one special case Character',
    ),
  newPassword: Yup.string()
    .required('password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password Must Contain One Uppercase, One Lowercase, One Number and one special case Character',
    ),
});

export const MyprofileSchema = Yup.object().shape({
  StreetNumberAndName: Yup.string(),
  address: Yup.string().required('Address is required'),
  city: Yup.string().nullable().required('City is required'),
  contactEmail: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  country: Yup.string().nullable().required('Country is required'),
  dateOfBirth: Yup.string().required('DOB is required'),
  firstName: Yup.string().required('First Name is required'),
  gender: Yup.string().required('Gender is required'),
  lastName: Yup.string().required('Last Name is required'),
  logo: Yup.array(),
  mobileNumber: Yup.string().required('Mobile Number is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  signatureKey: Yup.string(),
  state: Yup.string().nullable(),
  unitNumber: Yup.string(),
  unitType: Yup.string().optional(),
  zipCode: Yup.string().required('Zip Code is required'),
});

export const EditTimeSheetSchema = Yup.object().shape({
  city: Yup.string().nullable().notRequired(),
  eligibleForSickLeave: Yup.string().nullable().notRequired(),
  eligibleSickHours: Yup.string()
    .nullable()
    .when('eligibleForSickLeave', {
      is: (val: string) => val === 'yes',
      then: Yup.string().nullable().required('Eligible hours is required'),
    }),
  // collectionFrequency: Yup.string().nullable().notRequired(),
  // email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  state: Yup.string().nullable().notRequired(),
  timesheetStatus: Yup.string().nullable().notRequired(),
  workAuth: Yup.string().nullable().notRequired(),
});

export const EditPayInformationSchema = Yup.object().shape({
  additionalPay: Yup.string().nullable().notRequired(),
  basePay: Yup.string().nullable().notRequired(),
  baseWage: Yup.string().nullable().notRequired(),
  doNotPay: Yup.string().nullable().notRequired(),
});
