import './style/style.scss';

import LoadingToRedirect from 'components/LoadingToRedirect';
import ConsultantRoutes from 'config/routes/ConsultantRoutes';
import PrivateRoutes from 'config/routes/PrivateRoutes';
import PublicRoutes from 'config/routes/PublicRoutes';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { USER_LOGIN_SUCCESS, USER_SEARCH } from 'store/types/UserTypes';
import Storage from 'utils/Storage';

const App: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const locationData: any = useLocation();
  const [pathName, setPathName] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (locationData?.pathname !== pathName) {
      setPathName(locationData?.pathname);
      dispatch({
        payload: '',
        type: USER_SEARCH,
      });
    }
  }, [dispatch, locationData?.pathname, pathName]);

  const [loading, setLoading] = useState(true);
  const { userInfo: userDetail }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  const TokenStatus = Storage.getTokens();
  const handleAppEntry = useCallback(() => {
    if (!_.isEmpty(TokenStatus)) {
      dispatch({
        payload: Storage.isTokenValidDetails(),
        type: USER_LOGIN_SUCCESS,
      });
    } else {
      searchParams.get('hashKey') === '' && navigate('/login');
    }

    // if (_.isEmpty(sidemenus) && !_.isEmpty(SideMenuStatus)) {
    //   const menuData: any = SideMenuStatus;
    //   dispatch({
    //     payload: menuData,
    //     type: USER_SIDEMENU_SUCCESS,
    //   });
    // }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    handleAppEntry();
  }, [handleAppEntry]);

  if (loading) {
    return <LoadingToRedirect />;
  }
  return !_.isEmpty(userDetail) ? (
    userDetail.roleId === 7 ? (
      <ConsultantRoutes />
    ) : (
      <PrivateRoutes />
    )
  ) : (
    <PublicRoutes />
  );
};

export default App;
