import axios from 'axios';
import { useCustomNavigation } from 'hooks';
// import { toast } from 'react-toastify';
import store from 'store';
import { refreshToken } from 'store/actions/UserAction';
import { USER_LOGOUT } from 'store/types/UserTypes';
// import { alertOptions } from 'utils/Config';
import Storage from 'utils/Storage';

const { dispatch } = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const TokenStatus: any = Storage.getTokens();

    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ reject, resolve });
          })
            .then((token: string) => {
              originalConfig.headers['Authorization'] = 'Bearer '.concat(token);
              return instance(originalConfig);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
        // originalConfig._retry = true;
        originalConfig._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          const TokenStatus: any = Storage.getTokens();
          instance
            .post('tokens/exchange', {
              refreshToken:
                TokenStatus && TokenStatus.refreshToken
                  ? TokenStatus.refreshToken
                  : '',
            })
            .then((rs) => {
              isRefreshing = false;
              const { accessToken, refreshToken: refreshToken1 } = rs.data;
              dispatch(refreshToken(accessToken, refreshToken1));
              Storage.updateAccessToken(accessToken, refreshToken1);
              // const tokenData: any = {
              //   refreshToken: refreshToken1,
              //   token: accessToken,
              // };
              processQueue(null, accessToken);
              // return instance(originalConfig);
              resolve(instance(originalConfig));
            })
            .catch((err) => {
              // processQueue(err, null);
              // dispatch({
              //   type: USER_LOGOUT,
              // });
              // Storage.clear();
              // // toast.error('Please login again...', alertOptions);
              // useCustomNavigation('/login', { replace: true });
              reject(err);
            });
        });
      }

      if (err.response.status === 403 && err.response.data) {
        dispatch({
          type: USER_LOGOUT,
        });
        Storage.clear();
        // toast.error('Please login again...', alertOptions);
        useCustomNavigation('/login', { replace: true });
      }

      if (
        responseData.error?.message &&
        (responseData.error?.message === 'Invalid Token!' ||
          responseData.error?.message === 'Refresh token already exchanged' ||
          responseData.error?.message === 'Token expired')
      ) {
        dispatch({
          type: USER_LOGOUT,
        });
        Storage.clear();
        // toast.error('Session Expired! Please login again... ', alertOptions);
        useCustomNavigation('/login', { replace: true });
      }
    }

    return Promise.reject(err);
  },
);
export default instance;
