import { AxiosError, AxiosResponse } from 'axios';
import instance from 'services/api';

interface PostRequestProps {
  url: string;
  data: any;
  token?: string;
}

interface PatchRequestProps {
  url: string;
  data?: any;
}

interface GetRequestProps {
  url: string;
  token?: string;
}

const postRequest = async (props: PostRequestProps, options?: any) => {
  return await instance
    .post(process.env.REACT_APP_APIURL + props.url, props.data, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.response) {
        throw error.response.data;
      }
    });
};
const putRequest = async (props: PostRequestProps) => {
  return await instance
    .put(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const patchRequest = async (
  props: PatchRequestProps,
): Promise<AxiosResponse> => {
  return await instance
    .patch(process.env.REACT_APP_APIURL + props.url, props.data ?? {})
    .then((response: AxiosResponse) => {
      if (response.status >= 400) {
        if (response.data) {
          Promise.reject(response.data);
        } else {
          Promise.reject('Failed To Call Backend');
        }
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      throw new Error(
        (error?.response?.data as any)?.error?.message ??
          'Failed To Call Backend',
      );
    });
};

const deleteRequest = async (props: GetRequestProps) => {
  return await instance
    .delete(process.env.REACT_APP_APIURL + props.url)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const getRequest = async (props: GetRequestProps) => {
  return await instance
    .get(
      process.env.REACT_APP_APIURL + props.url,
      props.token !== '' && {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      },
    )
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

export { postRequest, patchRequest, putRequest, getRequest, deleteRequest };
