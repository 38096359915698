import { Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import arrowcolumns from 'assets/images/arrowcolumns.svg';
import InfinityScroll from 'components/InfinityScroll';
import { AdminPrivateRoutes } from 'config/index';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import useWindowDimensions from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display Regular',
    fontSize: 12,
    padding: 8,
    paddingBottom: 12,
    paddingTop: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    '& a': {
      color: '#005EBF',
      fontFamily: 'Red Hat Display Bold',
      fontSize: 13,
    },
    backgroundColor: '#fff',
    borderColor: '#d4d4d6',
    borderWidth: 1,
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    height: 50,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const staticParam = {
  order: 'asc',
  searchWord: '',
  skip: 0,
  sortby: 'clientName',
  status: 'active',
  take: 20,
};

const Clients: React.FC<any> = () => {
  const [list, setList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [param, setParam] = React.useState(staticParam);
  const { height } = useWindowDimensions();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const { status, searchText }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const [searchLoad, setSearchLoad] = React.useState(false);
  useEffect(() => {
    if (searchLoad && param.searchWord !== searchText) {
      setParam({
        ...staticParam,
        searchWord: searchText,
        status: param.status,
      });
    }
    setSearchLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchLoad]);

  useEffect(() => {
    if (status !== param.status) {
      setParam({
        ...staticParam,
        status: status,
      });
    }
  }, [status, param]);

  const [loader, setLoader] = React.useState<boolean>(false);
  const getData = () => {
    if (param.skip === 0) {
      setLoader(true);
    }
    let url =
      MASTER_ENDPOINT.Clients +
      '?order=' +
      param.order +
      '&sortby=' +
      param.sortby +
      '&skip=' +
      param.skip +
      '&take=' +
      param.take;
    if (param.status) {
      const boolVal = param.status === 'active' ? true : false;
      url += `&status=${String(boolVal)}`;
    }
    if (param.searchWord) {
      url += `&name=${String(param.searchWord)}`;
    }
    getAllListData(url)
      .then((resp: any) => {
        const mergedData =
          param.skip === 0 ? [...resp.data] : [...list, ...resp.data];
        setList(mergedData);
        setTotal(
          resp?.total?.[0]?.totalClients
            ? parseInt(resp?.total?.[0]?.totalClients)
            : 0,
        );
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const rowProps = (row: any, index: number) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell
          component="th"
          scope="row"
          style={{ maxWidth: 180, paddingLeft: 40 }}>
          {row.clientName}
        </StyledTableCell>
        <StyledTableCell>
          <NavLink to={`${AdminPrivateRoutes.CLIENTS}/sub/${String(row.id)}`}>
            {row.consultantsCount}
          </NavLink>
        </StyledTableCell>
        <StyledTableCell>
          <NavLink to={`${AdminPrivateRoutes.CLIENTS}/sub/${String(row.id)}`}>
            {row.projectsCount}
          </NavLink>
        </StyledTableCell>
        <StyledTableCell>{'Other'}</StyledTableCell>
      </StyledTableRow>
    );
  };

  const setParamData = (str: string) => {
    if (param.sortby === str) {
      setParam({
        ...staticParam,
        order: 'desc',
        status: param.status,
      });
    } else {
      setParam({
        ...staticParam,
        order: 'asc',
        sortby: str,
        status: param.status,
      });
    }
  };

  const headRow = () => {
    return (
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ paddingLeft: 40 }}>
            Clients
            <Link href={'#'} onClick={() => setParamData('clientName')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Consultants
            <Link href={'#'} onClick={() => setParamData('consultantsCount')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Projects
            <Link href={'#'} onClick={() => setParamData('projectsCount')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Other
            {/* <Link href={'#'} onClick={() => setParamData('roleId')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link> */}
          </StyledTableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container>
          <TableContainer>
            <InfinityScroll
              paramState={setParam}
              height={height - 80}
              scrollableTargetId={'Clients'}
              data={list}
              rowPorops={rowProps}
              headRow={headRow}
              count={total}
              columnCount={6}
            />
          </TableContainer>
        </Grid>
      </Typography>
    </LoadingOverlay>
  );
};

export default Clients;
