import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const UseFocus = () => {
  const htmlElRef: any = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun: any) => useEffect(fun, []);

export const useCustomNavigation = (routePath: string, options: any) => {
  const navigate = useNavigate();
  navigate(routePath, options);
};
