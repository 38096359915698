import Storage from 'utils/Storage';

import {
  EMAIL_CHECKED,
  UPDATE_CONSULTANT_FILTER,
  UPDATE_PROJECT_FILTER,
  USER_FILTER_COMPANY_STATUS,
  USER_FILTER_COMPANY_STATUS_QUEUE,
  USER_FILTER_STATUS,
  USER_IMAGE_ID_CHANGE,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PAYROLL_SUMMARY_BACK_ACTION,
  USER_REFRESH_TOKEN,
  USER_SEARCH,
  USER_SIDEMENU_FAIL,
  USER_SIDEMENU_SUCCESS,
} from '../types/UserTypes';

const user = Storage.isTokenValidDetails();
export interface UserState {
  loading?: boolean;
  error?: string;
  userInfo?: any;
  isLoggedIn?: boolean;
  sideMenu?: any;
  emailRemember?: string;
  status?: any;
  companyStatus: string;
  companyStatusQueue: string;
  searchText?: any;
  consultantFilter: any;
  projectFilter: any;
  payrollSummaryId: string;
}

export interface Action {
  type: string;
  payload?: string;
}

const INITIAL_STATE: UserState = {
  companyStatus: 'smartworks',
  companyStatusQueue: 'smartworks',
  consultantFilter: {
    branch: '',
    collectionFrequency: '',
    hours: '',
    lob: '',
    order: 'asc',
    periodIndex: '',
    searchWord: '',
    skip: 0,
    sortby: 'jobWcCode',
    take: 100,
    timesheets: '',
  },
  emailRemember: Storage.getItem(Storage.KEYS.REMEMBER_ME),
  error: null,
  isLoggedIn: Storage.isTokenValidDetails() ? true : false,
  loading: false,
  payrollSummaryId: '',
  projectFilter: {
    approved: '',
    branch: '',
    collectionFrequency: '',
    hours: '',
    lob: '',
    order: 'asc',
    periodIndex: '',
    searchWord: '',
    skip: 0,
    sortby: 'project',
    subPeriodFrom: '',
    subPeriodTo: '',
    take: 100,
    timesheets: '',
  },
  searchText: '',
  sideMenu: {},
  status: 'active',
  userInfo: Storage.isTokenValidDetails()
    ? Storage.isTokenValidDetails()
    : null,
};

export const userLoginReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case UPDATE_CONSULTANT_FILTER:
      return {
        ...state,
        consultantFilter: action.payload,
      };
    case UPDATE_PROJECT_FILTER:
      return {
        ...state,
        projectFilter: action.payload,
      };
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        emailRemember: state.emailRemember,
        error: action.payload,
        isLoggedIn: false,
        loading: false,
        userInfo: null,
      };
    case USER_LOGOUT:
      return {
        ...state,
        emailRemember: state.emailRemember,
        error: null,
        isLoggedIn: false,
        loading: false,
        sideMenu: {},
        userInfo: null,
      };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...user, token: action.payload },
      };
    case USER_SIDEMENU_SUCCESS:
      return {
        ...state,
        sideMenu: action.payload,
      };
    case USER_SIDEMENU_FAIL:
      return {
        ...state,
        error: action.payload,
        sideMenu: {},
      };
    case USER_IMAGE_ID_CHANGE:
      state.userInfo.imageId = action.payload;
      return {
        ...state,
      };
    case USER_FILTER_STATUS:
      state.status = action.payload;
      return {
        ...state,
      };
    case USER_FILTER_COMPANY_STATUS:
      state.companyStatus = action.payload;
      return {
        ...state,
      };
    case USER_FILTER_COMPANY_STATUS_QUEUE:
      state.companyStatusQueue = action.payload;
      return {
        ...state,
      };
    case USER_SEARCH:
      state.searchText = action.payload;
      return {
        ...state,
      };
    case EMAIL_CHECKED:
      return {
        ...state,
        emailRemember: action.payload,
      };
    case USER_PAYROLL_SUMMARY_BACK_ACTION:
      return {
        ...state,
        payrollSummaryId: action.payload,
      };
    default:
      return state;
  }
};
