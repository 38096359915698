import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import profileimg from 'assets/images/profileimg.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { USER_IMAGE_ID_CHANGE } from 'store/types/UserTypes';
import { EditProfileForm } from 'types';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { editProfileSchema } from 'utils/ValidatorSchema';

import useStyles from './CommonStyle';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

export const Profile: React.FC<any> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    handleSubmit: handleSubmit,
    control: control,
    formState,
    setValue,
  } = useForm<EditProfileForm>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(editProfileSchema),
  });

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLocalImage('');
  };
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState<any>({});
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [alternativeEmail, setAlternativeEmail] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [uploadImage, setUploadImage] = React.useState<any>({});
  const [localImage, setLocalImage] = React.useState<any>('');
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    getAllListData(MASTER_ENDPOINT.Admins + '?id=' + userInfo.id)
      .then((resp: any) => {
        setUserData(resp?.data?.[0]);
        dispatch({
          payload: resp?.data?.[0]?.imageId,
          type: USER_IMAGE_ID_CHANGE,
        });
        setValue('firstName', resp?.data?.[0]?.firstName);
        setValue('lastName', resp?.data?.[0]?.lastName);
        setValue('email', resp?.data?.[0]?.email);
        if (resp?.data?.[0]?.alternativeEmail) {
          setAlternativeEmail(resp?.data?.[0]?.alternativeEmail);
        } else {
          setAlternativeEmail(resp?.data?.[0]?.email);
        }
        setPhoneNumber(resp?.data?.[0]?.phoneNumber);
      })
      .catch((err: any) => {});
  };

  const submitForm = (data: any) => {
    const formData = new FormData();
    if (props.from === 'consultant') {
      if (alternativeEmail) {
        const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!mailformat.test(alternativeEmail)) {
          toast.error('Please enter valid alternate email', alertOptions);
          return true;
        }
      }
      formData.append('alternativeEmail', alternativeEmail);
    }
    formData.append('status', 'true');
    formData.append('roleId', userData.roleId);
    formData.append('email', data['email']);
    formData.append('empCode', userData.empCode);
    formData.append('firstName', data['firstName']);
    formData.append('lastName', data['lastName']);
    if (phoneNumber) {
      formData.append('phoneNumber', phoneNumber);
      formData.append('countryCode', `+${countryCode}`);
    } else {
      formData.append('phoneNumber', '');
      formData.append('countryCode', '');
    }
    if (uploadImage.name) {
      formData.append('profile', uploadImage);
    } else if (userData.imageId) {
      formData.append('imageId', userData.imageId);
    }
    setLoading(true);
    updateData(userData.id, formData, MASTER_ENDPOINT.Admins, true)
      .then((resp: any) => {
        setLoading(false);
        handleClose();
        getData();
        setUploadImage({});
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Card sx={{ boxShadow: 0 }}>
        <CardContent>
          <a href={'#'} onClick={handleOpen}>
            <Typography> Edit </Typography>
          </a>
          <Typography className={classes.profilebox} component={'div'}>
            <Typography component={'div'}>
              {userData?.imageId ? (
                <img
                  style={{
                    borderRadius: '10%',
                    objectFit: 'cover',
                    padding: 0,
                  }}
                  src={`${process.env.REACT_APP_APIURL}${
                    MASTER_ENDPOINT.Files
                  }/${String(userData?.imageId)}`}
                />
              ) : (
                <img
                  src={profileimg}
                  style={{
                    borderRadius: '10%',
                    objectFit: 'cover',
                    padding: 0,
                  }}
                />
              )}
            </Typography>
            <Typography component={'div'}>
              <ul className={classes.profiledetails}>
                <li>
                  <span>Name</span>{' '}
                  {userData?.firstName
                    ? userData?.firstName + ' ' + userData?.lastName
                    : ''}
                </li>
                <li>
                  <span>Email</span> {userData?.email}
                </li>
                <li className={'phoneLabel'}>
                  <span style={{ marginTop: '10px' }}> Phone </span>
                  <PhoneInput
                    country={'us'}
                    disabled
                    value={userData?.phoneNumber}
                    onChange={(value, data: any) => {
                      setPhoneNumber(value);
                      setCountryCode(data?.dialCode);
                    }}
                  />
                </li>
              </ul>
            </Typography>
          </Typography>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form id={'editProfile'} onSubmit={handleSubmit(submitForm)}>
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              Edit Profile
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                marginBottom: 25,
              }}>
              <Typography component={'div'}>
                <Typography component={'div'} sx={{ textAlign: 'center' }}>
                  <Button variant="text" component="label">
                    {localImage ? (
                      <img
                        style={{
                          borderRadius: '50px',
                          height: '100px',
                          objectFit: 'cover',
                          padding: 0,
                          width: '100px',
                        }}
                        src={localImage}
                      />
                    ) : userData?.imageId ? (
                      <img
                        style={{
                          borderRadius: '50px',
                          height: '100px',
                          objectFit: 'cover',
                          padding: 0,
                          width: '100px',
                        }}
                        src={`${process.env.REACT_APP_APIURL}${
                          MASTER_ENDPOINT.Files
                        }/${String(userData?.imageId)}`}
                      />
                    ) : (
                      <img
                        src={profileimg}
                        style={{
                          borderRadius: '50px',
                          height: '100px',
                          objectFit: 'cover',
                          padding: 0,
                          width: '100px',
                        }}
                      />
                    )}
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setUploadImage(e.target.files[0]);
                        setLocalImage(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </Button>
                </Typography>
                <Typography>
                  <label>
                    First Name<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="firstName"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          className={classes.custominput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.firstName?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography>
                  <label>
                    Last Name<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="lastName"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          className={classes.custominput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.lastName?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography>
                  <label>
                    Email<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="email"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          className={classes.custominput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.email?.message}
                          disabled
                        />
                      );
                    }}
                  />
                </Typography>
                {props.from === 'consultant' && (
                  <Typography>
                    <label>Alternate Email</label>
                    <CustomInput
                      placeHolder="Alternate Email"
                      value={alternativeEmail}
                      className={classes.custominput}
                      onChange={(e: any) => {
                        setAlternativeEmail(e?.target?.value);
                      }}
                    />
                  </Typography>
                )}
                <Typography>
                  <label>Phone Number</label>
                  <PhoneInput
                    country={'us'}
                    value={phoneNumber}
                    onChange={(value, data: any) => {
                      setPhoneNumber(value);
                      setCountryCode(data?.dialCode);
                    }}
                  />
                </Typography>
              </Typography>
            </Typography>
            <Divider />
            <Typography
              className="model-btn"
              component={'div'}
              style={{
                marginTop: 25,
              }}>
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                type={'submit'}
                isStyle={true}
                name={'Save Changes'}
                form={'editProfile'}
                loading={loading}
              />
            </Typography>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default Profile;
