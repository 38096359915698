import { Avatar, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import arrowcolumns from 'assets/images/arrowcolumns.svg';
import profileimg from 'assets/images/profileimg.png';
import InfinityScroll from 'components/InfinityScroll';
import { AdminPrivateRoutes } from 'config';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import useWindowDimensions from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from './style';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8fa',
    color: '#979598',
    fontFamily: 'Red Hat Display Regular',
    fontSize: 12,
    padding: 8,
    paddingBottom: 12,
    paddingLeft: 0,
    paddingTop: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fff',
    borderColor: '#d4d4d6',
    borderWidth: 1,
    color: '#241A2E',
    fontFamily: 'Red Hat Display Medium',
    fontSize: 13,
    padding: 5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  //   '&:first-child td': {
  //     border: 0,
  //   },
}));

const staticParam = {
  order: 'asc',
  searchWord: '',
  skip: 0,
  sortby: 'name',
  take: 20,
};

const Users: React.FC<any> = () => {
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const classes = useStyles();
  const [param, setParam] = React.useState(staticParam);
  const [list, setList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [loader1, setLoader1] = React.useState<boolean>(true);
  const getData = () => {
    if (loader1) {
      setLoader1(false);
      setLoader(true);
    } else {
      if (param.skip === 0) {
        setLoader(true);
        setList([]);
        setTotal(0);
      }
    }
    let url =
      MASTER_ENDPOINT.Admins +
      '?order=' +
      param.order +
      '&sortby=' +
      param.sortby +
      '&skip=' +
      param.skip +
      '&take=' +
      param.take +
      '&excludeRoles=7' +
      '&consultantStatus=active,terminated,closed,offproject&jobWcCode=1SALARY,2HOURLY,3P/I';
    if (param.searchWord) {
      url += `&search=${String(param.searchWord)}`;
    }
    if (companyStatus !== 'all') {
      url += `&organization=${String(companyStatus)}`;
    }

    getAllListData(url)
      .then((resp: any) => {
        const mergedData =
          param.skip === 0 ? [...resp.data] : [...list, ...resp.data];
        setList(mergedData);
        setTotal(resp?.total);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const { companyStatus, searchText }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const [companyLoad, setCompanyLoad] = React.useState(false);
  useEffect(() => {
    if (companyLoad) {
      setParam({ ...staticParam });
    }
    setCompanyLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStatus]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const [searchLoad, setSearchLoad] = React.useState(false);
  useEffect(() => {
    if (searchLoad && param.searchWord !== searchText) {
      setParam({
        ...staticParam,
        searchWord: searchText,
      });
    }
    setSearchLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, searchLoad]);

  const setParamData = (str: string) => {
    if (param.sortby === str) {
      setParam({
        ...staticParam,
        order: param.order === 'asc' ? 'desc' : 'asc',
        searchWord: param.searchWord,
      });
    } else {
      setParam({
        ...staticParam,
        order: 'desc',
        searchWord: param.searchWord,
        sortby: str,
      });
    }
  };

  const headRow = () => {
    return (
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ width: 60 }} />
          <StyledTableCell>
            Name
            <Link href={'#'} onClick={() => setParamData('name')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell style={{ width: 200 }}>
            Status
            <Link href={'#'} onClick={() => setParamData('consultantStatus')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
          <StyledTableCell style={{ width: 200 }}>
            Projects
            <Link href={'#'} onClick={() => setParamData('totalProjects')}>
              {/* <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span> */}
            </Link>
          </StyledTableCell>
          <StyledTableCell>
            Email
            <Link href={'#'} onClick={() => setParamData('email')}>
              <span style={{ marginLeft: 10 }}>
                <img src={arrowcolumns} />
              </span>
            </Link>
          </StyledTableCell>
        </TableRow>
      </TableHead>
    );
  };

  const rowProps = (row: any, index: number) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell
          component="th"
          scope="row"
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            borderWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            width: 60,
          }}>
          <Avatar
            variant="square"
            alt="Profile"
            src={
              row?.imageId
                ? `${process.env.REACT_APP_APIURL}${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    MASTER_ENDPOINT.Files
                  }/${String(row?.imageId)}`
                : profileimg
            }
            style={{
              background: '#e7e7e7',
              borderRadius: '10%',
              height: 40,
              width: 40,
            }}
          />
        </StyledTableCell>
        <StyledTableCell
          component="th"
          scope="row"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(
              `${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
                row?.id,
              )}`,
              { state: row?.consultantStatus },
            );
          }}>
          {/* <NavLink
            to={`${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
              row?.id,
            )}`}
            style={{ color: 'inherit', textDecoration: 'inherit' }}> */}
          {row?.lastName + ', ' + row?.firstName}
          {/* </NavLink> */}
        </StyledTableCell>
        <StyledTableCell>{row?.consultantStatus}</StyledTableCell>
        <StyledTableCell>{row?.totalProjects}</StyledTableCell>
        <StyledTableCell>{row?.email}</StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container className={classes.tableview} md={12}>
          <TableContainer>
            <InfinityScroll
              paramState={setParam}
              height={height - 80}
              scrollableTargetId={'Admins'}
              data={list}
              rowPorops={rowProps}
              headRow={headRow}
              count={total}
              columnCount={6}
            />
          </TableContainer>
        </Grid>
      </Typography>
    </LoadingOverlay>
  );
};

export default Users;
