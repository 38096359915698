import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import Loginlogo from 'assets/images/login-logo.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { ForgotSubmitForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { forgotSchema } from 'utils/ValidatorSchema';

import useStyles from '../publicStyles';

type screenProps = {
  screen1Change: (src: any, src1: boolean) => void;
  email: string;
  emailChecked: boolean;
};

const Screen1 = (props: screenProps): ReactElement => {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.email) {
      setValue('email', props.email);
      setChecked(props.emailChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const { handleSubmit, control, formState, setValue } =
    useForm<ForgotSubmitForm>({
      defaultValues: {
        email: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(forgotSchema),
    });

  const submitForm = (data: any) => {
    setLoading(true);
    const email: string = data['email'];
    getAllListData(`${MASTER_ENDPOINT.EmailValidate}/${email}`, true)
      .then((restRes: any) => {
        setLoading(false);
        if (restRes) {
          props.screen1Change(email, checked);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Typography>
        <img
          src={Loginlogo}
          alt="signup-logo"
          style={{ display: 'table', margin: '51px auto 30px' }}
        />
      </Typography>
      <Typography>
        <label>Email</label>
        <Controller
          control={control}
          defaultValue=""
          name="email"
          render={({ field: { onChange, value, name, ref } }) => {
            return (
              <CustomInput
                placeHolder="Email"
                // variant="outlined"
                value={value}
                className={classes.custominput}
                name={name}
                ref={ref}
                autoFocus={true}
                onChange={(e: any) => {
                  const emailTrim = e?.target?.value.replace(/\s+/g, '');
                  onChange(emailTrim);
                }}
                error={formState.errors?.email?.message}
              />
            );
          }}
        />
      </Typography>
      <Typography>
        <FormControlLabel
          className="default-chexbox"
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          }
          label="Remember My User ID"
        />
      </Typography>
      <Typography>
        <CustomButton
          variant="contained"
          type="submit"
          name={'Next'}
          loading={loading}
          extraStyle={{ textTransform: 'capitalize' }}
        />
      </Typography>
    </form>
  );
};

export default Screen1;
