export const AppStyles = {
  background: {
    aliceBlue: '#F7F9FC',
    blue: '#4d71f1',
    brightGray: '#ECECEC',
    lightlevandor: '#4E4754',
    primary: '#FFFFFF',
    secondary: '#241A2E',
    solitude: '#E4E8EE',
    tertiary: '#F3F5F8',
    tuna: '#4C4653',
  },
  badgeColor: {
    primary: '#DA5A30',
  },
  borderColor: {
    hawkesBlue: '#DCE2EA',
    levendor: '#61566C',
    primary: '#d5d9e3',
    scarpa: '#686070',
    secondary: '#0062FF',
    tertiary: '#979797',
    whisper: '#E6E6E6',
  },
  color: {
    black: '#000000',
    lightBlue: '#1C2858',
    orangeColor: '#E38C70',
    primary: '#0062FF',
    secondary: '#1AB23D',
    tertiary: '#DA5A30',
    white: '#FFFFFF',
  },
  font: {
    bold: 'FiraSans-Bold',
    medium: 'FiraSans-Medium',
    regular: 'FiraSans-Regular',
  },
  fontColor: {
    lightGray: '#777B7E',
    primary: '#000000',
    secondary: '#241A2E',
    tertiary: '#161719',
  },
  iconsBgColor: {
    primary: '#6C91CB',
  },
};

// Change scale
export const GET_8_PIXEL_BOX = (unit: number): string => `${8 * unit}px`;
