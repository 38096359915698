import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Link, Modal, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updateData } from 'services/CommonServices';
import { changePasswordForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { changePasswordSchema } from 'utils/ValidatorSchema';

import useStyles from './CommonStyle';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

export const ChangePassword: React.FC<any> = () => {
  const classes = useStyles();
  const { handleSubmit, control, formState, reset } =
    useForm<changePasswordForm>({
      defaultValues: {
        confirmPassword: '',
        currentPassword: '',
        newPassword: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(changePasswordSchema),
    });

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);

  const resetForm = () => {
    handleClose();
    reset();
  };

  const submitForm = (data: any) => {
    setLoading(true);
    updateData('', data, MASTER_ENDPOINT.Changepassword, true)
      .then((resp: any) => {
        resetForm();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Link href="#" onClick={handleOpen} underline="none" color="#292929">
        Change Password
      </Link>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form id={'changePassword'} onSubmit={handleSubmit(submitForm)}>
          <Box sx={style} className="model-widget">
            <Typography id="modal-modal-title" component="h2">
              Change Password
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography component={'div'}>
                <Typography>
                  <label>
                    Current Pasword<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="currentPassword"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder="Current Password"
                          type={'password'}
                          value={value}
                          className={classes.passwordinput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.currentPassword?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography>
                  <label>
                    New Pasword<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newPassword"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder="New Password"
                          type={'password'}
                          value={value}
                          className={classes.passwordinput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.newPassword?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography>
                  <label>
                    Confirm Password<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="confirmPassword"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder="Confirm Password"
                          type={'password'}
                          value={value}
                          className={classes.passwordinput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.confirmPassword?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Typography>
            </Typography>
            <Typography sx={{ paddingTop: '15px' }} />
            <Divider />
            <Typography sx={{ paddingTop: '15px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button variant="text" onClick={resetForm}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                type={'submit'}
                isStyle={true}
                name={'Save Changes'}
                form={'changePassword'}
                loading={loading}
              />
            </Typography>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePassword;
