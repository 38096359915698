import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Checkbox, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AdminPrivateRoutes } from 'config';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { RestrictionProd } from 'utils/Config';

import useStyles from './style';

export const ListTableRow: React.FC<any> = ({
  row,
  checked,
  checkEvent,
  userIds,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <TableRow
      sx={{
        borderBottom: '2px solid #eee !important',
      }}>
      <TableCell component="th" scope="row">
        <Checkbox
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 27 },
            '& span': {
              margin: 0,
            },
            '&.Mui-checked': {
              color: grey[800],
            },
            color: grey[300],
          }}
          checked={checked}
          onChange={(e) => checkEvent(e.target.checked, row.userId)}
        />
        <NavLink
          to={`${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
            row?.userId,
          )}`}
          state={{ from: 'submission', userIds: userIds }}
          style={{ color: 'inherit', textDecoration: 'inherit' }}>
          {row?.lastName + ', ' + row?.firstName}
        </NavLink>
      </TableCell>
      <TableCell align="left">
        {row?.projectCount ? row?.projectCount : 0}
      </TableCell>
      {!RestrictionProd && <TableCell align="left">{row?.jobWcCode}</TableCell>}
      {/* <TableCell align="left">
        {row?.collectionFrequency ? row?.collectionFrequency : '-'}
      </TableCell> */}
      <TableCell align="left">{row?.lob ? row?.lob : '-'}</TableCell>
      <TableCell align="left">{row?.branch ? row?.branch : '-'}</TableCell>
      <TableCell align="left">
        <Typography
          component={'div'}
          sx={{ alignItems: 'center', display: 'flex' }}>
          {row?.hours && row?.hours != '0.00' ? (
            <CheckCircleIcon style={{ color: '#1D9900', width: '18px' }} />
          ) : (
            <CancelIcon style={{ color: '#BF0000', width: '18px' }} />
          )}
          {row?.hoursRequestedOn && (
            <Typography
              component={'span'}
              sx={{
                color: 'grey',
                fontFamily: 'Red Hat Display Italic',
                fontSize: '12px',
                paddingLeft: '8px',
              }}>
              {moment(row?.hoursRequestedOn).format('MM/DD/YYYY')}
            </Typography>
          )}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          component={'div'}
          sx={{ alignItems: 'center', display: 'flex' }}>
          {row?.timesheetFiles && row?.timesheetFiles?.length ? (
            <CheckCircleIcon style={{ color: '#1D9900', width: '18px' }} />
          ) : (
            <CancelIcon style={{ color: '#BF0000', width: '18px' }} />
          )}
          {row?.timesheetRequestedOn && (
            <Typography
              component={'span'}
              sx={{
                color: 'grey',
                fontFamily: 'Red Hat Display Italic',
                fontSize: '12px',
                paddingLeft: '8px',
              }}>
              {moment(row?.timesheetRequestedOn).format('MM/DD/YYYY')}
            </Typography>
          )}
        </Typography>
        {/* <CancelIcon style={{ color: '#BF0000' }} /> */}
      </TableCell>
      {/* <TableCell align="left">
                    <RemoveCircleIcon style={{ color: '#E0E0E0' }} />
                  </TableCell> */}
      <TableCell align="center">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={classes.commondrop}
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              '& .MuiAvatar-root': {
                height: 35,
                ml: -0.5,
                mr: 1,
                width: 40,
              },
              background: '#241A2E',
              borderRadius: '2px',
              filter: 'drop-shadow(-2px 0px 1px rgba(0,0,0,0.5))',
              mt: 1.5,
              overflow: 'visible',
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}>
          <MenuItem>
            <NavLink
              to={`${String(AdminPrivateRoutes.CONSULTANTS)}/profile/${String(
                row?.userId,
              )}`}
              state={{ from: 'submission', userIds: userIds }}
              className={'whiteText'}
              style={{ textDecoration: 'inherit' }}>
              View Profile
            </NavLink>
          </MenuItem>
          <MenuItem>Request Timesheet</MenuItem>
          <MenuItem>Send Notification</MenuItem>
          <MenuItem>Archive</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default ListTableRow;
