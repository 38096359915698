import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import SidebarLogo from 'assets/images/RiseIt.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import HTMLRenderer from 'react-html-renderer';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

// import useStyles from '../publicStyles';
const drawerWidth = 0;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#340002',
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const VersionHistory: React.FC = () => {
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    getVersionHistory();
  }, []);

  const getVersionHistory = () => {
    getAllListData(MASTER_ENDPOINT.VersionHistory)
      .then((resp: any) => {
        setList(resp?.data);
      })
      .catch((err: any) => {});
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={true} style={{ boxShadow: 'unset' }}>
          <Toolbar sx={{ display: 'inline' }}>
            <Typography variant="h6" noWrap component="div">
              <Typography
                component={'div'}
                className="d-flex-ja flex-column"
                sx={{
                  padding: '12px',
                }}>
                <img
                  src={SidebarLogo}
                  alt="Riseit"
                  style={{
                    width: 150,
                  }}
                />
              </Typography>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Typography sx={{ paddingTop: '100px' }} />
      <Grid container sx={{ padding: '20px' }}>
        <Grid item xs={12}>
          <Typography className={'_version_bold_text'} component="h2">
            Version History Details
          </Typography>
          <Typography
            className={'_version_semi-bold_text'}
            sx={{
              paddingTop: '15px',
            }}>
            Please find below the version history of the application. It only
            includes final releases and not pre-release or beta software.
          </Typography>
          <Typography sx={{ paddingTop: '20px' }} />
          {list.map((item: any, index: number) => {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  sx={{ borderBottom: '0.5px solid #e5dada', width: '100%' }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography
                        component={'div'}
                        className={'_version_semi-bold_head_text'}>
                        {item.versionId}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        component={'div'}
                        sx={{
                          textAlign: 'end',
                        }}
                        className={'_version_semi-bold_head_text'}>
                        {moment(item.createdAt).format('MM-DD-YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingX: '20px' }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <HTMLRenderer html={item.releaseNotes} />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default VersionHistory;
