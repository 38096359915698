import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { postRequest } from 'utils/Handlers';
import Storage from 'utils/Storage';

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
} from '../types/UserTypes';

export const login = (email: string, password: string) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const params = {
      data: {
        email,
        password,
      },
      token: '',
      url: MASTER_ENDPOINT.Login,
    };
    return await postRequest(params)
      .then((response: any) => {
        const { accessToken, refreshToken: refreshToken1 } = response;
        const userData: any = jwt_decode(String(accessToken));
        userData['token'] = accessToken;
        userData['refreshToken'] = refreshToken1;
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });

        if (accessToken && refreshToken(refreshToken1) && userData?.id) {
          const tokenData: any = {
            refreshToken: refreshToken1,
            token: accessToken,
            userId: userData.id,
          };
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
        }
      })
      .catch((error: any) => {
        let errorMessage = '';
        if (error.error.message) {
          errorMessage = error.error.message;
        }
        dispatch({
          payload: errorMessage,
          type: USER_LOGIN_FAIL,
        });

        toast.error(
          errorMessage || 'Invalid username or password' || String(error),
          alertOptions,
        );
      });
  };
};

export const refreshToken: any = (token: string) => (dispatch) => {
  dispatch({
    payload: token,
    type: USER_REFRESH_TOKEN,
  });
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: USER_LOGOUT,
    });
    localStorage.clear();
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    Storage.removeItem(Storage.KEYS.MENUS);
  };
};

// export const getTokenDetail = () => {
//   return async (dispatch) => {
//     const tokenStatusDetails = await Storage.isTokenValidDetails();

//     if (!tokenStatusDetails || !tokenStatusDetails.id) {
//       Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
//       dispatch({
//         payload: 'Token Expired!',
//         type: USER_LOGIN_FAIL,
//       });
//     } else {
//       dispatch({
//         payload: tokenStatusDetails,
//         type: USER_LOGIN_SUCCESS,
//       });
//     }
//   };
// };
