import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoadingToRedirect = () => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);

    count === 0 && navigate('/login');

    return () => clearInterval(interval);
  }, [count, navigate]);

  return (
    <Stack
      className="liberty-loader"
      sx={{ color: 'grey.500' }}
      spacing={2}
      direction="row">
      <CircularProgress color="inherit" />
    </Stack>
  );
};

export default LoadingToRedirect;
