import { Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import PayPeriodsAccordion from './PayPeriodsAccordion';

const Payroll = () => {
  const [searchParams] = useSearchParams();
  const [payPeriodData, setPayPeriodData] = useState<{
    loader: boolean;
    data: any;
    beforeData: any;
    id: string;
  }>({
    beforeData: [],
    data: [],
    id: '',
    loader: false,
  });

  useEffect(() => {
    getPayPeriodData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayPeriodData = () => {
    setPayPeriodData((pre: any) => {
      return { ...pre, loader: true };
    });
    let url = `${MASTER_ENDPOINT.Payperiods}`;
    if (searchParams.get('payperiodId')) {
      url += '?payPeriodId=' + searchParams.get('payperiodId');
    }
    getAllListData(url)
      .then((resp: any) => {
        const dateArray = [];
        if (resp?.data) {
          if (resp?.data?.length) {
            resp?.data.map((row: any) => {
              row?.payPeriodQuarters.map((item: any) => {
                if (item?.payPeriods) {
                  item?.payPeriods.map((item1: any) => {
                    dateArray.push(item1);
                  });
                }
              });
            });
          }
        }
        const beforePayPeriods = dateArray.filter((date: any) =>
          moment(date.endDate).isBefore(moment().format('YYYY-MM-DD')),
        );
        const currentDates = dateArray.filter((date: any) =>
          moment(moment().format('YYYY-MM-DD')).isBetween(
            moment(date.startDate),
            moment(date.endDate),
            undefined,
            '[]',
          ),
        );
        const dateAObject: any = beforePayPeriods.sort(function (a, b) {
          const c: any = new Date(a.startDate);
          const d: any = new Date(b.startDate);
          return d - c;
        });
        if (currentDates.length) {
          // setExpand(currentDates[0].id);
          setPayPeriodData((pre: any) => {
            return {
              ...pre,
              beforeData: dateAObject,
              data: [currentDates[0]],
              id: searchParams.get('payperiodId')
                ? searchParams.get('payperiodId')
                : currentDates[0].id,
            };
          });
        } else {
          setPayPeriodData((pre: any) => {
            return {
              ...pre,
              beforeData: dateAObject,
              id: searchParams.get('payperiodId')
                ? searchParams.get('payperiodId')
                : '',
            };
          });
        }
      })
      .finally(() => {
        setPayPeriodData((pre: any) => {
          return { ...pre, loader: false };
        });
      });
  };

  const renderContent = React.useMemo(() => {
    return (
      <Typography sx={{ p: 2 }} component={'div'} className="common-accordin">
        <Typography component="h2">Active Payroll Questions</Typography>
        {payPeriodData.data.map((item: any, index: number) => {
          return (
            <PayPeriodsAccordion
              item={item}
              key={index}
              index={index}
              setPayPeriodData={setPayPeriodData}
              userIdData={
                searchParams.get('userId') ? searchParams.get('userId') : ''
              }
              expand={payPeriodData.id === item.id ? payPeriodData.id : ''}
            />
          );
        })}
        <Typography className="h2">Past Payroll Questions</Typography>
        {payPeriodData.beforeData.map((item: any, index: number) => {
          return (
            <PayPeriodsAccordion
              item={item}
              index={index}
              key={index}
              setPayPeriodData={setPayPeriodData}
              userIdData={
                searchParams.get('userId') ? searchParams.get('userId') : ''
              }
              expand={payPeriodData.id === item.id ? payPeriodData.id : ''}
            />
          );
        })}
      </Typography>
    );
  }, [
    payPeriodData.data,
    payPeriodData.beforeData,
    payPeriodData.id,
    searchParams,
  ]);

  return (
    <LoadingOverlay active={payPeriodData.loader} spinner text="loading...">
      {renderContent}
    </LoadingOverlay>
  );
};

export default Payroll;
